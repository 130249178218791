import { AttachMoney, CheckCircleOutline, CreateRounded, FlashOn, Group, HourglassEmpty, Person, Schedule, StarRounded } from "@material-ui/icons";
import Button from "../../../../components/shared/Button";
import { useHistory } from "react-router-dom";
import { obtainCustomerRepurchaseVoucher } from "../../../../domain/services/marketing/voucher.service";
import loginService from "../../../../domain/services/login.service";
import { automationSellerUserIds } from "../../../../domain/constant/marketing/purchase.constant";
import { packages } from "../../../../domain/constant/marketing/product.constant";
import { useEffect, useState } from "react";
import { Alert } from "@material-ui/lab";
import useRequest from "../../../../hooks/useRequest";
import moment from "moment";
import packageService from "../../../../domain/services/financial/package.service";
import PackageCardService from "../../../../domain/services/financial/package.card.service";
import utilsProvider from "../../../../components/providers/utils.provider";
import LoadingSpin from "../../../../components/LoadingSpin";
import { voucherPrefixes } from "../../../../domain/constant/marketing/voucher.constant";

export default function PostPurchaseUpsell({
    fromPackageId,
    toPackageId,
    title,
    subtitle,
    purchasedAmount,
    packageIds
}) {

    const history = useHistory();

    const [obtainVoucher, loading, error] = useRequest(obtainCustomerRepurchaseVoucher);
    const [findPackages, findingPackages] = useRequest(packageService.findPackageOptions);
    const [findCustomerExpenses, findingExpenses] = useRequest(packageService.findBillings);

    const fromPackageKey = Object.keys(packages).find(pack => packages[pack].id == fromPackageId);
    const toPackageKey = Object.keys(packages).find(pack => packages[pack].id == toPackageId);

    const initialCountdownMinutes = 30;

    const primaryColor = '#3b82f6';
    const secondaryColor = '#06b6d4';

    const [packageOptions, setPackagesOptions] = useState(null);
    const [amountSaved, setAmountSaved] = useState(0);

    const [timer, setTimer] = useState({
        minutes: initialCountdownMinutes,
        seconds: 0,
    });

    const boldSpan = (text) => (
        <span className="text-[#131313] font-semibold">{text}</span>
    );

    const packageBenefits = {
        [packages.premium.id]: [
            {
                title: 'Priorizamos as suas entregas',
                description: (
                    <span>
                        Você recebe todas as suas demandas em até {boldSpan('4 dias úteis')}, 1 dia a menos que o Pacote Start.
                    </span>
                ),
                Icon: FlashOn,
            },
            {
                title: 'Você tem flexibilidade nos pedidos de correção',
                description: (
                    <span>
                        Não gostou da sua peça? Você tem até {boldSpan('10 dias')} para pedir sua correção. É o {boldSpan('dobro')} do período do Pacote Start
                    </span>
                ),
                Icon: Schedule,
            },
            {
                title: 'O melhor de tudo',
                description: (
                    <span>
                        {boldSpan('VOCÊ PAGA MENOS!')} É isso mesmo, quando você é Premium, os serviços ficam mais baratos! Veja ao lado os descontos que você ganha!
                    </span>
                ),
                Icon: AttachMoney,
            },
            {
                title: 'Gerencie os seus funcionários na nossa plataforma',
                description: 'É possível cadastrar os seus funcionários na nossa plataforma para que eles tenham acesso apenas às informações essenciais sobre os processos cadastrados, sem precisar que vejam os detalhes financeiros do seu escritório.',
                Icon: Group,
            },
        ],
        [packages.infinity.id]: [
            {
                title: 'Priorizamos as suas entregas',
                description: (
                    <span>Você recebe todas as suas demandas em até {boldSpan('3 dias úteis')}, 1 dia a menos que o Pacote Premium!</span>
                ),
                Icon: HourglassEmpty,
            },
            {
                title: 'Personalize sua plataforma',
                description: 'Você consegue personalizar a plataforma com as cores e logo do seu escritório!',
                Icon: CreateRounded,
            },
            {
                title: 'O melhor de tudo',
                description: (
                    <span>
                        {boldSpan('VOCÊ PAGA MENOS!')} É isso mesmo, quando você é Infinity, os serviços ficam mais baratos! Veja ao lado os descontos que você ganha!
                    </span>
                ),
                Icon: AttachMoney,
            },
            {
                title: 'Qualidade garantida em todos os serviços',
                description: 'Todas suas solicitações são realizadas por Faciliters Premium!',
                Icon: StarRounded,
            },
        ]
    };

    const checkIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-check h-5 w-5 shrink-0 mt-[3px]"
            style={{ color: primaryColor }}
        >
            <path d="M20 6 9 17l-5-5">
            </path>
        </svg>
    );

    const mapToObject = (packs) => {
        const formattedPackages = Object.keys(packages).reduce((obj, key) => {
            const equal = packs.find(p => p.id === packages[key].id);
            return { ...obj, [key]: equal };
        }, {});
        return formattedPackages;
    }

    const getPackages = () => {
        findPackages()
            .then(({ data }) => {
                const packs = PackageCardService.mapPackageOptions(data, { packagesWithPercentages: [packages.premium.id, packages.infinity.id] });
                setPackagesOptions(mapToObject(packs));

                if (purchasedAmount < 2)
                    return;

                findCustomerExpenses(packageIds)
                    .then(({ data: expenses }) => calculateAmountSaved(expenses, data))
            })
    }

    const calculateAmountSaved = (customerExpenses, packs) => {

        const pack = packs.find(p => p.id == toPackageId);

        const { totalExpense, savedExpense } = customerExpenses.reduce((sums, expense) => {

            const sameService = pack.packageServices.find(s => {
                if (expense.calculationServiceId)
                    return s.calculationServiceId == expense.calculationServiceId;

                if (expense.advanceId)
                    return s.advanceId == expense.advanceId;

                return s.serviceId == expense.serviceId && expense.serviceId
            }
            );

            if (!sameService || expense.amount == sameService.price)
                return sums;

            sums.totalExpense += parseFloat(expense.amount);
            sums.savedExpense += parseFloat(sameService.price)

            return sums;

        }, { totalExpense: 0, savedExpense: 0 });

        setAmountSaved(totalExpense - savedExpense);
    }

    useEffect(() => {
        const countdownDuration = moment.duration(initialCountdownMinutes, 'minutes');

        const interval = setInterval(() => {
            countdownDuration.subtract(1, 'second');

            setTimer({
                minutes: countdownDuration.minutes(),
                seconds: countdownDuration.seconds(),
            });

            if (countdownDuration.asSeconds() <= 0) {
                clearInterval(interval);
            }
        }, 1000);

        getPackages();

        return () => clearInterval(interval);
    }, []);

    const handleSubmit = () => {
        const user = loginService.getUserAuthentication();

        const options = {
            useRemainingBalance: true,
            isUpsell: true,
            ignoreMinimumCreditAmount: true,
            useDiscount: false,
            voucherPrefix: voucherPrefixes.upgrade,
        };

        obtainVoucher(user.customer.id, options)
            .then(({ data: voucher }) => {
                history.push(`/checkout/package/${toPackageId}?voucher=${voucher.code}&sellerUserId=${automationSellerUserIds.firstPurchaseStartUpsell}`);
            })
            .catch(() => { })
    }

    const renderTimeDigit = (digit = 0) => (
        <div
            className="w-[56px] lg:w-[72px] h-16 lg:h-24 flex justify-center items-center rounded-xl bg-primary text-white text-5xl lg:text-7xl font-bold"
            style={{ backgroundImage: 'linear-gradient(transparent 50%, #ffffff12 50%)' }}
        >
            <div
                className="bg-clip-text text-transparent"
                style={{ backgroundImage: 'linear-gradient(#fff 50%, #eee 50%)' }}
            >
                {digit}
            </div>
        </div>
    )

    const getDigits = (num) => {
        return num.toString().padStart(2, '0').split('');
    };

    const renderDescription = (Icon, label) => (
        <div className="text-sm text-left mt-4 flex">
            <Icon color="primary" className="-translate-y-[1px] mr-2" />
            <div>
                {label}
            </div>
        </div>
    )

    const getFromService = (serviceName) => (packageOptions[fromPackageKey]?.packageServices || []).find(service => service.name === serviceName);

    const renderPackageServices = (service) => {

        const fromService = getFromService(service.name);

        return (
            <div key={service.name}>
                <div className="flex gap-x-2 cols-2 items-start h-7">
                    {checkIcon}
                    <div className="grow gap-8 text-base leading-none grid-cols-2">
                        <span className="text-sm text-gray-700" style={{ width: "9rem" }}>
                            {service.name}
                            {service.percentageDiscount && <span className="font-bold ml-1"> -{service.percentageDiscount}%</span>}
                        </span>
                        <div className="float-right text-sm">
                            <div className="font-semibold">
                                {utilsProvider.formatNumberCurrency(service.price)}
                            </div>
                            {fromService && (
                                <div className="line-through text-[#0007]">
                                    {utilsProvider.formatNumberCurrency(fromService.price)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (!fromPackageId || !toPackageId)
        return <></>;

    if (findingPackages || !packageOptions) {
        return (
            <div className="w-full py-64 flex flex-col gap-3 items-center">
                <LoadingSpin size="large" />
                <div className="text-primary text-base font-semibold">Carregando...</div>
            </div>
        )
    }

    return (
        <div className="flex flex-col lg:flex-row pt-20 px-10 gap-6 font-nunito">

            <div className="px-1 pt-5 w-full flex flex-col items-center text-center text-base">
                <div className="text-5xl font-semibold mt-6">
                    {title}
                </div>
                <div className="max-w-3xl mt-4 text-[#000a] lg:text-lg">
                    {subtitle}
                </div>

                <div className="mt-16 flex gap-6">
                    <div>
                        <div className="flex gap-1">
                            {renderTimeDigit(getDigits(timer.minutes)[0])}
                            {renderTimeDigit(getDigits(timer.minutes)[1])}
                        </div>
                        <div className="mt-2 text-center">
                            Minutos
                        </div>
                    </div>
                    <div>
                        <div className="flex gap-1">
                            {renderTimeDigit(getDigits(timer.seconds)[0])}
                            {renderTimeDigit(getDigits(timer.seconds)[1])}
                        </div>
                        <div className="mt-2 text-center">
                            Segundos
                        </div>
                    </div>
                </div>

                {!!amountSaved && (
                    <div className="max-w-3xl mt-10 text-[#000a]">
                        Com o Pacote {packages[toPackageKey].label} você já teria economizado um total de <span className="font-semibold">{utilsProvider.formatNumberCurrency(amountSaved)}</span>
                    </div>
                )}

                <div className="my-10 w-full flex items-center">
                    <div className="w-full h-[1px] bg-[#dedede]" />
                    <div className="px-4 py-2 rounded-full bg-primary text-white font-semibold text-nowrap">
                        OFERTA ÚNICA
                    </div>
                    <div className="w-full h-[1px] bg-[#dedede]" />
                </div>
                <div className="max-w-3xl mt-4 text-[#000a]">
                    Aquira o nosso Pacote {packages[toPackageKey].label} utilizando o seu Pacote {packages[fromPackageKey].label} como desconto e tenha acesso a todos os benefícios abaixo!
                </div>
                <div className="mt-8 w-full max-w-3xl">
                    <div className="w-full py-3 bg-primary rounded-t-lg text-white font-semibold flex items-center justify-center gap-1">
                        <StarRounded className="text-white" /> Benefícios
                    </div>
                    <div className="w-full border-2 border-primary border-t-0 relative rounded-b-lg">
                        <div className="absolute w-full h-full bg-primary opacity-0" />
                        <div className="z-[1] py-10 px-10 flex flex-col gap-5 text-start">
                            {packageBenefits[toPackageId].map(benefit => (
                                <div className="flex gap-4">
                                    <div className="mt-0 shrink-0 rounded-full">
                                        <benefit.Icon className="!w-6 !h-6" />
                                    </div>
                                    <div className="">
                                        <div className="font-semibold">
                                            {benefit.title}
                                        </div>
                                        <div className="text-[15px] mt-1 text-[#0009]">
                                            {benefit.description}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="mt-8 w-full max-w-2xl flex flex-col items-center">
                    {error && (
                        <Alert
                            severity="error"
                            variant="outlined"
                            className="mb-3 !bg-[#fff2f0]"
                        >
                            Ocorreu um problema ao gerar o seu desconto. Por favor, tente novamente.
                        </Alert>
                    )}
                    <Button
                        className="!rounded-lg !py-4 shadow-[inset_0_-4px_0_-2px_#0007,_inset_0_4px_0_-2px_#fff2] w-full max-w-sm"
                        onClick={handleSubmit}
                        loading={loading}
                    >
                        FAZER O UPGRADE
                    </Button>
                    <Button
                        className="mt-4 !text-primary hover:!text-primary/80 transition-colors"
                        onClick={() => history.push('/')}
                        type="text"
                    >
                        Não, obrigado
                    </Button>
                </div>
                <div className="mt-8 max-w-3xl text-base italic text-[#000a]">
                    Não perca essa chance, essa promoção irá expirar nos próximos 30 minutos e não aparecerá de novo caso você saia dessa tela
                </div>
            </div>
            <div className="w-full lg:max-w-96 flex justify-center">
                <div className="lg:sticky lg:top-16 bg-white w-full rounded border border-[#dedede] p-8 h-fit">

                    {findingPackages ? (
                        <div className="flex justify-center items-center h-96">
                            <LoadingSpin size="large" />
                        </div>
                    ) : (
                        <div className="text-slate-900">
                            <div
                                class="absolute inset-x-0 top-0 h-1"
                                style={{ background: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}

                            />

                            <div class="flex flex-col space-y-1.5">
                                <div class="flex items-center gap-3 mb-4">
                                    <div class={`p-2 bg-opacity-10 rounded-lg text-[${primaryColor}]`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-zap h-6 w-6">
                                            <path d="M4 14a1 1 0 0 1-.78-1.63l9.9-10.2a.5.5 0 0 1 .86.46l-1.92 6.02A1 1 0 0 0 13 10h7a1 1 0 0 1 .78 1.63l-9.9 10.2a.5.5 0 0 1-.86-.46l1.92-6.02A1 1 0 0 0 11 14z">
                                            </path>
                                        </svg>
                                    </div>
                                    <h3 class="font-medium leading-none tracking-tight text-2xl text-slate-950">{packages[toPackageKey].label}</h3>
                                </div>
                            </div>

                            <div class="space-y-2">
                                <div class="flex items-baseline">
                                    <span class="text-lg text-gray-500">R$</span>
                                    <span class="text-4xl font-semibold text-slate-900 mx-1">
                                        {utilsProvider.formatNumberCurrencyWithoutSymbol(packageOptions[toPackageKey]?.packagePrice - packageOptions[fromPackageKey]?.packagePrice)}
                                    </span>
                                    <span class="text-2xl font-semibold text-slate-900 text-opacity-50 mx-1 line-through">
                                        {utilsProvider.formatNumberCurrencyWithoutSymbol(packageOptions[toPackageKey]?.packagePrice)}
                                    </span>
                                </div>

                                <div className="pt-4">
                                    <button
                                        class="flex justify-center items-center w-full hover:opacity-90 text-white py-3 px-6 rounded-lg text-lg font-semibold transition-all"
                                        style={{ background: `linear-gradient(to right, ${primaryColor}, ${secondaryColor})` }}
                                        onClick={handleSubmit}
                                    >
                                        <div
                                            className="flex justify-start overflow-hidden"
                                            style={{
                                                width: loading ? 28 : 0,
                                                transition: 'width .3s ease',
                                            }}
                                        >
                                            <LoadingSpin color="#fff" />
                                        </div>
                                        Contratar {packages[toPackageKey].label}
                                    </button>
                                </div>

                                <div className="pt-1 text-gray-600">
                                    {packageOptions[toPackageKey]?.validDays && renderDescription(
                                        Schedule,
                                        <span>{packageOptions[toPackageKey]?.validDays} dias para utilizar os créditos do pacote</span>
                                    )}
                                    {packageOptions[toPackageKey]?.requestHoursToDueDate && renderDescription(
                                        HourglassEmpty,
                                        <span>Serviços entregues em <span className="font-semibold">{(parseFloat(packageOptions[toPackageKey].requestHoursToDueDate) / 24).toFixed(0)} dias úteis</span> por padrão!</span>
                                    )}
                                    {packageOptions[toPackageKey]?.maximumSubUsers == -1 && renderDescription(
                                        Person,
                                        <span>Cadastre integrantes do seu escritório para terem acesso a plataforma!</span>
                                    )}

                                    <div className="mt-6 text-md font-semibold leading-none flex items-center gap-2 text-slate-700">
                                        <span>Nossos Serviços</span>
                                    </div>

                                    <div className="mt-4 space-y-5 text-sm leading-6">
                                        {(packageOptions[toPackageKey]?.packageServices || []).map(service => renderPackageServices(service))}
                                    </div>
                                </div>

                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
}