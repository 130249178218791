import { PaymentOutlined, ReceiptOutlined, AttachMoneyOutlined } from "@material-ui/icons";

export const paymentMethods = {
    PIX: 'pix',
    CREDIT_CARD: 'credit_card',
    DEBIT_CARD: 'debit_card',

    types: {
        credit_card: {
            id: 1,
            key: 'credit_card',
            name: 'Cartão de crédito',
            value: 'Crédito',
            background: "#918B76",
            color: "#FCFAF9",
            icon: PaymentOutlined
        },
        pix: {
            id: 2,
            key: 'pix',
            value: 'Pix',
            name: 'Pix',
            background: "#70EE9C",
            color: "#FCFAF9",
            icon: AttachMoneyOutlined
        }
    }
};

export const paymentMethodsByProduct = {
    subscription: {
        pix: paymentMethods.types.pix,
        credit_card: paymentMethods.types.credit_card
    },
    package: {
        pix: paymentMethods.types.pix,
        credit_card: paymentMethods.types.credit_card
    },
    creditPackage: {
        pix: paymentMethods.types.pix,
        credit_card: paymentMethods.types.credit_card
    }
}

export const methods = [
    {
        id: 1,
        label: 'Pix',
        key: paymentMethods.PIX,
        value: paymentMethods.PIX
    },
    {
        id: 2,
        label: 'Crédito',
        key: paymentMethods.CREDIT_CARD,
        value: paymentMethods.CREDIT_CARD
    }
];


export default {
    paymentMethods,
    paymentMethodsByProduct,
    methods
}