import { useState } from "react";
import FormItem from "../../../shared/form/FormItem";
import Col from "../../../shared/grid/Col";
import Row from "../../../shared/grid/Row";
import Input from "../../../shared/Input";
import Card from "../../../shared/Card";
import Switch from "../../../shared/Switch";
import Form from "../../../shared/form/Form";
import BlogBanner from "../footer/blogBanner";
import { useBlog } from "../../../../context/marketing/blogContext";
import useForm from "../../../../hooks/useForm";
import SnackbarAlert from "../../../shared/SnackbarAlert";
import SaveChangesFooter from "./saveChangesFooter";

export default function BlogFooterConfig() {

    const ebookForm = useForm();

    const { updateChanges, changes, saveChanges, blogData, loading } = useBlog();

    const [includeEbook, setIncludeEbook] = useState(!!blogData?.ebook);

    const initialValues = {
        title: blogData?.ebook?.title || null,
        buttonLabel: blogData?.ebook?.buttonLabel || null,
        buttonUrl: blogData?.ebook?.buttonUrl || null,
    };

    const updateEbookInfo = (changedFields, formValues) => {
        updateChanges('ebook', formValues);
    }

    const handleSubmit = () => {
        saveChanges()
            .then(() => SnackbarAlert.success('Alterações salvas com sucesso!'));
    }

    return (
        <div className="w-full">
            <Card
                title="Banner"
                extra={(
                    <Switch onChange={setIncludeEbook} initialState={!!blogData?.ebook} />
                )}
            >
                {includeEbook && (
                    <>
                        <Form
                            form={ebookForm}
                            onFieldChange={updateEbookInfo}
                            onFinish={handleSubmit}
                            initialValues={initialValues}
                        >
                            <Row gutter={[24, 24]}>
                                <Col span={24}>
                                    <FormItem
                                        name="title"
                                        label="Texto do Banner"
                                        required
                                    >
                                        <Input placeholder="Insira o texto do banner" />
                                    </FormItem>
                                </Col>
                                <Col span={24} md={12} lg={12} xl={12}>
                                    <FormItem
                                        name="buttonLabel"
                                        label="Texto do Botão"
                                        required
                                    >
                                        <Input placeholder="Insira o texto do botão" />
                                    </FormItem>
                                </Col>
                                <Col span={24} md={12} lg={12} xl={12}>
                                    <FormItem
                                        name="buttonUrl"
                                        label="Link do Botão"
                                        required
                                    >
                                        <Input placeholder="https://facilitajuridico.com.br/" />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>

                        <div className="text-base mt-6 mb-2 text-[#999]">
                            Preview:
                        </div>

                        <BlogBanner
                            title={changes.ebook?.title || initialValues.title}
                            buttonLabel={changes.ebook?.buttonLabel || initialValues.buttonLabel}
                        />
                    </>
                )}
            </Card>

            <SaveChangesFooter
                onSave={() => ebookForm.submit()}
                showCancel={false}
                hasChanges={!!Object.keys(changes).length}
                loading={loading.editingBlog}
            />

        </div>
    )
}