import Async from "../components/Async";

const Redirect = Async(() => import('../pages/redirect/redirect'));
const RedirectWithHash = Async(() => import('../pages/redirect/redirectWithHash'));

const externalRoutes = {
    redirectShortLink: {
        id: 'redirectShortLink',
        path: '/redirect/:hash',
        component: RedirectWithHash,
    },
    redirectPage: {
        id: 'redirect',
        path: '/redirect',
        component: Redirect,
    },
}

const externalPages = Object.values(externalRoutes);

export default {
    externalRoutes,
    externalPages
}