import { post, get } from '../api';
import { stepsTransactions } from '../../constant/status.transactions.constant';

const purchaseRequest = async (purchaseData) =>
    post({
        type: 'user',
        service: `customers/purchase/request`,
        data: purchaseData
    });

const purchaseCreditPackage = async (purchaseData) => {
    const res = await post({
        type: 'user',
        service: `customers/purchase/credit-package`,
        data: purchaseData
    });

    return res;
}

const purchaseSubscription = async (purchaseData) =>
    post({
        type: 'user',
        service: `customers/purchase/subscription`,
        data: purchaseData
    });

const purchaseExternal = async (data) =>
    post({
        type: 'user',
        service: `customers/purchase/external`,
        data: {
            ...data,
            netPrice: getNetPrice(data.price, (data.tax || 0)),
            tax: 0
        }
    });

const findRecurrenceSettings = async () =>
    get({
        type: 'user',
        service: `customers/purchase/recurrence-settings`,
        data: {}
    }).then(res => res.data);

const getNetPrice = (price, tax) =>
    (parseFloat(price) - parseFloat(tax));

const findLastPurchase = () =>
    get({
        type: 'user',
        service: `customers/purchase/last-purchase`,
        data: {}
    }).then(res => res.data);

const getCustomerConversionData = async (eventName, pixelID, user, transactionId) => {
    return {
        eventName: eventName,
        pixelID: pixelID,
        email: user.email,
        transactionId: transactionId,
        userAgent: window.navigator.userAgent
    }
}

const submitPurchaseData = async (purchaseData) =>
    post({
        type: 'user',
        service: `customers/purchase/submit-purchase-data`,
        data: purchaseData
    }).catch(err => null);

const validateProductPurchase = async (customerId, productTypeKey, productId) =>
    post({
        type: 'user',
        service: `customers/purchase/validate-product`,
        data: { customerId, productTypeKey, productId }
    }).then(res => ({
        canPurchase: res.data.canPurchase,
        redirectUrl: res.data.redirectUrl
    }));

const generateInvoiceFile = async (transactionIds, language) =>
    post({
        type: 'user',
        service: `customers/purchase/invoice-file`,
        data: { transactionIds, language }
    })
        .then(res => res.data);

const findProviderTransaction = async (gateway, transactionId) => {
    try {
        const response = await get({
            type: 'user',
            service: `customers/purchase/transaction/${gateway}/${transactionId}`
        });

        if (!response.data) {
            return null;
        }

        const { id, status, paymentMethod } = response.data;

        const statusMap = {
            'WP': { status: stepsTransactions.WAITING_PAYMENT, payment: 'F' },
            'PA': { status: stepsTransactions.PROCESSING, payment: 'F' },
            'PC': { status: stepsTransactions.PAID, payment: 'A' },
            'PF': { status: stepsTransactions.REFUSED, payment: 'F' },
            'PR': { status: stepsTransactions.REFUSED, payment: 'F' },
            'RR': { status: stepsTransactions.PENDING_REFUND, payment: 'F' },
            'RP': { status: stepsTransactions.REFUNDED, payment: 'P' },
            'CB': { status: stepsTransactions.CHARGEDBACK, payment: 'C' }
        };

        const mappedStatus = statusMap[status] || { status: stepsTransactions.PROCESSING, payment: 'F' };

        return {
            id,
            ...mappedStatus,
            paymentMethod
        };
    } catch (error) {
        console.error('Erro ao buscar transação:', error);
        return null;
    }
};

const processPayment = async (purchase) => {
    post({
        type: 'user',
        service: `customers/purchase/${purchase.transactionId}/status-webhook/${purchase.gateway}`,
        data: {
            id: purchase.transactionId,
            status: purchase.status
        }
    });
}

export default {
    getNetPrice,
    purchaseRequest,
    purchaseCreditPackage,
    purchaseSubscription,
    purchaseExternal,
    findRecurrenceSettings,
    findLastPurchase,
    getCustomerConversionData,
    submitPurchaseData,
    validateProductPurchase,
    generateInvoiceFile,
    findProviderTransaction,
    processPayment
}