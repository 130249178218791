
// Layout Components
import DocsEditorLayout from '../layouts/docs.editor.layout';
import ExternalRegisterLayout from "../layouts/external.register.layout";
import ExternalRequestLinkLayout from "../layouts/external.request.link.layout";
import LoginLayout from "../layouts/login.layout";
import PageLayout from "../layouts/page.layout";
import requestFormLayout from "../layouts/request.form.layout";
import CleanLayout from "../layouts/clean-layout";

// Routes
import authRoute from "./authentication/auth.route";
import customerRoute from "./customer/customer.route";
import correspondentRoute from "./correspondent/correspondent.route";
import employessRoute from "./employess/employess.route";
import marketplaceRoute from "./marketplace/marketplace.route";
import requestRoute from "./request/request.route";
import requestDevelopmentRoute from "./request/request.development.docs.route";
import agendaRoute from "./request/agenda.route";
import financialRoute from "./financial/financial.route";
import voucherRoute from "./marketing/voucher.route";
import referralRoute from "./marketing/referral.route";
import videosRoute from "./marketing/videos.route";
import emailSendingRoute from "./marketing/email.sending.route";
import bannerRoute from "./marketing/banner.route";
import crmRoute from "./crm.route";

import loginService from "../domain/services/login.service";
import redirectRoute from './redirect.route';
import blogRoute from "./institutional/blog.route";
import marketingRoute from "./marketing/marketing.route";

const routes = {
    ...authRoute.routes,
    ...customerRoute.routes,
    ...customerRoute.externalRoutes,
    ...correspondentRoute.routes,
    ...correspondentRoute.externalRoutes,
    ...employessRoute.routes,
    ...requestRoute.routes,
    ...requestRoute.formRoutes,
    ...requestRoute.externalRoutes,
    ...voucherRoute.routes,
    ...marketplaceRoute.routes,
    ...marketplaceRoute.externalRoutes,
    ...agendaRoute.routes,
    ...financialRoute.routes,
    ...referralRoute.routes,
    ...referralRoute.externalRoutes,
    ...videosRoute.routes,
    ...emailSendingRoute.routes,
    ...emailSendingRoute.externalRoutes,
    ...requestDevelopmentRoute.routes,
    ...crmRoute.routes,
    ...crmRoute.externalRoutes,
    ...redirectRoute.externalRoutes,
    ...bannerRoute.routes,
    ...customerRoute.cleanRoutes
};

export const routesLayout = [
    {
        name: 'loginLayout',
        component: LoginLayout,
        routes: [...authRoute.pages],
    },
    {
        name: 'requestFormLayout',
        component: requestFormLayout,
        routes: [...requestRoute.formPages]
    },
    {
        name: 'pageLayout',
        component: PageLayout,
        routes: [
            ...crmRoute.pages,
            ...customerRoute.pages,
            ...correspondentRoute.pages,
            ...employessRoute.pages,
            ...requestRoute.pages,
            ...voucherRoute.pages,
            ...marketplaceRoute.pages,
            ...agendaRoute.pages,
            ...financialRoute.pages,
            ...videosRoute.pages,
            ...emailSendingRoute.pages,
            ...referralRoute.pages,
            ...bannerRoute.pages,
            ...blogRoute.pages,
            ...marketingRoute.pages,
        ],
    },
    {
        name: 'docsEditorLayout',
        component: DocsEditorLayout,
        routes: [...requestDevelopmentRoute.pages],
    },
    {
        name: 'externalRegisterLayout',
        component: ExternalRegisterLayout,
        routes: [
            ...customerRoute.externalPages,
            ...correspondentRoute.externalPages,
            ...marketplaceRoute.externalPages,
            ...emailSendingRoute.externalPages,
        ],
    },
    {
        name: 'externalRequestLinkLayout',
        component: ExternalRequestLinkLayout,
        routes: [...requestRoute.externalPages],
    },
    {
        name: 'cleanLayout',
        component: CleanLayout,
        routes: [
            ...redirectRoute.externalPages,
            ...referralRoute.externalPages,
            ...crmRoute.externalPages,
            ...customerRoute.cleanPages
        ]
    }
];

export const getValidatedRoutesLayout = () => routesLayout.map(routeLayout => ({
    ...routeLayout,
    routes: routeLayout.routes.filter(route => !route.validator || route.validator())
}
));

export const getAllRoutesByRoles = (currentUser) => {

    if (!currentUser)
        return [];

    return Object
        .values(routes)
        .filter((x) => loginService.isValidRoles(x.roles, currentUser));

}

export const getRoutesByRoles = (currentUser) => {

    if (!currentUser)
        return [];

    const validatedRoutes = getValidatedRoutesLayout()
        .find((x) => x.name == 'pageLayout')
        .routes.reduce((agg, x) => {

            const canShowRoute = loginService.isValidRoles(x.roles, currentUser) && !x.disabledMenu;
            if (!canShowRoute)
                return agg;

            const hasChildren = x.children && x.children.length > 0;
            if (!hasChildren)
                return [...agg, x];

            const children = x.children.filter((c) => !c.roles || loginService.isValidRoles(c.roles, currentUser));
            const routeWithFilteredChildren = { ...x, children };

            return [...agg, routeWithFilteredChildren];
        }, []);

    const routesWithOptions = validatedRoutes.filter((x) => {
        if (!x.children)
            return true;

        return !!x.children.length;
    });

    return routesWithOptions;

}