import Async from "../../components/Async";

const CampaignDetails = Async(() => import('../../pages/marketing/campaign.details'));
const EmailSendingCancellation = Async(() => import('../../pages/marketing/email.sending.cancellation'));
const AutomationEditor = Async(() => import('../../pages/marketing/automations/automationEditor'));
const AutomationDetails = Async(() => import('../../pages/marketing/automations/automationDetails'));
const ContactList = Async(() => import('../../pages/marketing/lists'));

const routes = {
    listas: {
        id: 'lists',
        path: '/marketing/lists',
        name: 'Listas',
        component: ContactList,
        roles: ['admin', 'email'],
    },
    automationEditor: {
        id: 'automations',
        path: '/marketing/automations/editor',
        name: 'Automações',
        component: AutomationEditor,
        roles: ['admin', 'email'],
    },
    automationSpecificEditor: {
        id: 'automations',
        path: '/marketing/automations/editor/:id',
        name: 'Automações',
        component: AutomationEditor,
        roles: ['admin', 'email'],
    },
    campaignDetails: {
        id: 'campaignDetails',
        name: 'Detalhes da campanha',
        path: '/marketing/campaigns/:id',
        component: CampaignDetails,
        roles: ['admin'],
    },
    automationDetails: {
        id: 'automationDetails',
        name: 'Detalhes da Automação',
        path: '/marketing/automations/:id',
        component: AutomationDetails,
        roles: ['admin'],
    },
}

const externalRoutes = {
    emailSendingCancellation: {
        id: 'emailSendingCancellation',
        name: 'Cancelar inscrição',
        path: '/marketing/email-sending-cancellation/:payload',
        component: EmailSendingCancellation
    },
}

const pages = Object.values(routes);

const externalPages = Object.values(externalRoutes);

export default {
    routes,
    externalRoutes,
    pages,
    externalPages
}