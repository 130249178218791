import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Grid } from '@material-ui/core';
import { EventOutlined } from '@material-ui/icons';

import RequestServiceDocumentsContent from './request.service.documents.content.component';
import LoadingProgress from '../../LoadingProgress';

import communicationProvider from '../../../providers/communication.provider';

import requestServiceDocumentService from '../../../domain/services/request/service/request.service.document.service';
import { useTour } from '../../../context/tourContext';
import Card from "../../shared/Card";
import Row from "../../shared/grid/Row";
import Col from "../../shared/grid/Col";
import RequestCorrectionDocumentsCard from "./requestCorrectionDocumentsCard";

const RequestServiceDocuments = ({ request, mainRequestId, isLoading: isPageLoading, alert }) => {

    const { registerRef } = useTour();

    const documentsRef = registerRef('concludedRequestTour', 'documents')

    const [documents, setDocuments] = useState({});

    const [isDocumentsLoading, setIsDocumentsLoading] = useState(true);

    useEffect(() => findDocuments(mainRequestId), []);

    const findDocuments = async (id) =>
        requestServiceDocumentService
            .find(id)
            .then(setDocuments)
            .catch(err => communicationProvider.getErrorMessage(err, alert, 'Não foi possível encontrar documentos!'))
            .finally(() => setIsDocumentsLoading(false));

    const isLoading = isPageLoading || isDocumentsLoading;

    const getCorrectionDocuments = () => {
        const correctionDocuments = (documents.additionalDocuments || []).filter(doc => doc.correctionSource);

        const docs = correctionDocuments.reduce((obj, doc) => {
            if (!obj[doc.correctionSource])
                obj[doc.correctionSource] = {
                    documents: [],
                    correctionSource: doc.correctionSource,
                    created: doc.created,
                };

            obj[doc.correctionSource].documents.push(doc);

            return obj;
        }, {});

        const sortedDocs = Object.values(docs).sort((a, b) => new Date(b.created) - new Date(a.created));

        return Object.values(sortedDocs);
    };

    const getAdditionalDocuments = () => (documents.additionalDocuments || []).filter(doc => !doc.correctionSource);

    const getLastCorrectionSendDate = () => {

        if (!request.lastCorrectionSendDate)
            return null;

        return (
            <div className="mt-2">
                <div className="text-xs text-gray-500 float-right">
                    <EventOutlined style={{ marginRight: 6, marginTop: -1, fontSize: 15 }} />

                    Correção solicitada em {moment(request.lastCorrectionSendDate).format("DD [de] MMMM [de] YYYY [às] HH:mm")}
                </div>
            </div>
        )
    }

    const getContent = () => {

        if (isLoading)
            return (
                <Grid style={{ width: '100%', marginTop: 10 }}>
                    <LoadingProgress message="Buscando documentos..." />
                </Grid>
            )

        return (
            <div className="flex justify-between items-center rounded-lg">
                <div className="w-full text-xl font-semibold">
                    <div ref={documentsRef}>

                        <RequestServiceDocumentsContent
                            documents={documents}
                            additionalDocuments={getAdditionalDocuments()}
                            request={request}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const correctionDocuments = getCorrectionDocuments();

    return (
        <Row gutter={[24, 24]}>

            <Col span={24}>
                <Card>
                    <div className="text-lg font-medium">
                        Documentos Finais
                    </div>
                    <div className="mt-4">
                        {getContent()}
                    </div>

                    {getLastCorrectionSendDate()}
                </Card>
            </Col>

            {!!correctionDocuments.length && (
                <Col span={24}>
                    <RequestCorrectionDocumentsCard
                        documents={correctionDocuments}
                        request={request}
                    />
                </Col>
            )}
        </Row>
    );
}

export default RequestServiceDocuments;