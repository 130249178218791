import { useEffect, useState } from "react";
import Card from "../../../shared/Card";
import Form from "../../../shared/form/Form";
import SnackbarAlert from "../../../shared/SnackbarAlert";
import Switch from "../../../shared/Switch";
import SaveChangesFooter from "./saveChangesFooter";
import { useBlog } from "../../../../context/marketing/blogContext";
import useForm from "../../../../hooks/useForm";
import Row from "../../../shared/grid/Row";
import Col from "../../../shared/grid/Col";
import FormItem from "../../../shared/form/FormItem";
import Input from "../../../shared/Input";
import Textarea from "../../../shared/Textarea";
import CustomSelect from "../../../shared/CustomSelect";
import useRequest from "../../../../hooks/useRequest";
import BlogsService from "../../../../domain/services/marketing/blog.service";
import BlogHeaderCta from "../header/blogHeaderCta";
import DragAndDropFile from "../../../shared/DragAndDropFile";
import employeeService from "../../../../domain/services/employee.service";
import UserSelectWithAvatar from "../../../users/UserSelectWithAvatar";

export default function BlogHeaderConfig() {

    const mainInfoForm = useForm();
    const headerForm = useForm();
    const verticalBannerForm = useForm();

    const { updateChanges, changes, saveChanges, blogData, loading, setBlogData } = useBlog();

    const [findTags, findingTags] = useRequest(BlogsService.findBlogTags);
    const [updateCover, updatingCover] = useRequest((file, blogId) => BlogsService.updateBlogImage(file, blogId, 'cover'));
    const [updateVerticalBanner, updatingVerticalBanner] = useRequest((file, blogId) => BlogsService.updateBlogImage(file, blogId, 'verticalBanner'));
    const [findVerticalBanners, findingVerticalBanners] = useRequest(BlogsService.findVerticalBanners);

    const [includeHeaderCta, setIncludeHeaderCta] = useState(false);
    const [includeVerticalBanner, setIncludeVerticalBanner] = useState(false);

    const [tags, setTags] = useState([]);
    const [coverImage, setCoverImage] = useState(blogData.coverImage?.url);
    const [verticalBannerImage, setVerticalBannerImage] = useState(blogData.verticalBanner?.url);
    const [verticalBanners, setVerticalBanners] = useState([]);

    const initialValues = {
        title: blogData.title,
        tags: blogData.tags,
        authorUserId: blogData.authorUserId,
    }

    const headerCtaInitialValues = {
        title: blogData.headerCta?.title || null,
        buttonLabel: blogData.headerCta?.buttonLabel || null,
        buttonUrl: blogData.headerCta?.buttonUrl || null,
    };

    const verticalBannerInitialValues = {
        redirectUrl: blogData.verticalBanner?.redirectUrl || null,
    };

    const updateHeaderCtaInfo = (changedFields, formValues) => {
        updateChanges('headerCta', formValues);
    }

    const updateMainInfo = (changedFields) => {
        const [key, value] = Object.entries(changedFields)[0];
        updateChanges(key, value);
    }

    const handleAddNewTag = (label) => {
        setTags(prev => [
            ...prev,
            {
                label,
                value: label.toLowerCase(),
            }
        ]);
    }

    const handleSave = () => {
        mainInfoForm.validateFields()
            .then(() => includeHeaderCta ? headerForm.validateFields() : Promise.resolve())
            .then(() => includeVerticalBanner ? verticalBannerForm.validateFields() : Promise.resolve())
            .then(saveChanges)
            .then(() => SnackbarAlert.success('Alterações salvas com sucesso!'))
            .catch(() => { });
    }

    const validateImage = (file) => {
        const validTypes = [".jpg", ".jpeg", ".png"];
        const isValid = validTypes.some(ext => file.name.includes(ext));
        return isValid;
    }

    const handleImageUpload = async (file, type) => {

        const imageConfigByType = {
            cover: {
                ...BlogsService.imageConfigByType.cover,
                stateFunction: setCoverImage,
                updateFunction: updateCover,
            },
            verticalBanner: {
                ...BlogsService.imageConfigByType.verticalBanner,
                stateFunction: setVerticalBannerImage,
                updateFunction: updateVerticalBanner,
            },
        }

        const imageConfig = imageConfigByType[type];

        const isValid = validateImage(file);
        if (!isValid) return;

        imageConfig.updateFunction(file, blogData.id)
            .then(resp => {
                const newImage = resp[imageConfig.key];
                imageConfig.stateFunction(newImage.url);
                setBlogData(prev => ({
                    ...prev,
                    [imageConfig.key]: newImage
                }))
                SnackbarAlert.success(`${imageConfig.label} atualizada com sucesso!`)
            })
            .catch(() => SnackbarAlert.error(`Ocorreu um problema ao atualizar a ${imageConfig.label}, por favor tente novamente`))
    };

    const handleClickSectionChange = (newState) => {
        setIncludeHeaderCta(newState);

        if (newState)
            return;

        updateChanges('headerCta', null);
    }

    const handleClickVerticalBannerChange = (newState) => {
        setIncludeVerticalBanner(newState);

        if (newState)
            return;

        updateChanges('verticalBanner', null);
    }

    const handleVerticalBannerSelect = (bannerUrl) => {

        const selectedBanner = verticalBanners.find(banner => banner.url === bannerUrl);
        if (!selectedBanner)
            return;

        setVerticalBannerImage(selectedBanner.url);
        updateChanges('verticalBanner', selectedBanner);
    };

    useEffect(() => {
        findTags()
            .then(res => setTags(res.map(tag => ({
                value: tag,
                label: `${tag[0].toUpperCase()}${tag.slice(1)}`,
            }))));
    }, []);

    useEffect(() => {
        if (blogData) {
            setIncludeHeaderCta(!!blogData.headerCta);
            setIncludeVerticalBanner(!!blogData.verticalBanner);
        }
    }, [blogData]);

    useEffect(() => {

        findVerticalBanners()
            .then(setVerticalBanners);

    }, []);

    return (
        <div className="w-full">
            <Card
                title="Configurações Principais"
            >
                <Form
                    onFieldChange={updateMainInfo}
                    form={mainInfoForm}
                    initialValues={initialValues}
                >
                    <Row gutter={[24, 24]}>
                        <Col span={24} lg={12} xl={12}>
                            <FormItem
                                name="title"
                                label="Título do Artigo"
                            >
                                <Textarea
                                    placeholder="Título do Artigo"
                                />
                            </FormItem>
                        </Col>
                        <Col span={24} lg={12} xl={12}>
                            <FormItem
                                name="tags"
                                label="Categorias"
                            >
                                <CustomSelect
                                    placeholder="Selecione as categorias"
                                    emptyText="Nenhuma categoria encontrada. Cadastre uma nova categoria"
                                    loading={findingTags}
                                    options={tags}
                                    allowClear
                                    multiSelect
                                    allowSearch
                                    onAddNewItem={handleAddNewTag}
                                />
                            </FormItem>
                        </Col>
                        <Col span={24} lg={12} xl={12}>
                            <FormItem
                                name="authorUserId"
                                label="Autor do Artigo"
                                required
                            >
                                <UserSelectWithAvatar
                                    placeholder="Selecione o autor"
                                    onFetch={employeeService.findAll}
                                    showEditButton
                                />
                            </FormItem>
                        </Col>
                    </Row>
                </Form>

                <Row className="mt-6">
                    <Col span={24} lg={16} xl={16}>
                        <FormItem label="Imagem de Capa">
                            <DragAndDropFile
                                handleFile={(file) => handleImageUpload(file, 'cover')}
                                value={coverImage}
                                isImage
                                loading={updatingCover}
                                formId="cover-image-form"
                                inputId="cover-image-input"
                            />
                        </FormItem>
                    </Col>
                </Row>

            </Card>
            <Card
                className="mt-6"
                title="Seção de Clique"
                extra={(
                    <Switch
                        onChange={handleClickSectionChange}
                        value={includeHeaderCta}
                    />
                )}
            >
                {includeHeaderCta && (
                    <>
                        <Form
                            form={headerForm}
                            onFieldChange={updateHeaderCtaInfo}
                            initialValues={headerCtaInitialValues}
                        >
                            <Row gutter={[24, 24]}>
                                <Col span={24}>
                                    <FormItem
                                        name="title"
                                        label="Texto Principal"
                                        required
                                    >
                                        <Input placeholder="Insira o texto" />
                                    </FormItem>
                                </Col>
                                <Col span={24} md={12} lg={12} xl={12}>
                                    <FormItem
                                        name="buttonLabel"
                                        label="Texto do Botão"
                                        required
                                    >
                                        <Input placeholder="Insira o texto do botão" />
                                    </FormItem>
                                </Col>
                                <Col span={24} md={12} lg={12} xl={12}>
                                    <FormItem
                                        name="buttonUrl"
                                        label="Link do Botão"
                                        required
                                    >
                                        <Input placeholder="https://facilitajuridico.com.br/" />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>

                        <div className="text-base mt-6 mb-2 text-[#999]">
                            Preview:
                        </div>

                        <div className="flex justify-center">
                            <div className="w-full max-w-3xl py-3 px-4 rounded-lg bg-[#fafafa]">

                                <BlogHeaderCta
                                    title={changes.headerCta?.title || headerCtaInitialValues.title}
                                    buttonLabel={changes.headerCta?.buttonLabel || headerCtaInitialValues.buttonLabel}
                                />
                            </div>
                        </div>
                    </>
                )}
            </Card>

            <Card
                className="mt-6"
                title="Banner Vertical"
                extra={(
                    <Switch
                        onChange={handleClickVerticalBannerChange}
                        value={includeVerticalBanner}
                    />
                )}
            >
                {includeVerticalBanner && (
                    <>
                        <Form
                            form={verticalBannerForm}
                            onFieldChange={(changedFields, formValues) => {
                                updateChanges('verticalBanner', { ...blogData.verticalBanner, ...formValues });
                            }}
                            initialValues={verticalBannerInitialValues}
                        >

                            <FormItem label="Banners já utilizados" className="mb-8">
                                <CustomSelect
                                    placeholder="Selecione um banner utilizado anteriormente"
                                    emptyText="Nenhum banner encontrado"
                                    loading={findingVerticalBanners}
                                    value={verticalBannerImage}
                                    options={verticalBanners.map(banner => ({
                                        value: banner.url,
                                        label: banner.name
                                    }))}
                                    allowSearch
                                    onChange={handleVerticalBannerSelect}
                                />
                            </FormItem>

                            <Row gutter={[24, 24]}>

                                <Col span={24} lg={12} xl={12}>
                                    <FormItem label="Imagem do Banner">
                                        <DragAndDropFile
                                            handleFile={(file) => handleImageUpload(file, 'verticalBanner')}
                                            value={verticalBannerImage}
                                            isImage
                                            loading={updatingVerticalBanner}
                                            formId="vertical-banner-form"
                                            inputId="vertical-banner-input"
                                        />
                                    </FormItem>
                                </Col>

                                <Col span={24} md={12} lg={12} xl={12}>
                                    <FormItem
                                        name="redirectUrl"
                                        label="Link do Banner"
                                    >
                                        <Input placeholder="https://facilitajuridico.com.br/" />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>
                    </>
                )}
            </Card>

            <SaveChangesFooter
                onSave={handleSave}
                showCancel={false}
                hasChanges={!!Object.keys(changes).length}
                loading={loading.editingBlog}
            />

        </div>
    )
}