import { useEffect, useState } from "react";

import CustomSelect from "../shared/CustomSelect";
import Avatar from "../shared/Avatar";
import EditAuthorModal from "../marketing/blog/blogEditor/EditAuthorModal";

import useRequest from "../../hooks/useRequest";

export default function UserSelectWithAvatar({
    placeholder = "Selecione um usuário",
    onFetch,
    value,
    onChange,
    required,
    showEditButton = false,
    ...props
}) {

    const [findUsers, findingUsers] = useRequest(onFetch);

    const [options, setOptions] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        findUsers()
            .then(({ data: users }) => {
                const formattedOptions = users.map(user => ({
                    value: user.userId || user.id,
                    label: user.name,
                    userData: user,
                    icon: (
                        <Avatar
                            src={user?.profileInfo?.picture?.url}
                            name={user.name}
                            size="sm"
                            className="mr-2"
                        />
                    )
                }));
                setOptions(formattedOptions);
            });
    }, []);

    useEffect(() => {
        if (value) {
            const selectedOption = options.find(opt => opt.value === value);
            setSelectedUser(selectedOption?.userData || null);
        } else {
            setSelectedUser(null);
        }
    }, [value, options]);

    const handleEditClick = (e) => {
        e.preventDefault();
        setIsEditModalOpen(true);
    };

    return (
        <div className="w-full">
            <CustomSelect
                placeholder={placeholder}
                loading={findingUsers}
                options={options}
                value={value}
                onChange={onChange}
                allowClear={false}
                allowSearch
                optionFilterProp="label"
                required={required}
                {...props}
            />

            {showEditButton && value && (
                <button
                    onClick={handleEditClick}
                    className="text-[#376fd0] hover:text-[#2855a3] text-sm mt-2 transition-colors duration-200"
                >
                    Editar informações do autor
                </button>
            )}

            {isEditModalOpen && (
                <EditAuthorModal
                    open={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    user={selectedUser}
                    onSave={(updatedUser) => {
                        const newOptions = options.map(opt =>
                            opt.value === updatedUser.userId ? {
                                ...opt,
                                userData: { ...opt.userData, profileInfo: updatedUser.profileInfo },
                                icon: (
                                    <Avatar
                                        src={updatedUser?.profileInfo?.picture?.url}
                                        name={opt.name}
                                        size="sm"
                                        className="mr-2"
                                    />
                                )
                            } : opt
                        );
                        setOptions(newOptions);
                        setIsEditModalOpen(false);
                    }}
                />
            )}
        </div>
    );
} 