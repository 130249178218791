
import { Link } from "react-router-dom";
import ebookImg from '../../../../assets/img/ebook.jpg';
import BlogButton from "../blogButton";

export default function BlogBanner({
    title = 'Um texto para incentivar o cliente a clicar nesse CTA',
    bannerImage = ebookImg,
    buttonLabel = 'Texto do botão',
    buttonUrl = '',
}) {

    const renderButton = () => (
        <BlogButton>
            {buttonLabel}
        </BlogButton>
    )

    return (
        <div
            className="w-full p-12 rounded-lg flex flex-col text-center md:text-start md:flex-row items-center justify-between gap-6 bg-center bg-cover"
            style={{
                backgroundImage: `url(${bannerImage})`,
                backgroundColor: '#131313',
                backgroundBlendMode: 'overlay',
            }}
        >
            <div className="text-2xl text-white font-semibold">
                {title}
            </div>
            <div className="shrink-0 max-w-[50%] min-w-[220px]">
                {!buttonUrl ? renderButton() : (
                    <Link to={buttonUrl}>
                        {renderButton()}
                    </Link>
                )}
            </div>
        </div>
    )
}