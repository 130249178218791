import React from 'react';


import SimpleModal from "../../shared/SimpleModal";

function DocumentPreview({
    onSelect,
    file,
}) {

    const isAudio = file?.fileType?.includes('audio');

    const closeModal = () => {
        onSelect({}, false);
    };

    const getUrl = () =>
        file.name.indexOf('.doc') != -1 ||
            file.name.indexOf('.xls') != -1 ||
            file.name.indexOf('.ppt') != -1 ||
            file.name.indexOf('.dot') != -1 ||
            file.name.indexOf('.csv') != -1
            ? 'https://view.officeapps.live.com/op/embed.aspx?src=' + file.url
            : file.url;

    return (
        <SimpleModal
            open={file.show}
            onClose={closeModal}
            title="Visualizar Arquivo"
        >
            {isAudio && !!file?.audioTranscription && (
                <div className="text-base text-gray-700">
                    <b>Transcrição:</b> {file.audioTranscription}
                </div>
            )}
            <iframe src={getUrl()} width='100%' height={isAudio ? '64px' : '400px'} />
        </SimpleModal>
    );
}

export default DocumentPreview;
