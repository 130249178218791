import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { Payment, CheckCircle, Schedule } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { getDocByCollection } from "../../../domain/services/api/firebase";
import { post } from '../../../domain/services/api';
import { stepsTransactions } from '../../../domain/constant/status.transactions.constant';
import customerPurchaseService from '../../../domain/services/financial/customer.purchase.service';

const WaitingPayment = () => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const transactionId = searchParams.get('transactionId');
    const gateway = searchParams.get('gateway');

    const [isCheckingStatus, setIsCheckingStatus] = useState(false);

    const sendWebhook = async (status, transactionProviderId, paymentMethod) => {
        try {
            await post({
                type: 'user',
                service: `customers/purchase/${transactionId}/status-webhook/${gateway}`,
                data: {
                    order: transactionProviderId,
                    payment_status: status,
                    payment_method: paymentMethod
                }
            });
        } catch (error) {
            console.error('Erro ao enviar webhook:', error);
        }
    };

    const redirectToPurchaseThank = (doc) => {
        const isFinished = doc.get('isFinished');
        const status = doc.get('status');

        if (isFinished && status === stepsTransactions.PAID) {
            window.close();
        }
    };

    const checkProviderStatus = async () => {
        if (isCheckingStatus || !gateway) return;

        setIsCheckingStatus(true);
        try {
            const result = await customerPurchaseService.findProviderTransaction(gateway, transactionId);

            if (result) {
                await sendWebhook(result.payment, result.id, result.paymentMethod);

                if (result.status === stepsTransactions.PAID) {
                    window.close();
                }
            }
        } catch (error) {
        } finally {
            setIsCheckingStatus(false);
        }
    };

    useEffect(() => {
        if (transactionId) {
            if (gateway) {
                const interval = setInterval(checkProviderStatus, 5000);
                return () => clearInterval(interval);
            } else {
                getDocByCollection('transactions', transactionId, redirectToPurchaseThank);
            }
        }
    }, [transactionId, gateway]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="80vh"
            textAlign="center"
            p={3}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb={4}
            >
                <Payment style={{
                    fontSize: 80,
                    color: '#1976d2',
                    marginRight: '1rem'
                }} />
                <Schedule style={{
                    fontSize: 80,
                    color: '#1976d2',
                    marginRight: '1rem'
                }} />
                <CheckCircle style={{
                    fontSize: 80,
                    color: '#1976d2'
                }} />
            </Box>

            <Typography variant="h4" gutterBottom>
                Estamos processando seu pagamento
            </Typography>

            <Typography variant="body1" color="textSecondary" style={{ maxWidth: '600px' }}>
                Por favor, aguarde alguns instantes enquanto identificamos seu pagamento.
                Esta janela será fechada automaticamente assim que a confirmação for concluída.
            </Typography>

            <Typography variant="body2" color="textSecondary" style={{ marginTop: '2rem' }}>
                Não feche ou atualize esta página
            </Typography>
        </Box>
    );
};

export default WaitingPayment;
