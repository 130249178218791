import { Button } from "@material-ui/core";
import { FavoriteOutlined, FavoriteBorderOutlined } from "@material-ui/icons";
import { useState } from "react";
import requestFavoriteService from "../../../domain/services/request/request.favorite.service";
import LoadingSpin from "../../LoadingSpin";
import { Heart } from "react-feather";
import Tooltip from "../../shared/Tooltip";

export default function CustomerRequestFavoriteButton({ requestData, setAlert }) {

    const [loading, setLoading] = useState(false);
    const [favoritedId, setFavoritedId] = useState(requestData.favoritedId);

    const isFavorited = !!favoritedId;

    const removeFavorite = () => {
        requestFavoriteService.removeFavorite(requestData.id, favoritedId)
            .then(() => {
                setAlert('Favorito Removido com Sucesso!');
                setFavoritedId(null);
            })
            .catch(() => setAlert('ocorreu um problema, por favor tente novamente', 'error'))
            .finally(() => setLoading(false));
    }

    const addFavorite = () => {
        requestFavoriteService
            .createFavoriteService({
                requestId: requestData.id,
                customerId: requestData.customerId,
                serviceId: requestData.serviceId,
                areaId: requestData.areaId,
                modalityId: requestData.modalityId,
                correspondentId: requestData.correspondentId,
            })
            .then(({ data }) => {
                setAlert('Faciliter Favoritado com Sucesso!');
                setFavoritedId(data[0].id);
            })
            .catch((err) => setAlert('Ocorreu um problema ao favoritar o faciliter, por favor tente novamente', 'error'))
            .finally(() => setLoading(false));
    }

    const handleClick = () => {
        setLoading(true);

        if (isFavorited) {
            removeFavorite();
            return;
        }

        addFavorite();
    }

    const getButtonIcon = () => {

        if (loading) return LoadingSpin;

        return isFavorited ? FavoriteOutlined : FavoriteBorderOutlined;
    }

    const getButtonText = () => {
        return isFavorited ? 'Faciliter Favorito' : 'Favoritar';
    }

    const getTooltipText = () => {
        if (isFavorited) return 'Esse serviço foi concluído por um faciliter favoritado. Gostaria de removê-lo dos seus favoritos?';
        return 'Favoritando o profissional, suas próximas demandas serão direcionadas para ele sempre que possível.';
    }

    const Icon = getButtonIcon();

    return (
        <>
            <Tooltip
                title={getTooltipText()}
            >
                <button
                    className="w-full border hover:border-primary hover:text-primary rounded-lg px-4 py-3 flex items-center justify-center gap-2 transition-colors"
                    onClick={handleClick}
                >   
                    <Icon className="" />
                    {getButtonText()}
                </button>
            </Tooltip>
        </>
    );
}