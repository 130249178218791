export default function VerticalBanner({ imageUrl, redirectUrl }) {
    const content = (
        <div className={`rounded-lg overflow-hidden shadow-sm ${redirectUrl ? 'cursor-pointer' : ''}`}>
            <img
                src={imageUrl}
                alt="Banner vertical"
                className="w-full h-[600px] object-cover"
            />
        </div>
    );

    return (
        <div className="mt-6 sticky top-64">
            {redirectUrl ? (
                <a href={redirectUrl} target="_blank" rel="noopener noreferrer">
                    {content}
                </a>
            ) : content}
        </div>
    );
} 