import React from "react";

export default function Row({ children, gutter, style, className = '', ref }) {

    const horizontalGutter = Array.isArray(gutter) ? gutter[0] : gutter;
    const verticalGutter = Array.isArray(gutter) ? gutter[1] : undefined;

    const rowStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: `-${horizontalGutter / 2}px`,
        marginRight: `-${horizontalGutter / 2}px`,
        rowGap: verticalGutter && `${verticalGutter}px`,
        width: 'auto',
        position: 'relative',
        ...style,
    };

    const unfragmentChildren = (child) => {
        const isFragment = Symbol('react.fragment').toString() === child.type.toString();
    
        if (!isFragment)
            return [React.cloneElement(child, { gutter })];
    
        const parsedFragmentChildren = (child.props?.children || []).map(c => React.cloneElement(c, { gutter }))
    
        return [...parsedFragmentChildren];
    }

    const cols = React.Children.toArray(children)
        .filter(child => !!child)
        .reduce((childs, child) => {
            const unfragmentedChild = unfragmentChildren(child);
            return [...childs, ...unfragmentedChild];
        }, []);

    return <div ref={ref} className={className} style={rowStyle}>{cols}</div>;
}