import { useState } from "react";
import { ArrowBack, BarChartOutlined, CallToActionOutlined, EditOutlined, SettingsOutlined, VisibilityOutlined } from "@material-ui/icons";
import SeoConfig from "./seoConfig";
import BlogFooterConfig from "./blogFooterConfig";
import PageHeader from "../../../shared/layout/pageHeader";
import { Link } from "react-router-dom";
import Button from "../../../shared/Button";
import SkeletonLoading from "../../../SkeletonLoading";
import { useBlog } from "../../../../context/marketing/blogContext";
import BlogContent from "./blogContent";
import BlogViewModal from "../blogViewModal";
import BlogHeaderConfig from "./blogHeaderConfig";
import Swal from "sweetalert2";
import BlogEditorDesktopSidebar from "./sidebar/blogEditorDesktopSidebar";
import BlogEditorMobileSidebar from "./sidebar/blogEditorMobileSidebar";
import PublishArticleButton from "./publishArticleButton";
import datetimeProvider from "../../../../providers/datetime.provider";
import BlogMetrics from "./blogMetrics";

export default function BlogEditor() {

    const { blogData, loading, changes, discardChanges } = useBlog();

    const [selectedSidebarOption, setSelectedSidebarOption] = useState('editor');
    const [previewBlogId, setPreviewBlogId] = useState(null);

    const sidebarOptions = [
        {
            id: 'editor',
            label: 'Conteúdo',
            icon: <EditOutlined className="!w-5 !h-5" />,
            Component: () => <BlogContent />,
        },
        {
            id: 'header',
            label: 'Header',
            icon: <CallToActionOutlined className="!w-5 !h-5 rotate-180" />,
            Component: () => <BlogHeaderConfig />,
        },
        {
            id: 'footer',
            label: 'Footer',
            icon: <CallToActionOutlined className="!w-5 !h-5" />,
            Component: () => <BlogFooterConfig />,
        },
        {
            id: 'seo',
            label: 'Configurações de SEO',
            icon: <SettingsOutlined className="!w-5 !h-5" />,
            Component: () => <SeoConfig />,
        },
        {
            id: 'metrics',
            label: 'Métricas',
            icon: <BarChartOutlined className="!w-5 !h-5" />,
            Component: () => <BlogMetrics />,
        },
    ];

    const handleSidebarSelect = (id) => {
        if (!Object.keys(changes).length) {
            setSelectedSidebarOption(id);
            return;
        }

        Swal.fire({
            title: 'Descartar Alterações?',
            html: `
                Você tem alterações não salvas. Tem certeza que deseja descartá-las?
            `,
            icon: 'warning',
            confirmButtonText: 'Descartar Alterações',
            cancelButtonText: 'Continuar Editando',
            showCancelButton: true,
            reverseButtons: true,
        })
            .then(result => {
                if (!result.isConfirmed)
                    return;

                setSelectedSidebarOption(id)
                discardChanges();
            })
    }

    const componentToRender = sidebarOptions.find(opt => opt.id === selectedSidebarOption).Component();

    return (
        <div className="h-full">
            <PageHeader
                pageTitle="Blog"
                title={(
                    <div className="flex gap-1 items-center">
                        <Link to="/blog">
                            <Button
                                type="text"
                                icon={<ArrowBack className="translate-y-[1px]" />}
                                className="!p-3 !rounded-full"
                            >
                            </Button>
                        </Link>
                        <SkeletonLoading isDataLoading={loading.findingBlog}>
                            <div>{blogData?.title}</div>
                        </SkeletonLoading>
                    </div>
                )}
                extra={(
                    <div className="flex flex-col md:flex-row items-center gap-4 md:gap-6 mt-3">
                        {blogData?.published && (
                            <div className="text-sm text-[#0007]">
                                Publicado em {datetimeProvider.formatDateTime(blogData.published)}
                            </div>
                        )}
                        <div className="flex items-cneter gap-4 md:gap-6">
                            <Button
                                type="secondary"
                                icon={<VisibilityOutlined className="!w-5 translate-y-[1px]" />}
                                className="!py-2"
                                onClick={() => setPreviewBlogId(blogData.id)}
                            >
                                <span className="hidden md:inline">
                                    Preview
                                </span>
                            </Button>
                            <PublishArticleButton blogData={blogData} />
                        </div>
                    </div>
                )}
                className="flex-col md:flex-row"
            />

            <div className="w-full block md:hidden mb-6">
                <BlogEditorMobileSidebar
                    onSelect={handleSidebarSelect}
                    options={sidebarOptions}
                    selectedOption={selectedSidebarOption}
                />
            </div>

            <div className="w-full h-full flex gap-8">

                <BlogEditorDesktopSidebar
                    onSelect={handleSidebarSelect}
                    options={sidebarOptions}
                    selectedOption={selectedSidebarOption}
                />

                <div className="w-full relative">
                    <SkeletonLoading isDataLoading={loading.findingBlog || !Object.keys(blogData).length}>
                        {componentToRender}
                    </SkeletonLoading>
                </div>
            </div>

            <BlogViewModal
                blogId={previewBlogId}
                open={!!previewBlogId}
                onClose={() => setPreviewBlogId(false)}
            />
        </div>
    );
}