import { useEffect, useState } from "react";

import {
    Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography,
    useTheme
} from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

import datetimeProvider from "../../../providers/datetime.provider";

import solicitationService from "../../../domain/services/solicitation/solicitation.service";
import communicationProvider from "../../../providers/communication.provider";
import Tooltip from "../../shared/Tooltip";

export const CustomerRequestProtocoledModal = ({ open, handleClose, request = {}, setAlert, onRefresh }) => {

    const [protocoledAt, setProtocoledAt] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        if (!open)
            return;

        setProtocoledAt(request.protocoledAt || null);
    }, [open]);

    const handleSave = async () => {

        const mainId = request.mainId || request.id;

        setIsLoading(true);

        await solicitationService
            .editValue(mainId, { protocoledAt })
            .then(() => {
                onRefresh();
                handleClose();
            })
            .catch(err => communicationProvider.getErrorMessage(err, setAlert))
            .finally(() => setIsLoading(false));
    }

    return (
        <Dialog
            open={open} onClose={handleClose}
            maxWidth='xs' fullWidth
        >
            <DialogTitle >
                Data de protocolação <br />
                <Typography style={{ marginTop: 5 }}>
                    Adicione a data de protocolação do seu serviço.
                </Typography>
            </DialogTitle>

            <DialogContent style={{ marginBottom: 15 }}>
                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <DatePicker
                        name="initialDateFilter" fullWidth format="dd/MM/yyyy"
                        placeholder="Data de protocolação"
                        clearLabel="Remover" clearable
                        okLabel="Confirmar" cancelLabel="Cancelar"
                        value={protocoledAt} onChange={setProtocoledAt}
                    />
                </MuiPickersUtilsProvider>
            </DialogContent>

            <DialogActions>

                <Button onClick={handleClose} disabled={isLoading}>
                    Cancelar
                </Button>

                <Button
                    variant="contained" color="primary"
                    onClick={handleSave} disabled={isLoading}
                >
                    {!isLoading ? 'Salvar' : (
                        <>
                            Salvando
                            <CircularProgress size={14} style={{ marginLeft: 8 }} />
                        </>
                    )}
                </Button>

            </DialogActions>
        </Dialog>
    )
}

const CustomerRequestProtocoledButton = ({ protocoledAt, onClick, className }) => {
    
    const theme = useTheme();

    const getProtocoledValue = () => (
        <div className="flex items-center gap-1 text-sm">
            {datetimeProvider.formatDateTime(protocoledAt, 'DD/MM/YYYY')}
            <Edit className="!text-sm" />
        </div>
    )

    return (
        <Tooltip
            title={!protocoledAt
                ? "Registre a data do protocolo para controlar seus prazos e tarefas"
                : `Esse serviço foi protocolado em ${datetimeProvider.formatDateTime(protocoledAt, 'DD/MM/YYYY')}`
            }
        >
            {
                !protocoledAt ? (
                    <button
                        style={{
                            color: theme.palette.primary.main,
                            fontSize: 14, textAlign: 'center'
                        }}
                        className={className}
                        onClick={onClick}
                    >
                        Adicionar
                    </button>
                ) : (
                    <button
                        className="text-primary bg-transparent hover:bg-gray-100"
                        onClick={onClick}
                    >
                        {getProtocoledValue()}
                    </button>
                )
            }


        </Tooltip>
    )
}

export default CustomerRequestProtocoledButton;