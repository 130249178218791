import BlogAuthorAvatar from '../blogAuthorAvatar';

export default function BlogAuthorInfo({ author }) {
    return (
        <div className="flex items-start gap-4">
            <BlogAuthorAvatar
                name={author.name}
                photoUrl={author.photoUrl}
                size="lg"
            />

            <div>
                <h3 className="text-lg font-semibold text-[#131313] mb-1">
                    {author.name}
                </h3>
                <p className="text-[#13131399] text-sm leading-relaxed">
                    {author.bio || 'Especialista em direito digital com vasta experiência em proteção de dados e regulamentações tecnológicas. Dedicado a traduzir complexidades jurídicas em soluções práticas para empresas e profissionais.'}
                </p>
            </div>
        </div>
    );
} 