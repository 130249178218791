import { Cancel, CloseOutlined, KeyboardArrowDown } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import LoadingSpin from "../LoadingSpin";
import { Tooltip } from "@material-ui/core";

/**
 * Componente genérico de select
 * @property {array} options Recebe um array de objetos no formato [{ label, value, disabled? }]
 * @property {function} onChange Função que executa ao selecionar, passa como argumento o valor e index selecionado (value, index) 
 * @property {string} title (opcional) Título que é exibido acima do input
 * @property {string} placeholder (opcional) Placeholder do input
 * @property {bool} loading Estado de loading
 * @property {bool} allowClear Permite limpar o select após selecionar uma opção
 * @property {function} getRef Função para pegar referência do botão, assim é possível abrir ele programaticamente
 * @example
 * <CustomSelect
 *      title="Opções"
 *      options={[
 *          { label: 'Opção A', value: 634 },
 *          { label: 'Opção B', value: 1234 },
 *          { label: 'Opção C', value: 85, disabled: true },
 *      ]}
 *      onChange={(value, index) => console.log(value, index)}
 *      placeholder="Seleciona a opção"
 *      loading={loading}
 *      allowClear
 * />
 */
export default function CustomSelect({
    options = [],
    onChange = () => { },
    onBlur = () => { },
    title = null,
    placeholder = null,
    loading = false,
    allowClear = false,
    allowSearch = false,
    getRef = ref => { },
    value,
    multiSelect = false,
    onAddNewItem,
    emptyText = 'Nenhuma opção encontrada',
    className = '',
}) {

    const ref = useRef();
    const inputRef = useRef();

    const [open, setOpen] = useState(false);
    const [selectedIndexes, setSelectedIndexes] = useState(multiSelect ? [] : null);
    const [searchTerm, setSearchTerm] = useState('');

    const hasSelectedValue = multiSelect ? !!selectedIndexes.length : selectedIndexes !== null;

    const filteredOptions = !searchTerm ? options : options.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const hasExactSearchedLabel = () => !searchTerm || options.some(option => option.label.toLowerCase() === searchTerm.toLowerCase());

    const getIndexByValue = (value) => options.findIndex(option => option.value === value);

    const handleSelect = (event, selectedValue) => {
        event.stopPropagation();

        const originalIndex = getIndexByValue(selectedValue);

        setSearchTerm('');

        if (allowSearch)
            inputRef.current.focus();

        if (multiSelect) {

            let newSelectedIndexes = [...selectedIndexes, originalIndex];

            if (selectedIndexes.includes(originalIndex)) {
                newSelectedIndexes = selectedIndexes.filter(i => i !== originalIndex);
            }

            setSelectedIndexes(newSelectedIndexes);

            const selectedValues = newSelectedIndexes.map(i => options[i].value);

            onChange(selectedValues);

            return;
        }

        setSelectedIndexes(originalIndex);

        setTimeout(() => {
            setOpen(false);
            onBlur();
        }, 300);

        onChange(selectedValue, originalIndex);
    }

    const handleOutSideClick = (event) => {
        const isOutsideClick = ref.current && !ref.current.contains(event.target);
        if (isOutsideClick && open) {
            setOpen(false);
            onBlur();
        }
    }

    const handleSearch = searchValue => {
        setSearchTerm(searchValue);
        setOpen(true);
    }

    const handleAddNewItem = (e) => {
        e.stopPropagation();
        onAddNewItem(searchTerm);
    }

    const handleClose = () => {
        if (loading) return;

        setOpen(prev => !prev)

        if (!open) return;

        onBlur();
    }

    useEffect(() => {
        if (!multiSelect) {
            const optionIndex = options.findIndex(option => option.value === value);
            setSelectedIndexes(optionIndex === -1 ? null : optionIndex);
            return;
        }

        const foundIndexes = (value || []).reduce((indexes, value) => {
            const index = options.findIndex(option => option.value === value);
            if (index !== -1)
                return [...indexes, index];
            return indexes;
        }, []);

        setSelectedIndexes(foundIndexes);
    }, [value, options]);

    useEffect(() => {
        if (ref.current) {
            getRef(ref.current);
        }
    }, [ref]);

    useEffect(() => {
        if (!allowSearch || !open) return;
        inputRef.current.focus();
    }, [open]);

    useEffect(() => {
        document.addEventListener("mousedown", handleOutSideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutSideClick);
        };
    }, [ref, open]);

    const clearSelection = (e) => {
        e.stopPropagation();
        setSelectedIndexes(multiSelect ? [] : null);
        onChange(multiSelect ? [] : null);
    };

    const renderLabel = () => {
        if (!multiSelect) {
            const selectedOption = options[selectedIndexes];
            return (
                <div className="my-1 min-h-5 flex items-center gap-2">
                    {selectedOption?.icon && <span className="shrink-0">{selectedOption.icon}</span>}
                    {selectedOption?.label}
                </div>
            );
        }

        return selectedIndexes.map(index => (
            <div key={index} className="px-2 py-1 rounded bg-[#eee] flex gap-1 items-center cursor-pointer max-w-full">
                <Tooltip placement="top" enterDelay={1000} title={(<span className="text-sm">{options[index].label}</span>)}>
                    <div className="max-w-full overflow-hidden overflow-ellipsis flex items-center gap-2">
                        {options[index].icon && <span className="shrink-0">{options[index].icon}</span>}
                        {options[index].label}
                    </div>
                </Tooltip>
                <div
                    className="text-[#777] hover:text-[#131313]"
                    onClick={e => handleSelect(e, options[index].value)}
                >
                    <CloseOutlined className="!w-3 !h-3 shrink-0" />
                </div>
            </div>
        ));
    };

    const isSelected = (index, value) => {
        if (!multiSelect) {
            return index === selectedIndexes;
        }

        const optionOriginalIndex = getIndexByValue(value);
        return selectedIndexes.includes(optionOriginalIndex);
    }

    return (
        <div className="text-sm w-full">
            {title && <div className="font-semibold mb-2">{title}</div>}
            <button
                type="button"
                ref={ref}
                className={`
                    relative min-h-8 bg-[#fff] border-solid border border-[#ededed] hover:border-[#376fd0] rounded px-3 w-full transition-[border-color_.2s_ease]
                    ${allowSearch ? 'cursor-text' : 'cursor-pointer'}
                    ${className}
                `}
                style={{ borderColor: open ? '#376fd0' : undefined }}
                onClick={handleClose}
            >


                <div className="flex flex-wrap pr-4 relative items-center">

                    <div className="overflow-hidden text-ellipsis text-nowrap pr-2 flex gap-1 flex-wrap align-middle">
                        {hasSelectedValue && renderLabel()}

                        {!hasSelectedValue && !searchTerm && <div className="my-1 min-h-5 text-[#0006]">{placeholder}</div>}

                        {allowSearch && (
                            <input
                                ref={inputRef}
                                type="text"
                                className="max-w-full text-sm my-1 h-5 p-0 border-none focus:ring-0 bg-transparent outline-none"
                                style={{
                                    width: searchTerm ? searchTerm.length * 8 : 4,
                                }}
                                value={searchTerm}
                                onChange={e => handleSearch(e.target.value)}
                            />
                        )}
                    </div>

                    <div className="absolute right-0">
                        {loading ? (
                            <LoadingSpin />
                        ) : allowClear && hasSelectedValue ? (
                            <div onClick={e => clearSelection(e)}>
                                <Cancel className="text-[#0007] hover:text-[#000a] !w-4 !h-4" />
                            </div>
                        ) : (
                            <KeyboardArrowDown className="text-[#0005]" />
                        )}
                    </div>
                </div>

                <div
                    className="absolute left-0 top-[calc(100%+8px)] w-full bg-[#fff] shadow-[0_0_12px_#0004] p-1 rounded z-[9999] max-h-56 overflow-auto"
                    style={{
                        pointerEvents: open ? 'all' : 'none',
                        opacity: open ? 1 : 0,
                        transformOrigin: 'top',
                        transform: open ? 'scaleY(1)' : 'scaleY(0.85)',
                        transition: 'opacity .2s ease, transform .2s ease',
                    }}
                >

                    {filteredOptions.map((option, index) => (
                        <div
                            key={option.value}
                            className="bg-transparent hover:bg-[#0001] px-3 py-2 w-full text-left rounded cursor-pointer flex items-center gap-2"
                            style={{
                                cursor: option.disabled ? 'not-allowed' : undefined,
                                color: option.disabled ? '#0006' : undefined,
                                backgroundColor: option.disabled ? 'transparent' : isSelected(index, option.value) ? '#376fd022' : undefined,
                                transition: 'background-color .2s ease',
                            }}
                            onClick={e => !option.disabled && handleSelect(e, option.value)}
                        >
                            {option.icon && <span className="shrink-0">{option.icon}</span>}
                            {option.labelOptionRender || option.label}
                        </div>
                    ))}

                    {!filteredOptions.length && (!onAddNewItem || !searchTerm) && (
                        <div
                            className="bg-transparent px-3 py-2 w-full text-center text-gray-500"
                        >
                            {emptyText}
                        </div>
                    )}

                    {!!searchTerm && !hasExactSearchedLabel() && onAddNewItem && (
                        <div
                            className="text-[#131313] bg-transparent hover:bg-[#0001] px-3 py-2 w-full text-left font-semibold rounded cursor-pointer"
                            style={{
                                transition: 'background-color .2s ease',
                            }}
                            onClick={e => handleAddNewItem(e)}
                        >
                            Adicionar {searchTerm} +
                        </div>
                    )}
                </div>

            </button>
        </div>
    );
}