import { useMemo } from 'react';

const sizes = {
    sm: {
        container: 'w-6 h-6',
        text: 'text-xs'
    },
    md: {
        container: 'w-8 h-8',
        text: 'text-sm'
    },
    lg: {
        container: 'w-10 h-10',
        text: 'text-base'
    },
    xl: {
        container: 'w-32 h-32',
        text: 'text-4xl'
    }
};

export default function Avatar({ src, name, size = 'md', className = '' }) {
    const initials = useMemo(() => {
        if (!name) return '';
        return name
            .split(' ')
            .map(word => word[0])
            .join('')
            .toUpperCase()
            .slice(0, 2);
    }, [name]);

    if (src) {
        return (
            <img
                src={src}
                alt={name}
                className={`rounded-full object-cover ${sizes[size].container} ${className}`}
            />
        );
    }

    return (
        <div
            className={`
                rounded-full 
                bg-gray-500 
                text-white 
                flex 
                items-center 
                justify-center 
                font-medium
                ${sizes[size].container}
                ${sizes[size].text}
                ${className}
            `}
        >
            {initials}
        </div>
    );
} 