import Quill from 'quill';
const BlockEmbed = Quill.import('blots/block/embed');

class VideoBlot extends BlockEmbed {
    static create(url) {
        const node = super.create();

        const youtubeMatch = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([^&]+)/);
        const vimeoMatch = url.match(/(?:https?:\/\/)?(?:www\.)?vimeo.com\/([^?]+)/);

        if (youtubeMatch) {
            node.setAttribute('src', `https://www.youtube.com/embed/${youtubeMatch[1]}`);
        } else if (vimeoMatch) {
            node.setAttribute('src', `https://player.vimeo.com/video/${vimeoMatch[1]}`);
        } else {
            try {
                const videoUrl = new URL(url);
                node.setAttribute('src', videoUrl.href);
            } catch {
                node.setAttribute('src', url);
            }
        }

        node.setAttribute('frameborder', '0');
        node.setAttribute('allowfullscreen', true);
        node.setAttribute('width', '80%');
        node.setAttribute('height', '400');
        node.setAttribute('style', 'display: block; margin: 0 auto;');

        return node;
    }

    static value(node) {
        return node.getAttribute('src');
    }
}

VideoBlot.blotName = 'video';
VideoBlot.tagName = 'iframe';

export default VideoBlot; 