export default function BlogAuthorAvatar({ name, photoUrl, size = 'md' }) {
    const renderAbbreviation = (authorName) => {
        const splitName = (authorName || '').split(' ');

        if (!splitName[0])
            return 'FJ';

        const firstLetter = splitName[0][0].toUpperCase();
        const secondLetter = splitName[1] ? splitName[1][0].toUpperCase() : '';

        return `${firstLetter}${secondLetter}`;
    };

    const sizeClasses = {
        xs: 'w-6 h-6 text-[10px]',
        sm: 'w-8 h-8 text-sm',
        md: 'w-12 h-12 text-lg',
        lg: 'w-16 h-16 text-xl'
    };

    return photoUrl ? (
        <img
            src={photoUrl}
            alt={name}
            className={`${sizeClasses[size]} rounded-full object-cover`}
        />
    ) : (
        <div className={`shrink-0 ${sizeClasses[size]} bg-gray-500 rounded-full text-white font-medium flex items-center justify-center uppercase`}>
            {renderAbbreviation(name)}
        </div>
    );
} 