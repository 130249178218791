import React, { useEffect } from "react";
import { useFormContext } from "./Form";

export default function FormItem({
    name = '',
    label = '',
    noStyle = false,
    children,
    className,
    validators = [],
    required = false,
    normalizer,
    delayTypingValidation = false,
    validationTypingDelay,
    mask,
}) {

    const form = useFormContext();
    const { registerField, registerFieldValue, formValues = {}, invalidFields = {} } = form;

    const value = formValues[name] ?? null;

    useEffect(() => {
        if (!name || !form) return;
        registerFieldValue(name, value);

        const props = {
            validators,
            required,
            normalizer,
            name,
            delayTypingValidation,
            validationTypingDelay,
            mask,
        };

        registerField(name, props);
    }, []);

    const handleChange = (newValue) => {
        registerFieldValue(name, newValue);
    };

    return (
        <div className={`flex flex-col ${className}`}>
            {!noStyle && (
                <div className="text-sm mb-2 font-semibold">
                    {label}
                </div>
            )}
            {(form && name) ? React.cloneElement(children, { value, onChange: handleChange, error: invalidFields[name] }) : children}
        </div>
    );
};
