import { useEffect, useState } from "react";
import datetimeProvider from "../../providers/datetime.provider";

/**
 * @property {number} duration Duração do timer em segundos
 */
export default function CountdownTimer({ duration }) {

    const [timeLeft, setTimeLeft] = useState(duration);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formattedTime = datetimeProvider.momentInstance().utc(timeLeft * 1000).format('HH:mm:ss');

    return (
        <>{formattedTime}</>
    );
}