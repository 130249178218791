
export const storages = {
    general: {
        name: '@facilita-app/general'
    }
};

export const getStorage = (storageName) => {
    return JSON.parse(localStorage.getItem(storageName))
}

export const refreshStorage = (storageName, data) => {

    if (typeof data === 'function') {
        const previousValue = getStorage(storageName);
        const newUserData = data(previousValue);
        localStorage.setItem(storageName, JSON.stringify(newUserData))
        return;
    }

    localStorage.setItem(storageName, JSON.stringify(data));
}

const LocalStorageProvider = {
    storages,
    refreshStorage,
    getStorage,
}

export default LocalStorageProvider;