import { Checkbox, FormControlLabel, IconButton } from "@material-ui/core";
import SimpleModal from "../../shared/SimpleModal";
import { Close } from "@material-ui/icons";
import { useState } from "react";
import GoogleLogoImg from '../../../assets/img/googleLogo.png';
import { Link } from "react-router-dom";
import announcementService from "../../../domain/services/marketing/announcement.service";
import { announcements } from "../../../domain/constant/announcements.constant";

export default function GoogleReviewModal({ open, onClose }) {

    const reviewUrl = 'https://www.google.com/search?kgmid=/g/11t1fdqx5g&hl=en-BR&q=Facilita+Jur%C3%ADdico&kgs=db88bb28a9514599&shndl=30&source=sh/x/loc/osrp/m5/1#lrd=0x4fefa2af4be2d6db:0x8a264d173187b806,3,,,,';

    const [dontShowAgain, setDontShowAgain] = useState(false);

    const markAsViewed = (redirectedToGoogleReview = false) => {

        const additionalInfo = {
            stopRecurrence: dontShowAgain ? true : undefined,
            redirectedToGoogleReview,
        };

        announcementService.markViewed(announcements.writeGoogleReview, additionalInfo)
            .then(onClose)
    }

    const handleClose = () => {
        markAsViewed();
    }

    const handleSubmit = () => {
        markAsViewed(true);
    }

    return (
        <SimpleModal
            maxWidth="sm"
            open={open}
            onClose={handleClose}
            bodyStyle={{ padding: 0 }}
        >
            <div className="absolute right-4 top-4">
                <IconButton
                    aria-label="close"
                    size='small'
                    onClick={handleClose}
                >
                    <Close className="!text-[#aaa]" />
                </IconButton>
            </div>
            <div className="p-8">
                <div className="flex justify-center">
                    <img src={GoogleLogoImg} alt="Google Logo" className="h-16" />
                </div>
                <div className="mt-6 text-base text-center">
                    <div className="text-lg font-semibold">
                        Obrigado pela sua avaliação!
                    </div>
                    <div className="mt-4">
                        Que tal compartilhar a sua experiência e ajudar a espalhar o poder da nossa tecnologia? Clique no botão abaixo e deixe sua avaliação no Google também!
                    </div>
                </div>
            </div>
            <div
                className="w-full px-8 py-4 flex justify-between text-base"
                style={{ borderTop: 'solid 1px #ededed' }}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={dontShowAgain}
                            onChange={e => setDontShowAgain(e.target.checked)}
                            color="primary"
                        />
                    }
                    label={<span className="text-base text-[#444]">Não mostrar novamente</span>}
                />
                <div className="flex gap-2">
                    <button
                        className="px-3 py-2 bg-transparent text-[#999] hover:bg-[#00000007] rounded text-sm"
                        onClick={handleClose}
                    >
                        Agora Não
                    </button>
                    <Link to={{ pathname: reviewUrl }} target="_blank">
                        <button
                            className="px-3 py-2 font-semibold bg-[#376fd0] text-white rounded hover:brightness-105"
                            onClick={handleSubmit}
                        >
                            Avaliar
                        </button>
                    </Link>
                </div>
            </div>
        </SimpleModal>
    )
}