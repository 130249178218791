import { events } from "../domain/services/api";

const uploadFile = (file, signedRequest, item) => new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedRequest);

    xhr.upload.onprogress = (e) =>
        setProgress({ total: e.total, loaded: e.loaded, filename: item.document.name, type: "Upload" });

    xhr.onloadend = () => checkFileError(item, item.document.url, resolve);

    xhr.send(file);
});

const checkFileError = (file, url, resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
            resolve({ ...file, success: xhr.status === 200 });
        }
    };
    xhr.send();
};

const setProgress = (progressData) => {
    if (!progressData.loaded) return;

    let progress = Math.round((progressData.loaded / progressData.total) * 100);
    events.emmit('onProgressDeterminate', {
        info: `Fazendo ${progressData.type} do arquivo: ${progressData.filename}`,
        progress,
        show: true
    });
};

const upload = async (file, data) => {
    events.emmit('onProgressDeterminate', { info: 'Verificando o arquivo', progress: 0, show: true });

    if (!data || !data.document) return null;

    const response = await uploadFile(file, data.signedRequest, data);

    events.emmit('onProgressDeterminate', { info: 'Upload concluído com sucesso', progress: 100, show: false });

    return response;
};

const getDocumentObject = (item) => {
    return {
        name: item.name,
        fileSize: item.size,
        fileType: item.type,
    };
};

const documentProvider = {
    upload,
    getDocumentObject
}

export default documentProvider;