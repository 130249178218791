import { Chip, Tooltip } from '@material-ui/core';
import { PersonAdd } from '@material-ui/icons';

const ResponsibleButton = ({ title, label, color, onClick, className = '' }) => {
  return (
    <Tooltip title={title}>
      <Chip
        size="small"
        label={label}
        variant="outlined"
        avatar={<PersonAdd style={{ width: '16px', height: '16px', color }} />}
        style={{ fontWeight: '600', color, border: `1px solid ${color}`, marginRight: '5px' }}
        className={className}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default ResponsibleButton;
