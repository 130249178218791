import { Error } from "@material-ui/icons";
import loginService from "../../domain/services/login.service";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { voucherTrackerUserId } from "../../domain/constant/customer.repurchase.voucher.constant";
import { obtainCustomerRepurchaseVoucher } from "../../domain/services/marketing/voucher.service";
import Button from "../shared/Button";
import LoadingSpin from "../LoadingSpin";
import utilsProvider from "../providers/utils.provider";

export default function FinancialPendingAfterRegistration({
    request,
}) {

    const history = useHistory();

    const [voucher, setVoucher] = useState(null);

    const user = loginService.getUserAuthentication();
    const customerId = user?.customer?.id || request?.customerId;

    const texts = {
        absolute: {
            discountType: 'Utilize o seu saldo na compra do próximo pacote e ganhe mais 5% de desconto!',
        },
        percentage: {
            discountType: 'Turbine ainda mais sua economia com um desconto de 5%!',
        },
    };

    const handleSubmit = () => {
        history.push(`/marketplace?voucher=${voucher.code}&sellerUserId=${voucherTrackerUserId}`);
    }

    const handleClose = () => {
        history.push('/solicitations#pending');
    }

    const getRepurchaseVoucher = () => {
        if (request?.voucher) {
            setVoucher(request.voucher);
            return;
        }

        obtainCustomerRepurchaseVoucher(customerId, { ignoreMinimumCreditAmount: true })
            .then(({ data }) => {
                const repurchaseVoucher = data;

                if (!repurchaseVoucher || !repurchaseVoucher?.code) {
                    handleClose();
                    return;
                }

                setVoucher(repurchaseVoucher);
            })
            .catch(() => handleClose())
    }

    useEffect(() => {
        getRepurchaseVoucher();
    }, []);
    
    const text = texts[voucher?.discountType || 'absolute'];

    if (!voucher) return (
        <div className="p-12 flex flex-col items-center gap-2 text-base text-[#376fd0] font-semibold">
            <LoadingSpin size="large"/>
            Carregando...
        </div>
    );

    return (
        <div className="w-full text-center font-['SF_Pro'] text-slate-900 text-base flex flex-col justify-center">

            <div className="relative overflow-hidden flex items-center gap-4 p-4 rounded-lg text-start">
                <div className="absolute left-0 top-0 w-full h-full bg-primary opacity-15" />

                <Error className="text-primary !text-5xl shrink-0" />

                <div className="">
                    <div className="text-lg font-semibold">
                        Solicitação Pendente
                    </div>
                    <div className="mt-1">
                        Sua solicitação #{request.id} está aqui, só faltam créditos para avançarmos
                    </div>
                </div>
            </div>

            <div className="relative overflow-hidden rounded-lg px-3 py-1 mt-6 mx-auto">
                <div className="absolute left-0 top-0 w-full h-full bg-primary opacity-15" />
                <div className="text-primaryDark font-medium">
                Economize <b>R$ {utilsProvider.formatNumberCurrencyWithoutSymbol(voucher?.discount || 0)}</b> na renovação
                </div>
            </div>

            <div className="mt-6 text-slate-700">
                Ei, notamos que seu saldo acabou e não conseguiremos dar andamento na sua solicitação sem a renovação. Vamos resolver isso rapidinho?
            </div>

            <Button
                type="primary"
                className="mt-6 w-full h-12 !rounded-lg"
                onClick={handleSubmit}
            >
                Renovar Agora
            </Button>

            <div className="mt-3 text-xs text-slate-500">
                Renove agora com 5% de desconto e sua solicitação já entra em processamento! Sem perda de tempo. ⏰
            </div>
        </div>
    )
}