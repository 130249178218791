import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from 'query-string';

import RequestCustomerRatingDialog from "./request.customer.rating.dialog.component";
import RequestFormHighlightButton from "../request.form.highlight.button";
import RequestCustomerTipDialog from "./request.customer.tip.dialog.component";

import customerRatingService from "../../../domain/services/solicitation/customer.rating.service";
import memberExternalTipService from "../../../domain/services/member/member.external.tip.service";
import ReferralProgramVideoModal from "../../marketing/referral.program.video.modal.component";
import GoogleReviewModal from "./GoogleReviewModal";
import announcementService from "../../../domain/services/marketing/announcement.service";
import { announcements } from "../../../domain/constant/announcements.constant";


const CustomerRatingButton = ({ mainRequestId, request, setAlert, onFinish = () => {}}) => {

    const googleReviewMinimumScore = 4;

    const [showRequestRatingButton, setShowRequestRatingButton] = useState(false);
    const [isRequestCustomerRatingOpen, setIsRequestCustomerRatingOpen] = useState(false);
    const [openTipDialog, setOpenTipDialog] = useState(false);
    const [openGoogleReviewModal, setOpenGoogleReviewModal] = useState(false);
    const [ratingInfo, setRatingInfo] = useState(null);
    

    const [openVideo, setOpenVideo] = useState(false);

    let { search } = useLocation();

    useEffect(() => { checkRequestCustomerRating(request.id) }, []);

    const checkRequestCustomerRating = async (requestId) =>
        customerRatingService
            .checkIfRatingIsPending(mainRequestId || requestId)
            .then(checkShowButton);

    const checkShowButton = ({ data }) => {

        if (!data.hasPendingRatings)
            return null;

        setShowRequestRatingButton(true);
        checkRatingUrl(search);
    }

    const checkRatingUrl = (search) => {

        const { customerRating } = queryString.parse(search)

        if (!customerRating)
            return;

        setIsRequestCustomerRatingOpen(true);
    }

    const checkToShowTip = async (rating, score) => {
        const minRatingScore = 3;

        const { success } = await memberExternalTipService.checkToGiveTip(score, rating, () => setOpenTipDialog(true));
        if (success)
            return

        if (score > minRatingScore)
            handlePreviewVideo();
    }

    const checkOpenGoogleReview = (rating, score) => {
        const alreadyViewed = announcementService.isShowed(announcements.writeGoogleReview);
        
        if (alreadyViewed) {
            checkToShowTip(rating, score)
            return;
        }

        setOpenGoogleReviewModal(true);
    }

    const handleFinishRating = async (rating) => {
        setIsRequestCustomerRatingOpen(false);
        setShowRequestRatingButton(false);
        setRatingInfo(rating);

        const ratingScore = parseFloat(rating.value);

        if (ratingScore >= googleReviewMinimumScore) {
            checkOpenGoogleReview(rating, ratingScore);
            return;
        }

        onFinish(rating)

        checkToShowTip(rating, ratingScore);
    }

    const handleFinishTip = () => {
        handlePreviewVideo();
    }

    const handleFinishGoogleReview = () => {
        setOpenGoogleReviewModal(false);
        checkToShowTip(ratingInfo, parseFloat(ratingInfo?.value || 0))
    }

    const handlePreviewVideo = () => {

        setOpenVideo((openVideo) => !openVideo);

        if (openVideo)
            setShowRequestRatingButton(false);
    }

    return (
        <>
            {showRequestRatingButton && (
                <RequestFormHighlightButton
                    text='AVALIAR AGORA'
                    onClick={() => setIsRequestCustomerRatingOpen(true)}
                    label='Sua opinião é importante! Clique no botão para avaliar →'
                />
            )} 

            <RequestCustomerRatingDialog
                request={request} setAlert={setAlert}
                isOpen={isRequestCustomerRatingOpen}
                onFinish={handleFinishRating}
                onClose={() => setIsRequestCustomerRatingOpen(false)}
            />

            <RequestCustomerTipDialog
                request={request}
                isOpen={openTipDialog}
                onFinish={handleFinishTip}
                onClose={() => setOpenTipDialog(false)}
                setAlert={setAlert}
            />

            <GoogleReviewModal
                open={openGoogleReviewModal}
                onClose={handleFinishGoogleReview}
            />

            {openVideo && <ReferralProgramVideoModal onClose={handlePreviewVideo} setAlert={setAlert} />}
        </>
    )
}

export default CustomerRatingButton;