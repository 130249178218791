import React, { useState } from 'react';


import requestStatusService from '../../domain/services/solicitation/request.status.service';

import RequestAllServicesDueDate from './request.all.services.due.date.component';
import RequestDetailsActionButtons from './request.details.action.buttons.component';
import CustomerRequestProtocoledButton, { CustomerRequestProtocoledModal } from './customer/customer.request.protocoled.button';
import RequestServiceInformationHearingDateInfo from './request.service.information.hearingDate.info';
import { useTour } from '../../context/tourContext';
import { tours } from '../../domain/constant/tour.constant';
import { CheckCircle } from "react-feather";
import CustomerRevokeButton from "./customer.revoke.button.component";
import UnrevokeButton from "./unrevoke.button.component";
import stepsSolicitation from "../../domain/constant/steps.solicitation.constant";
import loginService from "../../domain/services/login.service";
import PendingSolveButton from "./pending/pending.solve.button.component";
import CustomerRatingButton from "./rating/customer.rating.button.component";
import announcementService from "../../domain/services/marketing/announcement.service";
import { customerCorrectionMinimumScore } from "../../domain/constant/request.rating.constant";
import { announcements } from "../../domain/constant/announcements.constant";

export default function CustomerRequestServiceInformation({
    mainRequestId,
    setAlert,
    setValue,
    request,
    refresh,
    isSmall,
    setAllowNext,
    tutorial,
    find,
    setShowTutorial,
}) {

    const { hearingDateTime } = request;

    const { registerRef } = useTour();

    const tourRefs = {
        statusRef: registerRef(tours.requestDetailsTour, 'status'),
        dueDateRef: registerRef(tours.requestDetailsTour, 'dueDate'),
    };

    const [protocoledModal, setProtocoledModal] = useState({ open: false, request: {} });

    const fieldProps = {
        titleSize: 12, titleColor: '#87b1ff', fontSize: 14, cleanTitle: true, spacing: 8
    };

    const handleFinishRating = (rating) => {
        const alreadyViewed = announcementService.isShowed(announcements.customerCorrection);
        const isLowScore = rating.value <= customerCorrectionMinimumScore;

        if (alreadyViewed || !isLowScore)
            return;

        setShowTutorial({ open: true, requestId: rating.requestId })
    }

    const getCustomerStatusFields = () => {

        const stepRequest = requestStatusService.getStepByKeyAndUserSource(request.status);

        return (
            <div
                ref={tourRefs.statusRef}
                className="flex items-center gap-2"
                style={{ color: stepRequest.background }}
            >
                <CheckCircle className="!w-5 !h-5" />
                <div className="font-medium text-base">
                    {stepRequest.value}
                </div>
            </div>
        )
    }

    const getReferenceNotion = () => {

        return (
            <div className="text-gray-500 text-base flex gap-2 items-center">
                <span>
                    Solicitação criada a partir do Parecer de ID:
                </span>

                <a
                    href={`/solicitations/${request.referenceNotionRequestId}`}
                    className="text-sm text-primary bg-transparent hover:underline px-1"
                >
                    {request.referenceNotionRequestId}
                </a>
            </div>
        )
    }

    const renderHearingDate = (dateTime, isCheckin) => (
        <RequestServiceInformationHearingDateInfo dateTimeInfo={dateTime} isCheckin={isCheckin} />
    )

    const getCustomerRevokeButton = () => {

        if (!loginService.isCustomer())
            return null;

        return (
            <CustomerRevokeButton mainId={mainRequestId}
                onFinish={refresh} setAlert={setAlert} />
        )
    }

    const getUnrevokeButton = () => {

        const showButton = (
            request.status === stepsSolicitation.REVOKED
            && (loginService.isCustomer() || loginService.isInternal())
        )

        if (!showButton)
            return null;

        return (
            <UnrevokeButton request={request}
                refresh={refresh} setAlert={setAlert} />
        )
    }

    const renderSpecificServiceInfo = () => {
        if (!request.referenceNotionRequestId && !request.checkIn && !hearingDateTime)
            return <></>;

        return (
            <>
                <div className="w-full my-2 h-[1px] bg-gray-200" />

                {!!request.referenceNotionRequestId && getReferenceNotion()}

                {!!request.checkIn && renderHearingDate(request.checkIn, true)}

                {!!hearingDateTime && renderHearingDate(hearingDateTime)}
            </>
        );
    }

    const renderPendingSolveButton = () => {

        if (request.status !== stepsSolicitation.PENDING || !loginService.isCustomer())
            return null;

        return (
            <div className="mt-6">
                <PendingSolveButton
                    request={request} variant='card'
                    onFinish={find} setAlert={setAlert}
                />
            </div>
        )
    }

    const renderRatingButton = () => {

        if (request.status != stepsSolicitation.CONCLUDED || !loginService.isCustomer())
            return null;

        return (
            <div className="mb-6">
                <CustomerRatingButton
                    mainRequestId={mainRequestId}
                    request={request}
                    setAlert={setAlert}
                    onFinish={handleFinishRating}
                />
            </div>
        )
    }

    return (
        <React.Fragment>

            <div>

                {renderRatingButton()}


                <div className="flex flex-col gap-3 text-base">
                    <div className="flex items-center gap-2 mb-1">
                        <div className="flex flex-wrap items-center gap-2">
                            {getCustomerStatusFields()}

                            {!!request.isInCorrection && (
                                <div className="bg-orange-400 text-white px-[6px] py-[2px] rounded-md text-xs">
                                    Está em correção
                                </div>
                            )}
                        </div>

                        <div className="ml-auto">
                            {getCustomerRevokeButton()}
                            {getUnrevokeButton()}
                        </div>


                    </div>

                    <div ref={tourRefs.dueDateRef}>
                        <RequestAllServicesDueDate
                            mainRequestId={mainRequestId} request={request}
                            setAlert={setAlert} fieldProps={fieldProps}
                            refresh={refresh} setValue={setValue}
                        />
                    </div>

                    {request.status === stepsSolicitation.CONCLUDED && (
                        <div className="text-gray-500 text-base flex gap-2">
                            <span>
                                Serviço protocolado em:
                            </span>

                            <CustomerRequestProtocoledButton
                                protocoledAt={request.protocoledAt}
                                onClick={() => setProtocoledModal({ open: true, request })}
                            />
                        </div>
                    )}

                    {renderSpecificServiceInfo()}

                </div>

            </div>


            <RequestDetailsActionButtons
                mainRequestId={mainRequestId}
                isSmall={isSmall}
                request={request}
                refresh={refresh}
                setValue={setValue}
                setAlert={setAlert}
                setAllowNext={setAllowNext}
                tutorial={tutorial}
            />

            {renderPendingSolveButton()}


            <CustomerRequestProtocoledModal
                open={protocoledModal.open}
                handleClose={() => setProtocoledModal(false)}
                request={protocoledModal.request}
                setAlert={setAlert}
                onRefresh={refresh}
            />

        </React.Fragment >
    );
}