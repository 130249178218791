import { useEffect, useState } from "react";
import BlogsService from "../../../domain/services/marketing/blog.service";
import useRequest from "../../../hooks/useRequest";
import SimpleModal from "../../shared/SimpleModal";
import { Alert } from "@material-ui/lab";
import QuillViewer from "../../shared/quill/QuillViewer";
import datetimeProvider from "../../../providers/datetime.provider";
import { CalendarToday, Schedule } from "@material-ui/icons";
import BlogBanner from "./footer/blogBanner";
import LoadingSpin from "../../LoadingSpin";
import BlogHeaderCta from "./header/blogHeaderCta";
import TableOfContentsDesktop from "./blogEditor/tableOfContents/tableOfContentsDesktop";
import TableOfContentsMobile from "./blogEditor/tableOfContents/tableOfContentsMobile";
import BlogAuthorInfo from "./footer/blogAuthorInfo";
import BlogAuthorAvatar from "./blogAuthorAvatar";
import BlogVerticalBanner from "./header/blogVerticalBanner";

export default function BlogViewModal({
    blogId,
    open = false,
    onClose,
}) {

    const [findBlog, findingBlog, err] = useRequest(BlogsService.findById);

    const [article, setArticle] = useState(null);
    const [toc, setToc] = useState([]);


    useEffect(() => {
        if (!blogId) return;

        findBlog(blogId)
            .then(blogArticle => {
                setArticle(blogArticle);
                generateTableOfContents(blogArticle.content);
            })
            .catch(() => { })
    }, [blogId]);

    const generateTableOfContents = (htmlContent = '') => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');

        const headings = doc.querySelectorAll('h1, h2, h3');
        const tocList = [];

        headings.forEach((heading) => {
            const headingText = heading.innerText;
            const headingTag = heading.tagName.toLowerCase();

            const id = heading.id;

            tocList.push({
                text: headingText,
                tag: headingTag,
                id,
            });
        });

        setToc(tocList || []);
    };

    const renderAuthorInfo = () => {

        const description = article.profileInfo?.description;
        if (!description)
            return null;

        return (
            <div className="mt-20">
                <BlogAuthorInfo
                    author={{
                        name: article.authorName,
                        photoUrl: article.profileInfo?.picture?.url,
                        bio: description
                    }}
                />
            </div>
        );
    };

    function calculateReadingTime(htmlString) {
        const formattedHtmlString = htmlString.replace(/<br\s*\/?>/gi, ' ');

        const plainText = formattedHtmlString.replace(/<[^>]*>/g, '');

        const words = plainText.trim().split(/\s+/);
        const wordCount = words.length;

        const wordsPerMinute = 200;
        const readingTime = Math.ceil(wordCount / wordsPerMinute);

        return readingTime;
    }

    const renderMainArticle = () => (
        <article>

            {!!(article.tags || []).length && (
                <div className="mb-8 flex flex-wrap gap-2">
                    {article.tags.map(tag => (
                        <div key={tag} className="bg-[#13131311] text-[#131313cc] px-3 py-1 rounded-full text-sm">
                            {`${tag[0].toUpperCase()}${tag.slice(1)}`}
                        </div>
                    ))}
                </div>
            )}

            <div className="text-3xl lg:text-4xl font-bold">
                {article.title}
            </div>

            <div className="mt-2 flex flex-col md:flex-row gap-2 text-gray-400">
                <div className="flex gap-1 items-center">
                    <div className="w-5 h-5">
                        <CalendarToday className="!text-base -translate-y-[1px]" />
                    </div>
                    {datetimeProvider.formatDateTime(article.published || undefined, 'DD/MM/YYYY')}
                </div>
                <div className="hidden md:block">|</div>
                <div className="flex gap-1 items-center">
                    <div className="w-5 h-5">
                        <Schedule className="!text-base -translate-y-[0px]" />
                    </div>
                    {calculateReadingTime(article.content || '')} min
                </div>
            </div>

            <div className="mt-8 flex items-center gap-3">
                <BlogAuthorAvatar
                    name={article.authorName}
                    photoUrl={article.profileInfo?.picture?.url}
                    size="sm"
                />
                <div className="text-base">
                    {article.authorName}
                </div>
            </div>

            {article.headerCta && (
                <div className="mt-8">
                    <BlogHeaderCta
                        title={article.headerCta.title}
                        buttonLabel={article.headerCta.buttonLabel}
                        buttonUrl={article.headerCta.buttonUrl}
                    />
                </div>
            )}

            {!!article.coverImage?.url && (
                <div className="mt-8 w-full">
                    <img className="w-full h-auto" src={article.coverImage.url} alt="Imagem de Capa" />
                </div>
            )}

            <div className="mt-8 md:hidden">
                <TableOfContentsMobile tableOfContents={toc} />
            </div>

            <div className="mt-8">
                <QuillViewer content={article.content} />
            </div>

            {article?.ebook && (
                <div className="mt-16">
                    <BlogBanner
                        title={article.ebook.title}
                        buttonLabel={article.ebook.buttonLabel}
                        buttonUrl={article.ebook.buttonUrl}
                    />
                </div>
            )}

            {renderAuthorInfo()}
        </article>
    )


    return (
        <SimpleModal
            title="Preview do artigo"
            maxWidth="lg"
            open={open}
            onClose={onClose}
            style={{ overflow: 'hidden', height: '100%' }}
            bodyStyle={{ padding: 0, background: '#fafafa', height: '100%' }}
        >
            {findingBlog && (
                <div className="h-full flex items-center justify-center">
                    <LoadingSpin size={48} />
                </div>
            )}

            {err && (
                <Alert
                    severity="error"
                    variant="outlined"
                    className="mb-3 !bg-[#fff2f0]"
                >
                    Ocorreu um problema ao buscar seu artigo, por favor feche e tente novamente.
                </Alert>
            )}
            {!!article && !findingBlog && (

                <div className="w-full h-auto flex justify-center lg:justify-start gap-6">

                    <div className="hidden lg:block w-full max-w-[240px]">
                        <TableOfContentsDesktop tableOfContent={toc} />
                    </div>

                    <div className="w-full flex justify-center">
                        <div className="w-full flex flex-col gap-8 max-w-3xl text-[#131313] mt-8 px-8 pb-16">
                            {renderMainArticle()}
                        </div>
                    </div>

                    <div className="hidden xl:block w-full max-w-[220px]">
                        <div className="sticky mt-8 top-10 space-y-5 pb-16 pr-8">

                            {article.verticalBanner?.url && (
                                <BlogVerticalBanner
                                    imageUrl={article.verticalBanner?.url}
                                    redirectUrl={article.verticalBanner?.redirectUrl}
                                />
                            )}

                        </div>
                    </div>
                </div>
            )}
        </SimpleModal>
    )
}