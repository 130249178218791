import moment from 'moment-timezone';

import { Grid } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';

import FormField from '../../FormField';

import datetimeProvider from '../../../providers/datetime.provider';

export default function DateTimePickerField({
    name, label, value, extraInfo, setFieldValue,
    handleInUtc = true, fieldProps, onError, disabled = false,
    inputVariant = 'outlined',
    showIcon = true,
    defaultTimezone = 'America/Sao_Paulo',
    titleStyle = {
        titleSize: 15,
        titleWeight: 600,
        spacing: 10,
    }
}) {

    const datetime = value
        ? (
            handleInUtc ?
                datetimeProvider.formatDateTimeInTimezone(value, defaultTimezone)
                : moment(value).toISOString()
        )
        : null;

    const onChange = (name, newValue) => {

        if (!newValue)
            return;

        const datetimeInBrasilia = moment(newValue).tz(defaultTimezone, true);

        const datetime = handleInUtc
            ? datetimeInBrasilia.utc().format()
            : datetimeInBrasilia.format();

        setFieldValue(name, datetime);
    }

    return (
        <Grid item xs={12}>
            <FormField
                {...fieldProps}
                type='datetime'
                name={name}
                field={label}
                currentValue={datetime}
                helperText={extraInfo}
                onChange={onChange}
                required
                onError={onError}
                disabled={disabled}
                placeholder="Selecione a data"
                ampm={false}
                inputVariant={inputVariant}
                InputProps={{
                    endAdornment: showIcon ? <CalendarToday style={{ color: '#0007' }} /> : undefined,
                }}
                {...titleStyle}
            />
        </Grid>
    );
}
