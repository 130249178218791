

import Async from "../../components/Async";


const VoucherEditView = Async(() => import("../../pages/marketing/voucher.edit"));
const VoucherCreateView = Async(() => import("../../pages/marketing/voucher.create"));

const routes = {
    voucherCreate: {
        id: "voucherCreate",
        name: "voucherCreate",
        path: "/marketing/vouchers/create",
        component: VoucherCreateView,
        roles: ["admin", "financial", "marketing"],
    },
    voucherEdit: {
        id: "voucherEdit",
        name: "voucherEdit",
        path: "/marketing/vouchers/:voucherId",
        component: VoucherEditView,
        roles: ["admin", "financial", "marketing"],
    },
}

const pages = Object.values(routes);

export default {
    routes,
    pages
}