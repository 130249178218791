import { useState, useRef } from "react";

import { Dialog } from "@material-ui/core";
import { Close, CloudUploadOutlined } from "@material-ui/icons";

import Avatar from "../../../shared/Avatar";

import useRequest from "../../../../hooks/useRequest";
import SnackbarAlert from "../../../shared/SnackbarAlert";

import userService from "../../../../domain/services/user.service";

export default function EditAuthorModal({ open, onClose, user, onSave }) {

    const [updateUser, updating] = useRequest(userService.saveProfileInfo);

    const [tempProfileImage, setTempProfileImage] = useState(null);
    const [description, setDescription] = useState(user?.profileInfo?.description || "");

    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setTempProfileImage({
                file,
                previewUrl: imageUrl
            });
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();

        const newProfileInfo = {
            picture: tempProfileImage?.file || user?.profileInfo?.picture,
            description
        };

        try {
            const savedProfileInfo = await updateUser(user.userId, newProfileInfo);

            SnackbarAlert.success('Perfil salvo com sucesso!');
            onSave({ ...user, profileInfo: savedProfileInfo });

            if (tempProfileImage?.previewUrl)
                URL.revokeObjectURL(tempProfileImage.previewUrl);

        } catch (error) {
            SnackbarAlert.error(error.message || "Erro ao atualizar autor");
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-xl font-semibold">Editar informações do autor</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <Close />
                    </button>
                </div>

                <form onSubmit={handleSave}>
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium mb-2">
                                Nome do autor
                            </label>
                            <div className="text-lg font-medium text-gray-800">
                                {user?.name}
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-2">
                                Foto de perfil
                            </label>
                            <div className="flex items-center gap-6">
                                <div className="shrink-0">
                                    <Avatar
                                        src={tempProfileImage?.previewUrl || user?.profileInfo?.picture?.url}
                                        name={user?.name}
                                        size="xl"
                                        className="!w-32 !h-32"
                                    />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                        accept="image/*"
                                        className="hidden"
                                    />
                                    <button
                                        type="button"
                                        onClick={() => fileInputRef.current?.click()}
                                        className="flex items-center gap-2 px-4 py-2 bg-[#376fd0] text-white rounded hover:bg-[#2855a3] transition-colors"
                                    >
                                        <CloudUploadOutlined />
                                        {tempProfileImage || user?.profileInfo?.picture ? 'Trocar foto' : 'Selecionar foto'}
                                    </button>
                                    <span className="text-sm text-gray-500">
                                        Tamanho recomendado: 256x256px
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-2">
                                Descrição do autor
                            </label>
                            <textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Insira uma breve descrição sobre o autor"
                                className="w-full p-2 border rounded min-h-[100px]"
                            />
                        </div>
                    </div>

                    <div className="flex justify-end gap-3 mt-6">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 text-gray-600 hover:text-gray-800"
                        >
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            disabled={updating}
                            className="px-4 py-2 bg-[#376fd0] text-white rounded hover:bg-[#2855a3] disabled:opacity-50"
                        >
                            {updating ? "Salvando..." : "Salvar"}
                        </button>
                    </div>
                </form>
            </div>
        </Dialog>
    );
}