import { makeStyles, Tooltip as MUITooltip } from "@material-ui/core"

const useStyles = makeStyles(() => ({
    customTooltip: {
        backgroundColor: "#000c",
        color: "white",
        fontSize: "12px",
    },
    customArrow: {
        color: '#000c'
    },
}));

export default function Tooltip({
    title = '',
    placement = 'top',
    className,
    children,
    ...otherProps
}) {

    const classes = useStyles();

    return (
        <MUITooltip
            title={(
                <span className={`block p-1 text-center ${className}`}>
                    {title}
                </span>
            )}
            arrow
            classes={{
                tooltip: classes.customTooltip,
                arrow: classes.customArrow,
            }}
            placement={placement}
            {...otherProps}
        >
            {children}
        </MUITooltip>
    )
}