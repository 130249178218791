const rolesKeys = {
    SELLER: 'seller',
    FACILITER: 'faciliter',
    CUSTOMER: 'customer',
    LEAD: 'lead',
}

const rolesLabels = {
    [rolesKeys.SELLER]: 'Vendedor',
    [rolesKeys.FACILITER]: 'Faciliter',
    [rolesKeys.CUSTOMER]: 'Cliente',
    [rolesKeys.LEAD]: 'Lead',
}

module.exports = {
    rolesKeys,
    rolesLabels
}