import { Edit, PlusCircle } from "react-feather";
import Button from "../../shared/Button";
import { useState } from "react";

export default function CorrectionOptions({
    onSelect,
    onCancel,
    loading = false,
}) {

    const [correctionOption, setCorrectionOption] = useState(null);

    return (
        <div class="bg-white rounded-lg p-6 max-w-2xl mx-auto text-base">

            <div class="mb-8">
                <h2 class="text-xl font-semibold mb-2">O que você precisa?</h2>
                <p class="text-gray-600">Selecione uma das opções abaixo:</p>
            </div>

            <div class="space-y-4">
                <label
                    class="block p-4 border rounded-lg hover:border-blue-200 cursor-pointer transition-colors"
                    onClick={() => setCorrectionOption('correctionService')}
                >
                    <div class="flex items-start gap-4">
                        <input class="mt-1" type="radio" name="correction-type" />
                        <div>
                            <div class="flex items-center gap-2 mb-2">
                                <Edit className="!h-5 !w-5 text-blue-600" />
                                <span class="font-medium">Ajustar o serviço que recebi</span>
                            </div>
                            <p class="text-gray-600">Escolha esta opção se você precisa de alterações no serviço que já recebeu. Por exemplo: correção de erros, ajustes no texto ou mudanças no formato. Prazo de entrega: 24 horas.</p>
                        </div>
                    </div>
                </label>

                <label
                    class="block p-4 border rounded-lg hover:border-blue-200 cursor-pointer transition-colors"
                    onClick={() => setCorrectionOption('newService')}
                >
                    <div class="flex items-start gap-4">
                        <input class="mt-1" type="radio" name="correction-type" />
                        <div>
                            <div class="flex items-center gap-2 mb-2">
                                <PlusCircle className="!h-5 !w-5 text-green-600" />
                                <span class="font-medium">Solicitar serviço adicional</span>
                            </div>
                            <p class="text-gray-600">Escolha esta opção para adicionar um novo serviço ao seu caso. Por exemplo: incluir um cálculo, adicionar uma guia ou pedir outro documento. Este será um novo serviço com cobrança separada.</p>
                        </div>
                    </div>
                </label>
            </div>

            <div class="mt-8 flex justify-end gap-4">
                <Button type="text" onClick={onCancel}>
                    Cancelar
                </Button>
                <Button
                    type="primary"
                    onClick={() => onSelect(correctionOption)}
                    loading={loading}
                >
                    Continuar
                </Button>
            </div>
        </div>
    )
}