import { useEffect, useState } from "react";

import VideoModal from "../marketing/videos/video.modal.component";

import { videos } from "../../domain/constant/marketing/video.constant";

import loginService from "../../domain/services/login.service";
import customerService from "../../domain/services/customer/customer.service";

const ReferralProgramVideoModal = ({ openByUser, onClose, setAlert }) => {

    const [showingVideoModal, setShowingVideoModal] = useState(false);

    useEffect(() => checkInitializeModal(), []);

    const checkInitializeModal = () => {

        const user = loginService.getUserAuthentication();

        const showModal = !user.customer.showedNotifications?.referralProgram;

        if (!showModal && !openByUser) {
            onClose();
            return;
        }

        setShowingVideoModal(true);
    }

    const checkModalShowed = () => {

        const user = loginService.getUserAuthentication();

        const newConfig = {
            ...user.customer,
            showedNotifications: {
                ...user.customer.showedNotifications,
                referralProgram: true
            }
        }

        loginService.refreshUserAuthentication({ ...user, customer: newConfig });
    }

    const markNotificationShowed = async () => {

        const user = loginService.getUserAuthentication();

        await customerService
            .edit(
                user.customer.id,
                {
                    showedNotifications: {
                        ...user.customer.showedNotifications,
                        referralProgram: true
                    }
                }
            ).then(checkModalShowed);
    }

    const handleClose = async () => {

        if (!openByUser)
            await markNotificationShowed();

        onClose();
        setShowingVideoModal(false);
    }

    return showingVideoModal && (
        <VideoModal videoKey={videos.referralProgram} onClose={() => { handleClose() }} setAlert={setAlert} />
    )
}

export default ReferralProgramVideoModal;
