

import RequestCustomerCorrectionButton from './request.customer.correction.button.component';
import stepsSolicitation from '../../domain/constant/steps.solicitation.constant';

import loginService from '../../domain/services/login.service';
import { useTour } from '../../context/tourContext';
import { tours } from '../../domain/constant/tour.constant';
import Row from "../shared/grid/Row";
import Col from "../shared/grid/Col";
import Tooltip from "../shared/Tooltip";
import CustomerRequestFavoriteButton from "./customer/customer.request.favorite.button";
import RequestCustomerTipButton from "./rating/request.customer.tip.button";

export default function RequestDetailsActionButtons({
    mainRequestId, request, setAlert, refresh, isSmall, setAllowNext, tutorial
}) {

    const { registerRef } = useTour();

    const correctionRef = registerRef(tours.concludedRequestTour, 'correction');

    const showCorrectionButton = loginService.isCustomer() && request.status === stepsSolicitation.CONCLUDED;
    const showFavoriteButton = request.correspondentId;
    const showTipButton = request.concludedAt;

    const isShowingAButton = showCorrectionButton || showFavoriteButton || showTipButton;

    return (
        <Row gutter={[24, 24]} className={`text-base ${isShowingAButton ? 'mt-6' : 'mt-0'}`}>
            {showCorrectionButton && (
                <Col span={24}>
                    <div ref={correctionRef}>
                        <RequestCustomerCorrectionButton
                            request={request}
                            setAlert={setAlert}
                            onSendToCorrection={refresh}
                            setAllowNext={setAllowNext}
                            tutorial={tutorial}
                        />
                    </div>
                </Col>
            )}
            {showFavoriteButton && (
                <Col span={24} md={12} lg={12} xl={12}>
                    <CustomerRequestFavoriteButton
                        requestData={request}
                        setAlert={setAlert}
                    />
                </Col>
            )}
            {showTipButton && (
                <Col span={24} md={12} lg={12} xl={12}>
                    <Tooltip title="Adicione um valor extra para reconhecer o bom trabalho do profissional. O valor será debitado do seu pacote.">
                        <RequestCustomerTipButton
                            requestData={request}
                            setAlert={setAlert}
                        />
                    </Tooltip>
                </Col>
            )}
        </Row>


    );
}