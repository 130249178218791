import { useTheme } from "@material-ui/core";
import styled, { css } from "styled-components";
import LoadingSpin from "../LoadingSpin";

const variantStyles = {
    primary: css`
        background-color: ${props => props.primaryColor};
        color: #fff;
        transition: filter .3s ease;
        &:hover {
            filter: brightness(1.2);
        }
    `,
    secondary: css`
        background-color: #fff;
        color: #232323;
        border: solid 1px #dedede;
        transition: color .3s ease, border-color .3s ease, background-color .3s ease;
        &:hover {
            background-color: #fafafa;
            color: ${props => props.primaryColor};
            border-color: ${props => props.primaryColor};
        }
    `,
    dashed: css`
        background-color: #fff;
        color: #232323;
        border: dashed 1px ${props => props.primaryColor};
        transition: color .3s ease, border-color .3s ease, background-color .3s ease;
        &:hover {
            background-color: #fafafa;
            color: ${props => props.primaryColor};
            border-color: ${props => props.primaryColor};
        }
    `,
    text: css`
        background-color: transparent;
        color: #232323;
        transition: background-color .3s ease;
        &:hover {
            background-color: #f0f0f0;
        }
    `,
    link: css`
        background-color: transparent;
        color: ${props => props.primaryColor};
        transition: filter .3s ease;
        &:hover {
            filter: brightness(1.2);
        }
    `
};

const StyledButton = styled.button`
    padding: 6px 16px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    ${props => variantStyles[props.variant || 'primary']}

    &:disabled {
        pointer-events: none;
        filter: grayscale(1) brightness(1.6);
    }
`;

export default function Button({
    onClick,
    children,
    type = 'primary',
    icon = null,
    loading = false,
    htmlType = 'button',
    color = 'default',
    disabled = false,
    ...props
}) {

    const theme = useTheme();

    const colors = {
        default: theme.palette.primary.main,
        danger: '#ff4d4f',
    };

    return (
        <StyledButton
            type={htmlType}
            onClick={!loading && !disabled ? onClick : undefined}
            primaryColor={colors[color]}
            variant={type}
            disabled={disabled}
            {...props}
        >
            <div
                className="h-full overflow-hidden flex items-center justify-start"
                style={{
                    transition: 'width .3s ease',
                    width: icon || loading ? 24 : 0,
                }}
            >
                <span className="min-w-[16px] min-h-[16px] flex items-center justify-center">
                    {loading ? <LoadingSpin size="small" color={type === 'primary' ? '#fff' : theme.palette.primary.main} /> : icon}
                </span>
            </div>
            {children}
        </StyledButton>
    );
}
