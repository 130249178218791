import videoRepository from '../../repositories/marketing/video.repository';
import announcementRepository from '../../repositories/marketing/announcement.repository';

import loginService from '../login.service';

const find = async (key) =>
    videoRepository
        .findByKey(key)
        .then(res => res.data);

const findAll = async () =>
    videoRepository
        .findAll()
        .then(res => res.data);

const checkVideoViewed = async (isViewed, video) => {

    if (!isViewed)
        return Promise.reject();

    if (!video.announcementId)
        return;

    return announcementRepository.markViewed(video.announcementId)
}

const updateLocalStorage = (videoKey) => {

    const user = loginService.getUserAuthentication();

    const newAnnouncementsViewed = (user.announcementsViewed || []);
    newAnnouncementsViewed.push(videoKey)

    const newUser = { ...user, announcementsViewed: newAnnouncementsViewed }
    loginService.refreshUserAuthentication(newUser);
}

export default {
    find,
    findAll,
    checkVideoViewed,
    updateLocalStorage
}