import React, { useState } from "react";
import { Grid, Dialog, DialogActions, DialogTitle, DialogContent, Typography } from "@material-ui/core"
import { Button } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import RequestCustomerTipForm from "./request.customer.tip.form.component";


const RequestCustomerTipDialog = ({ request = {}, initialRating, setAlert, isOpen, onClose, onFinish }) => {

    const [openTipForm, setOpenTipForm] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpenTipForm = async () => {
        onClose();
        setOpenTipForm(true);
    };

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={isOpen}
                maxWidth='sm'
                onClose={(e, reason) => (reason !== 'backdropClick') && onClose()}
            >
                <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="h4" style={{margin:'10px'}}>
                        <strong>🌟 Incrível! Sua avaliação alta realmente nos alegra! 🌟</strong>
                    </Typography>
                </DialogTitle>

                <DialogContent>

                    <Typography variant='subtitle1'>
                        Parece que nosso faciliter fez um trabalho fantástico.
                        Que tal mostrar seu agradecimento com um gesto especial? 🙏💐
                        <br /><br />
                        Uma pequena gorjeta pode ser um grande incentivo para eles continuarem fazendo um trabalho excepcional.
                        <br />
                        É rápido, simples e significa muito. 💖
                        <br /><br />
                        Vamos dar esse toque de carinho e reconhecimento? Será descontado direto do seu pacote ou créditos e dividido igualmente entre os envolvidos na solicitação.
                    </Typography>
                    <br></br>

                    <DialogActions style={{
                        display: 'flex', alignItems: 'center',
                        justifyContent: 'flex-end', gap: '10px',
                    }}>

                        <Button size="large" variant="contained" onClick={onClose}>
                            Não dar reconhecimento
                        </Button>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            onClick={handleOpenTipForm}
                        >
                            Dar gorjeta
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

            <RequestCustomerTipForm
                isOpen={openTipForm}
                request={request}
                setAlert={setAlert}
                onFinish={onFinish}
                onClose={() => setOpenTipForm(false)}
            />
        </React.Fragment>
    )
}

export default RequestCustomerTipDialog;