import { Skeleton } from "@material-ui/lab";

/**
 * Componente genérico de estatística
 * @property {string} title Título que é exibido acima do valor
 * @property {string} value Valor exibido abaixo do título
 * @property {bool} loading Estado de loading
 * @property {node} prefix Elemento exibido antes do valor
 * @property {node} suffix Elemento exibido após o valor
 * @example
 * <Statistic
 *      title="Data de Entrega"
 *      loading={loading}
 *      value={dateProvider.formatDate('04/05/2025')}
 *      prefix={<CalendarIcon />}
 * />
 */
export default function Statistic({
    title,
    value,
    prefix = <></>,
    suffix = <></>,
    loading = false,
    titleClassName = '',
}) {
    return (
        <div className="text-start">
            <div className={`text-lg text-[#0009] ${titleClassName}`}>
                {loading ? (
                    <Skeleton variant="rect" height={16} width={120} />
                ) : (
                    <>{title}</>
                )}
            </div>
            <div className="mt-3 flex gap-1 leading-none items-center" style={{ fontSize: "16px" }}>
                {loading ? (
                    <Skeleton variant="rect" height={20} width={64} />
                ) : (
                    <>
                        {prefix}
                        <div translate="no">{value}</div>
                        {suffix}
                    </>
                )}
            </div>
        </div>
    );
}