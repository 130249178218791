import React, { useState, useEffect } from "react";
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import swal from "sweetalert";

import {
    Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, MenuItem,
    Grid, RadioGroup, Radio, Button, Typography, Divider, Select,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";

import CorrectionForm from "./correction/correction.form.component.legacy";
import LoadingProgress from "../LoadingProgress";
import RequestCalculationServices from '../requests/calculation/request.calculation.services.component';

import requestServicesService from '../../domain/services/solicitation/request.services.service';
import requestValidator from '../../domain/validators/request.validator';
import requestCorrectionService from "../../domain/services/request/request.correction.service";

import requestFieldsConfig from "../requests/fields/request.fields.config";

import { requestServices } from "../../domain/constant/request.services.constant";
import requestRegistryService from "../../domain/services/solicitation/request.registry.service";

function CustomerReturnSolicitation(props) {

    const { onFinish = () => { }, request } = props

    const { promiseInProgress } = usePromiseTracker();

    const [correctionValues, setCorrectioValues] = useState(requestCorrectionService.newCorrectionValues());
    const [correctionType, setCorrectionType] = useState(null);
    const [services, setServices] = useState([]);
    const [newService, setNewService] = useState({});
    const [correctionFields, setCorrectionFields] = useState([]);
    const [additionalFields, setAdditionalFields] = useState([]);
    const [additionalFieldsValues, setAdditionalFieldsValues] = useState({});

    const isReadOnly = requestCorrectionService.checkIsReadOnly(correctionValues, request.status);

    useEffect(() => {
        if (!props.mainRequestId)
            return null
        getServices()
    }, [props.mainRequestId]);

    useEffect(() => {
        const { correctionValues: selectedCorrections, isCreating } = props;

        setCorrectioValues(selectedCorrections || requestCorrectionService.newCorrectionValues());

        getServiceCorrectionFields(selectedCorrections, isCreating);


    }, [props.correctionValues]);

    useEffect(() => {
        if (newService.key != requestServices.ISSUEPAYMENTFEE)
            return;

        requestRegistryService.getFieldsToCreateIssuePaymentServiceAfterConclusion(request.id)
            .then((res) => setAdditionalFields(res.fields))
    }, [newService])

    const getServiceCorrectionFields = async (correction, isCreating) => {

        if (isCreating) {
            const corrections = await requestCorrectionService.findCorrectionFieldsByService(request.serviceId);
            setCorrectionFields(corrections.data);
            return;
        }

        const corrections = await requestCorrectionService.findMatchingCorrectionFields(correction, request.serviceId);
        setCorrectionFields(corrections);
    }

    const getServices = async () =>
        trackPromise(
            requestServicesService
                .getAvailableServicesToCreateAfterConclusion(props.mainRequestId)
                .then((res) => {

                    if ((res.data || []).length == 0)
                        handleSelectServiceCorrection();

                    setServices(res.data)
                })
                .catch(handleSelectServiceCorrection)
        )
            .finally(() => !props.isCreating && handleSelectServiceCorrection());


    const handleChangeCorrectionType = (newType) => {

        if (newType == 'correctionService')
            return handleSelectServiceCorrection();

        setCorrectionType(newType);
    }

    const handleSelectServiceCorrection = () => {

        if (!!props.correctionValues) {
            setCorrectionType('correctionService')
            return;
        }

        return requestCorrectionService
            .checkCorrectionAvailability(request.id)
            .then(() => setCorrectionType('correctionService'))
            .catch(err => {
                props.setOpen(false);
                swal({
                    title: '⚠️ Atenção! ⚠️',
                    text: `
                        Infelizmente, o período máximo de pedido de correção expirou ${err?.response?.data?.correctionExpirationDate ? `no dia ${err?.response?.data?.correctionExpirationDate}` : ''}  e não é mais possível pedir uma correção.
                        
                       Estamos aqui para ajudá-lo. Se precisar de qualquer assistência, basta entrar em contato com o suporte e nossa equipe estará pronta para garantir que você tenha uma experiência incrível conosco.

                    Agradecemos sua compreensão e estamos ansiosos para continuar a atendê-lo! 😊
                    `,
                    icon: false,
                    buttons: {
                        confirm: 'Tudo bem'
                    }
                })
            });
    }

    const save = async (values) => {

        const action = requestCorrectionService.returnActionToSaveOrUpdateCorrection(values, props, correctionFields, correctionValues);

        await trackPromise(
            action()
                .then(() => {
                    if (!correctionValues.isReadOnly)
                        props.returnStep();

                    props.setOpen(false);
                    props.onSave && props.onSave();
                })
                .catch(error => props.setAlert(error.response.data.message, "error"))
        );

        onFinish()
    }

    const createService = async () => {

        if (!newService.key || newService.key == 'calculation' && !newService.calculationServiceId)
            return props.setAlert('Preencha todos os campos para criar o serviço!', "error")

        if (newService.key == requestServices.ISSUEPAYMENTFEE && Object.keys(additionalFieldsValues).length == 0)
            return props.setAlert('Preencha todos os campos para criar o serviço!', "error")

        additionalFields.map(async (field) => {
            await requestRegistryService.updateField(request.id, field.id, { value: additionalFieldsValues[field.key] })
        })

        const serviceToCreate = services.find(s => s.key == newService.key);
        const calculationServiceId = serviceToCreate.key == 'calculation' ? newService.calculationServiceId : null;

        await trackPromise(
            requestServicesService.create(props.mainRequestId, { serviceId: serviceToCreate.id, calculationServiceId })
                .then(() => props.setAlert('Serviço criado com sucesso!', "success"))
                .then(() => props.setOpen(false))
                .then(() => window.location.href = `/solicitations/${props.mainRequestId}`)
                .catch(error => props.setAlert(error?.response?.data?.message, "error"))
        );

        onFinish()
    }

    const closeModal = () => {
        props.setOpen(false);
        if (props.cancelCorrection)
            props.cancelCorrection();

        onFinish()
    };

    const loading = () => {
        return (promiseInProgress && <LoadingProgress />);
    }

    const onChange = (field, fieldValue) =>
        setNewService((values) => ({ ...values, [field]: fieldValue }));

    useEffect(
        () => {
            if (promiseInProgress && props.setAllowNext) props.setAllowNext(true)
        }, [promiseInProgress]
    )

    const renderFields = () => {

        return additionalFields.map((field, index) => (
            <React.Fragment key={index}>
                {requestFieldsConfig.getComponentField({
                    field,
                    request,
                    setValue: (...params) => { setAdditionalFieldsValues((values) => ({ ...values, [field.key]: params[2] })) },
                    isEdit: true,
                    fieldProps: {},
                    fieldsWithError: [],
                    setAlert: () => { }
                })}
            </React.Fragment>
        ));
    };

    return (
        <Dialog open={props.open} onClose={closeModal} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Edit style={{ marginBottom: "-4px", marginRight: 6, fontSize: "18px", color: "#376fd0" }} />
                {correctionValues.isReadOnly ? "Solicitação de correção" : "Solicitar Correção"}
            </DialogTitle>

            <DialogContent>
                {loading()}

                {!promiseInProgress &&
                    <Grid container direction="column" spacing={6} style={{ marginTop: -8, marginBottom: 4 }}>

                        {services.length > 0 && props.isCreating && (
                            <Grid item>

                                <Typography variant='h5' gutterBottom>
                                    Por que está solicitando esta correção?
                                </Typography>

                                <Divider style={{ marginBottom: '10px' }} />

                                <RadioGroup
                                    row={true}
                                    value={correctionType}
                                    onChange={(e) => handleChangeCorrectionType(e.target.value)}
                                >

                                    <FormControlLabel
                                        key={'correctionService'}
                                        value={'correctionService'}
                                        control={<Radio />}
                                        label={'Desejo que um serviço já realizado seja corrigido'}
                                        disabled={isReadOnly}
                                    />

                                    <FormControlLabel
                                        key={'newService'}
                                        value={'newService'}
                                        control={<Radio />}
                                        label={'Desejo um novo serviço para essa solicitação'}
                                        disabled={isReadOnly}
                                    />

                                </RadioGroup>

                            </Grid>
                        )}

                        {correctionType == 'correctionService' && (
                            <>
                                <Grid item>
                                    <CorrectionForm
                                        initialValues={correctionValues}
                                        isReadOnly={isReadOnly}
                                        onSubmit={save}
                                        onClose={closeModal}
                                        fields={correctionFields}
                                    />
                                </Grid>
                            </>
                        )}

                        {correctionType == 'newService' && (
                            <Grid item>
                                <Typography variant='h5' gutterBottom>
                                    Selecione o serviço a ser criado
                                </Typography>

                                <Divider style={{ marginBottom: '20px' }} />

                                <Grid container spacing={6}>

                                    <Grid item xs={12}>
                                        <FormControl m={2} style={{ width: "100%" }}>

                                            <Select
                                                value={newService.key || null}
                                                required displayEmpty
                                                renderValue={!newService.key ? () => <div style={{ color: "#aaa" }}>Selecione um serviço</div> : null}
                                                labelId='services'
                                                onChange={(e) => {
                                                    onChange("key", e.target.value);
                                                }}
                                            >
                                                {services.map((item, index) =>
                                                    (<MenuItem key={index} value={item.key}>{item.name}</MenuItem>)
                                                )}
                                            </Select>

                                        </FormControl>
                                    </Grid>

                                    {requestValidator.isIssuePaymentFeesService(newService.key) && additionalFields.length > 0 && (
                                        <Grid item xs={12}>
                                            {renderFields()}
                                        </Grid>
                                    )}

                                    {requestValidator.isCalculationService(newService.key) &&
                                        <Grid item xs={12}>

                                            <RequestCalculationServices
                                                label='Selecione o tipo de cálculo que será desenvolvido'
                                                request={request}
                                                requestId={request?.id}
                                                areaId={request.areaId}
                                                calculationServiceId={newService.calculationServiceId || ''}
                                                isEdit={false}
                                                setFieldValue={(field, value) => onChange('calculationServiceId', value)}
                                                fieldProps={{
                                                    titleColor: '#000000DE',
                                                    titleSize: 15,
                                                    titleWeight: 500,
                                                    spacing: 10
                                                }}
                                            />

                                        </Grid>
                                    }

                                    <Grid item xs={12} style={{ gap: 8 }} container justify="flex-end">
                                        <Grid item>
                                            <Button variant="contained"
                                                disabled={promiseInProgress}
                                                onClick={closeModal}>
                                                Fechar
                                            </Button>
                                        </Grid>
                                        {!isReadOnly &&
                                            <Grid item>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    disabled={promiseInProgress}
                                                    onClick={createService}
                                                >
                                                    Enviar
                                                </Button>
                                            </Grid>
                                        }
                                    </Grid>

                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                }
            </DialogContent>
        </Dialog>
    );
}

export default CustomerReturnSolicitation;


