import { useState } from 'react';

import { PlaylistAddCheck } from '@material-ui/icons';

import RequestServiceDocumentList from './request.service.documents.list.component';
import RequestAnalysisCustomerView from '../analysis/request.analysis.customer.view';
import RequestHearingAnalysisCustomerView from '../hearing/request.hearing.analysis.customer.view';
import RequestHearingCustomerView from '../hearing/request.hearing.customer.view';
import DocumentPreview from '../../solicitations/documents/document.preview.component';
import modalities from '../../../domain/constant/modalities.constant';
import Button from "../../shared/Button";
import { Alert, AlertTitle } from "@material-ui/lab";

const RequestServiceDocumentsContent = ({
    documents,
    request,
    additionalDocuments,
}) => {

    const isCorrection = request.status != 'CONCLUDED';

    const [preview, setPreview] = useState({ show: false });

    const handleOpenPreview = (file) => setPreview({ ...file, show: true });

    const renderButton = ({
        label,
        onClick,
    }) => (
        <div>
            <Button
                type="primary"
                className="!rounded-lg !text-sm md:!text-base h-10 !font-normal"
                onClick={onClick}
            >
                {label}
            </Button>
        </div>
    )

    const specificContentByServiceKey = (item) => ({
        analysis: {
            name: item.name,
            icon: PlaylistAddCheck,
            action: () => (
                <RequestAnalysisCustomerView
                    requestId={item.id}
                    buttonVariant="contained"
                    buttonTitle="Acessar"
                    buttonWidth="100%"
                    CustomButton={({ onClick }) => renderButton({
                        onClick,
                        label: 'Acessar',
                    })}
                />
            )
        },
        hearingAnalysis: {
            name: item.name,
            icon: PlaylistAddCheck,
            action: () => (
                <RequestHearingAnalysisCustomerView
                    requestId={item.id}
                    buttonVariant="contained"
                    buttonTitle="Acessar"
                    buttonWidth="100%"
                    CustomButton={({ onClick }) => renderButton({
                        onClick,
                        label: 'Acessar',
                    })}
                />
            )
        },
        hearing: {
            name: item.name,
            icon: PlaylistAddCheck,
            action: () => (
                <RequestHearingCustomerView
                    requestId={item.id}
                    buttonVariant="contained"
                    buttonTitle="Acessar"
                    buttonWidth="100%"
                    CustomButton={({ onClick }) => renderButton({
                        onClick,
                        label: 'Acessar',
                    })}
                />
            )
        },
    })[item.key];

    const getServiceList = (item, index) => {

        const specificContent = specificContentByServiceKey(item);

        const docs = specificContent ? [specificContent, ...item.documents] : item.documents;

        return (
            <RequestServiceDocumentList
                request={request}
                documents={docs}
                openPreview={handleOpenPreview}
                serviceName={item.name}
                isNotion={item.serviceKey == modalities.notion.key}
            />
        )
    }

    const getAdditionalDocumentsList = () => (
        <div>
            <div class="flex items-center gap-2 mb-3">
                <div class="shrink-0 h-2 w-2 bg-gray-400 rounded-full"></div>
                <span class="text-sm font-medium text-gray-600">
                    Documentos Complementares
                </span>
            </div>

            <div className="flex flex-col gap-6">
                <RequestServiceDocumentList
                    request={request}
                    openPreview={handleOpenPreview}
                    documents={additionalDocuments}
                    isPrimary={false}
                />
            </div >
        </div >
    );

    return (
        <>
            <div className="flex flex-col gap-6">

                <div>
                    <div class="flex items-center gap-2 mb-3">
                        <div class="shrink-0 h-2 w-2 bg-blue-400 rounded-full"></div>
                        <span class="text-sm font-medium text-blue-600">
                            Documentos Principais
                        </span>
                    </div>

                    <div className="flex flex-col gap-4">
                        {!isCorrection
                            ? documents.documentsByService?.map(getServiceList)
                            : (
                                <Alert severity='info'>
                                    <AlertTitle>
                                        <strong>Documento em correção!</strong>
                                    </AlertTitle>
                                    A solicitação está em correção e os documentos principais ficarão
                                    disponíveis aqui assim que a correção for finalizada.
                                </Alert>
                            )}
                    </div>
                </div>

                {!!additionalDocuments.length && getAdditionalDocumentsList()}

            </div>
            {preview.show && (
                <DocumentPreview file={preview}
                    onSelect={() => setPreview({ show: false })} />
            )}

        </>
    );
}

export default RequestServiceDocumentsContent;