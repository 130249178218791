import { InputAdornment, TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import useDebounce from '../../providers/debounce';
import Menu from '../Menu';
import MenuPage from '../MenuPage';
import VirtualizedList from '../VirtualizedList';
import ResponsibleButton from './responsible.button.component';
import ResponsibleChip from './responsible.chip.component';

const SelectResponsible = ({
  data = [],
  responsible,
  title,
  label,
  color,
  getList,
  onSelect,
  onDelete,
}) => {
  const [name, setName] = useState('');
  const debounced = useDebounce(name, 500);

  const handleInput = (event) => {
    setName(event.target.value);
  };

  useEffect(() => (debounced ? getList([{ name: 'name', value: debounced }]) : getList()), [
    debounced,
    getList,
  ]);

  if (responsible)
    return (
      <ResponsibleChip
        id={responsible.responsibleId}
        name={responsible.name}
        currentPrice={responsible.price}
        color={color}
        onDelete={onDelete}
        label={label}
      />
    );

  return (
    <Menu onOpen={getList} button={<ResponsibleButton title={title} label={label} color={color} className="!mb-2" />}>
      <MenuPage
        title={title}
        footer={`${data.length} ${data.length > 1 ? 'encontrados' : 'encontrado'}`}
      >
        <TextField
          onChange={handleInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined style={{ fontSize: '14px', color: '#777', marginLeft: '8px' }} />
              </InputAdornment>
            ),
          }}
          style={{ color: '#333' }}
          placeholder="Filtrar pelo nome"
          fullWidth
        />
        <VirtualizedList list={data} onSelect={onSelect} color={color} />
      </MenuPage>
    </Menu>
  );
};

export default SelectResponsible;
