import { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    Button,
    TextareaAutosize,
    CircularProgress,
    FormControl,
} from "@material-ui/core"
import { announcements } from "../../../domain/constant/announcements.constant";
import customerRatingService from "../../../domain/services/solicitation/customer.rating.service";
import loginService from "../../../domain/services/login.service";
import announcementService from "../../../domain/services/marketing/announcement.service";
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { ThumbUp, ThumbDown, CheckCircle } from '@material-ui/icons';

const RequestCustomerRatingForm = ({ requestId, initialRating = null, requestMainId, setAlert, onFinish, additionalInformation = { channel: 'platform' }, index = 0 }) => {

    const [rating, setRating] = useState({ ...initialRating, additionalInformation });
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setRating({ ...initialRating, additionalInformation })
    }, [JSON.stringify(initialRating), JSON.stringify(additionalInformation)])

    const { promiseInProgress } = usePromiseTracker();

    const customerUserId = loginService.getUserAuthentication().id;

    const mainRequestId = requestMainId || requestId;

    const showSuccess = () =>
        setAlert('Avaliado com sucesso!', 'success');

    const onSubmitRating = async (savedRating) => {
        setAlert('Avaliação enviada com sucesso!', 'success')
        !announcementService.isShowed(announcements.customerRatingInvite) && announcementService.markViewed(announcements.customerRatingInvite)
        showSuccess();
        await onFinish({ ...savedRating, requestId: mainRequestId, additionalInformation: savedRating?.additionalInformation })
    }

    const submitPromise = async (ratingToSave, showSaveProcess = true) => {

        if (isSaving)
            return;

        setIsSaving(true);
        const saveAction = ratingToSave?.id ? customerRatingService.update : customerRatingService.save;

        const savedRating = await saveAction({
            id: ratingToSave?.id,
            requestId: mainRequestId,
            userId: customerUserId,
            ...ratingToSave,
        })
            .then(response => response?.data)
            .then(data => {
                setRating(data)   
                return data
            })
            .catch(_err => setAlert('Não foi possível enviar a avaliação! Tente novamente', 'error'));


        setIsSaving(false);
        if (!showSaveProcess || !savedRating)
            return

        await onSubmitRating(savedRating);
    }

    const handleSubmit = async () =>
        trackPromise(submitPromise(rating));

    const handleSelectRating = (ratingText) => {
        const newRating = { ...rating, ratingText }
        setRating(newRating)
        submitPromise(newRating, false);
    }

    const ratingOptions = [
        {
            buttonVariant: 'outlined',
            text: 'Gostei',
            getIcon: (props) => <ThumbUp {...props} />,
            action: () => handleSelectRating('positive'),
            value: 'positive'
        },
        {
            buttonVariant: 'outlined',
            text: 'Não gostei',
            getIcon: (props) => <ThumbDown {...props} />,
            action: () => handleSelectRating('negative'),
            value: 'negative'
        },
    ]

    const renderCustomerFeedback = () => {
        return (
            <div class="flex flex-column gap-5 h-10 w-full justify-center">
                {ratingOptions.map(ratingOption =>
                    <div style={{ position: 'relative', minWidth: '200px' }}>
                        <Button
                            variant={ratingOption.buttonVariant}
                            color='primary'
                            fullWidth
                            style={{
                                height: '100%',
                                fontSize: '14px',
                                border: rating.ratingText === ratingOption.value ? 'solid 2px #1976d2' : 'solid 1px #ccc',
                                position: 'relative'
                            }}
                            onClick={ratingOption.action}
                        >
                            {isSaving && rating.ratingText === ratingOption.value ? (
                                <CircularProgress size={20} style={{ marginRight: 10 }} />
                            ) : (
                                ratingOption.getIcon({ style: { marginRight: 10, fontSize: 18 } })
                            )}
                            {ratingOption.text}
                            {rating.ratingText === ratingOption.value && !isSaving && (
                                <CheckCircle style={{ position: 'absolute', top: -10, right: -10, fontSize: 20, color: '#1976d2', backgroundColor: 'white', borderRadius: '50%' }} />
                            )}
                        </Button>
                    </div>
                )}
            </div>
        )
    }

    return !promiseInProgress ? (
        <Grid className="text-base mt-3">

            <div className="text-[#444]">
                Contratamos, treinamos e demitimos por você. Sua avaliação nos ajuda a manter os melhores advogados na plataforma!
            </div>

            {
                !announcementService.isShowed(announcements.customerRatingInvite) && (
                    <Typography variant="subtitle1" style={{ lineHeight: 1.5, marginBottom: 20 }}>
                        Você contribui diretamente para o desenvolvimento dos nossos Faciliters, possibilitando novos
                        treinamentos, reconhecimento dos melhores e até mesmo o desligamento daqueles que não atendem
                        aos nossos altos padrões.
                    </Typography>
                )
            }

            <div className="py-8">
                <p className="text-base font-semibold mb-4">Você gostou do serviço realizado?</p>
                {renderCustomerFeedback()}
            </div>
            {rating?.ratingText && !isSaving && (
                <div className="mt-4">
                    <div className="text-base font-semibold mb-2">
                        Deixe aqui o seu feedback sobre a petição
                    </div>
                    <FormControl fullWidth>
                        <TextareaAutosize
                            style={{ border: 'solid 1px #ededed', padding: 8 }}
                            id="remark"
                            name="remark"
                            minRows={3}
                            value={rating.remark}
                            placeholder="Digite seu feedback..."
                            onChange={(e) => setRating({ ...rating, remark: e.target.value })}
                        />
                    </FormControl>
                </div>
            )}

            {
                !announcementService.isShowed(announcements.customerRatingInvite) && (
                    <Typography variant="subtitle1" style={{ lineHeight: 1.5, marginBottom: 10 }}>
                        Agradecemos desde já pela sua colaboração e atenção. Juntos, vamos elevar
                        nossos serviços a um novo patamar de excelência! 🚀
                    </Typography>
                )
            }

            {rating?.ratingText ?
                <div className="mt-10 flex justify-center">
                    <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        disabled={promiseInProgress || isSaving}
                        onClick={() => handleSubmit(rating)}
                    >
                        <span className="text-base font-semibold">
                            Enviar avaliação
                        </span>
                    </Button>
                </div>
                : null
            }
        </Grid>
    ) : (
        <Grid container justifyContent="center" style={{ margin: '25px 0' }}>
            <CircularProgress size={50} color="secondary" />
        </Grid>
    )
}

export default RequestCustomerRatingForm;