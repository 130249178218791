import { Link } from "react-router-dom";
import datetimeProvider from "../../../providers/datetime.provider";
import Button from "../../shared/Button";
import { ChevronDown } from "react-feather";
import RequestServiceDocumentList from "./request.service.documents.list.component";
import { useState } from "react";
import DocumentPreview from "../../solicitations/documents/document.preview.component";
import Card from "../../shared/Card";

export default function RequestCorrectionDocumentsCard({
    documents = [],
    request,
}) {

    const [preview, setPreview] = useState({ show: false });
    const [expanded, setExpanded] = useState(null);

    const handleOpenPreview = (file) => setPreview({ ...file, show: true });

    const renderCorrection = (doc, index) => (
        <div>
            <div class="flex sm:flex-row flex-col items-center gap-1 mb-3">
                <div class="shrink-0 h-2 w-2 bg-orange-400 rounded-full"></div>
                <span class="text-sm font-medium text-orange-600">
                    {documents.length - index} Correção - <span className="font-light font-sm">{datetimeProvider.formatDateTime(doc.created)}</span>
                </span>
                <div>
                    <Link to={`/solicitations/${request.id}/correction/${doc.correctionSource}`}>
                        <Button
                            type="text"
                            className="!text-sm !font-normal !text-primary"
                        >
                            Acessar Correção
                        </Button>
                    </Link>
                </div>

                <div className="sm:ml-auto">
                    <Button
                        type="text"
                        className="!text-sm !font-normal !text-gray-600"
                        icon={(
                            <ChevronDown
                                style={{
                                    transform: expanded === index ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                size={16}
                            />
                        )}
                        onClick={() => setExpanded(prev => prev === index ? null : index)}
                    >
                        {expanded === index ? 'Encolher' : 'Expandir'}
                    </Button>
                </div>
            </div>

            <div
                className="grid overflow-hidden"
                style={{
                    transition: 'grid-template-rows 0.3s ease-in-out',
                    gridTemplateRows: expanded === index ? '1fr' : '0fr',
                }}
            >
                <div className="flex flex-col gap-6 overflow-hidden">
                    <RequestServiceDocumentList
                        request={request}
                        openPreview={handleOpenPreview}
                        documents={doc.documents}
                        isPrimary={false}
                    />
                </div >
            </div>
        </div >
    )

    return (
        <>
            <Card>
                <div className="text-lg font-medium">
                    Documentos de Correções
                </div>
                <div className="mt-1 text-sm text-gray-500">
                    Documentos anexados para detalhar os ajustes solicitados nas correções.
                </div>
                <div className="mt-4">

                    <div className="flex flex-col gap-6">
                        <div>
                            <div className="flex flex-col gap-4">
                                {documents.map(renderCorrection)}
                            </div>
                        </div>

                    </div>
                </div>
            </Card>

            {preview.show && (
                <DocumentPreview
                    file={preview}
                    onSelect={() => setPreview({ show: false })}
                />
            )}

        </>
    );
}
