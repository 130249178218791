
import datetimeProvider from "../../providers/datetime.provider";

import Tooltip from "../shared/Tooltip";
import { Check, Clock } from "react-feather";

export default function RequestServiceInformationHearingDateInfo({
    dateTimeInfo,
    isCheckin
}) {

    const datetime = datetimeProvider.formatDateTimeInTimezone(dateTimeInfo, 'America/Sao_Paulo');

    return (
        <div>
            <div className="flex gap-2">
                <Tooltip title={isCheckin ? 'O faciliter confirmou que estava disponível para realizar a audiência nesse horário' : 'Data e Hora da Audiência'}>
                    <div className="text-gray-500 text-base flex gap-2 items-center">
                        {isCheckin ?
                            <Check className="!w-4 !h-4" />
                            : <Clock className="!w-4 !h-4" />
                        }
                        {isCheckin ? 'O faciliter realizou o check-in em' : 'Data e Hora da Audiência'}
                    </div>
                </Tooltip>

                <div className="flex items-center gap-1 text-sm">

                    <span>
                        {datetimeProvider.formatDateTime(datetime, 'DD/MM [às] HH:mm', true)}
                    </span>

                    {isCheckin ? null : (
                        <span className="text-gray-400">
                            {datetimeProvider.getTimeDifferenceUntilDate(datetimeProvider.getDate(), datetime, 'Audiência já realizada.')}
                        </span>
                    )}

                </div>
            </div>

            <div className="text-sm italic text-gray-400 ml-6">
                Horário de Brasília
            </div>

        </div>
    )
}