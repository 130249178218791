import api from '../api';

const save = async (data) =>
    api.post({ type: "user", service: `/marketing/use-tracking/`, data });

const update = async (eventId, data) => 
    api.put({type: "user", service: `/marketing/use-tracking/${eventId}`, data})

const redirectShortLinkByHash = async (hash) =>
    api.get({ type: 'user', service: `/marketing/use-tracking/process-short-link/${hash}` })
        .then(({ data }) => data);

export default {
    save,
    update,
    redirectShortLinkByHash,
}