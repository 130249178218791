

import { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import swal from 'sweetalert2';

import { Grid } from '@material-ui/core';

import FormField from '../FormField';
import AdvanceDueDate from './advance.due.date';

import loginService from '../../domain/services/login.service';
import solicitationService from '../../domain/services/solicitation/solicitation.service';

import stepsSolicitation from '../../domain/constant/steps.solicitation.constant';
import { Clock } from "react-feather";

export default function RequestAllServicesDueDate({
    mainRequestId, request, refresh, setAlert, setValue, fieldProps
}) {

    const [allServicesDueDate, setAllServicesDueDate] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => getAllServicesDueDate(request.allServicesDueDate), []);

    useEffect(() => setAllServicesDueDate(request.allServicesDueDate), [request.allServicesDueDate]);

    const getAllServicesDueDate = () =>
        solicitationService
            .getAvailableDueDate(request.mainId || request.id)
            .then(({ data }) => setAllServicesDueDate(data.allServicesDueDate));

    const editAllServicesDueDateSkipingValidation = (data = {}, value, oldDueDate) => {

        setLoading(false);

        if (!data.canSkipValidation) {
            setAllServicesDueDate(oldDueDate);
            setAlert((data.message || 'Não foi possível alterar a data de entrega de todos os serviços solicitação!'), "error");
            return
        }

        swal.fire({
            title: data.message,
            html: `Tem certeza que deseja alterar a data de entrega de todos os serviços da solicitação mesmo assim? Lembrando que alterar o prazo não altera a antecipação realizada, para isso é necessário editar a antecipação!`,
            icon: "warning",
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
        })
            .then(result => {

                if (result.isConfirmed)
                    return editAllServicesDueDate(value, oldDueDate, data.canSkipValidation);

                setAllServicesDueDate(oldDueDate);
            })
            .catch(err => {
                setAllServicesDueDate(oldDueDate);
                setAlert(err?.response?.data?.message || "Erro ao salvar informação", "error")
            });
    }

    const editAllServicesDueDate = async (value, oldDueDate, canSkipValidation = false) => {

        setLoading(true);

        await solicitationService
            .editAllServicesDueDate(mainRequestId, { allServicesDueDate: value, canSkipValidation })
            .then(res => res.data)
            .then(data => {
                if (data.changedDueDate)
                    setValue('dueDate', value)

                setValue('allServicesDueDate', value)
            })
            .then(() => setAlert("Salvo com sucesso", "success"))
            .catch(err => editAllServicesDueDateSkipingValidation(err?.response?.data, value, oldDueDate))
            .finally(() => setLoading(false));
    }

    const getCustomerAllServicesDueDate = () => (
        <div>
            <div className="text-base flex items-center gap-2 text-gray-500 flex-wrap">
                <span>Será entregue em:</span>
                <span>
                    {moment(allServicesDueDate).tz('America/Sao_Paulo').format("DD [de] MMMM [de] YYYY [às] HH:mm")}
                </span>
            </div>
            <div className="text-sm italic text-gray-400">
                Horário de Brasília
            </div>
        </div>
    )

    const getAllServicesDueDateField = () => {

        const component = {
            internal: () => (
                <Grid item>
                    <FormField
                        {...fieldProps}
                        type='datetime' required
                        name="allServicesDueDate"
                        field="Entrega de todos os serviços"
                        currentValue={allServicesDueDate}
                        onChange={(name, value) => editAllServicesDueDate(value, allServicesDueDate)}
                        disabled={loading}
                    />
                </Grid>
            ),
            customer: getCustomerAllServicesDueDate,
            customerUser: getCustomerAllServicesDueDate,
        }

        const componentToRender = component[loginService.getUserAuthentication().source]

        return componentToRender ? componentToRender() : null;
    }

    const getAdvanceButton = () => {

        const canAdvance = ![stepsSolicitation.CONCLUDED, stepsSolicitation.REVOKED].includes(request.status);

        if (!canAdvance)
            return null;

        return (
            <div>
                <AdvanceDueDate mainRequestId={mainRequestId}
                    request={request} onRefreshRequest={refresh} />
            </div>
        )
    }

    if (!allServicesDueDate)
        return null;

    return (
        <div className="flex gap-2 text-base  text-gray-500">
            <Clock className="!h-4 !w-4 my-1 shrink-0" />

            <div className="flex flex-wrap gap-2">
                {request.status === stepsSolicitation.CONCLUDED && request.concludedAt ? (
                    <div className="flex items-center gap-2 flex-wrap">
                        <span>Entregue em:</span>
                        <span>
                            {moment(request.concludedAt).format("DD [de] MMMM [de] YYYY [às] HH:mm")}
                        </span>
                    </div>
                ) : getAllServicesDueDateField()}
                {getAdvanceButton()}
            </div>

        </div>
    )
}