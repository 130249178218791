import { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined, Publish } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

import SimpleModal from "../../../shared/SimpleModal";
import Button from "../../../shared/Button";
import Description from "../../../shared/Description";
import SnackbarAlert from "../../../shared/SnackbarAlert";

import { useBlog } from "../../../../context/marketing/blogContext";
import useRequest from "../../../../hooks/useRequest";
import datetimeProvider from "../../../../providers/datetime.provider";

import BlogsService from "../../../../domain/services/marketing/blog.service";

import { blogStatus } from "../../../../domain/constant/marketing/blog.constant";
import { appConstants } from "../../../../domain/constant/app.constant";

export default function PublishArticleButton({
    blogData = {},
}) {

    const { refreshBlog } = useBlog();

    const [editBlog, loading, err] = useRequest(BlogsService.edit);

    const [open, setOpen] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const isPublished = blogData.published && blogData.status === blogStatus.published.name;

    useEffect(() => {

        if (!open && showSuccess)
            setInterval(() => setShowSuccess(false), 200);

    }, [open]);

    const requiredFieldsToPublish = [
        {
            label: 'Título',
            value: blogData.title,
        },
        {
            label: 'Slug',
            value: blogData.slug,
        },
        {
            label: 'Conteúdo do Artigo',
            value: blogData.content,
        },
        {
            label: 'Meta Descrição',
            value: blogData.seoInformation?.metaDescription,
        },
        {
            label: 'Imagem de Capa',
            value: blogData.coverImage?.url,
        }
    ];

    const getMissingFieldsToPublish = () => {
        return requiredFieldsToPublish.filter(field => !field.value);
    }

    const handlePublish = () => {
        editBlog(blogData.id, {
            status: blogStatus.published.name,
            published: datetimeProvider.getDate().format('YYYY-MM-DD HH:mm')
        })
            .then(() => {
                SnackbarAlert.success('Artigo publicado com sucesso!', 5000);
                setShowSuccess(true);
                refreshBlog();
            })
            .catch(() => { })
    }

    const handleUnpublish = () => {
        editBlog(blogData.id, {
            status: blogStatus.inactive.name,
            published: null,
        })
            .then(() => {
                SnackbarAlert.success('Artigo publicado com sucesso!', 5000);
                setOpen(false);
                refreshBlog();
            })
            .catch(() => { })
    }

    const renderNotPublishedInformation = () => (
        <>
            <div className="text-sm">
                Você está prestes a publicar o seu artigo. Confira algumas informações antes de publicar:
            </div>
            <div className="text-sm mt-6 flex flex-col gap-2">
                <Description title="Título">
                    {blogData.title || '--'}
                </Description>
                <Description title="Slug">
                    {blogData.slug || '--'}
                </Description>
                <Description title="Meta Título">
                    {blogData.seoInformation?.metaTitle || '--'}
                </Description>
                <Description title="Meta Descrição">
                    {blogData.seoInformation?.metaDescription || '--'}
                </Description>
            </div>

            {err && (
                <Alert
                    severity="error"
                    variant="outlined"
                    className="mt-6 !bg-[#fff2f0]"
                >
                    Ocorreu um problema ao publicar o seu artigo, por favor tente novamente.
                </Alert>
            )}

            {!!getMissingFieldsToPublish().length && (
                <Alert
                    severity="warning"
                    variant="outlined"
                    className="mt-6 !bg-[#fff2f0]"
                >
                    <div className="font-semibold">
                        Alguns campos obrigatórios estão faltando para a publicação do artigo.
                    </div>
                    <div className="mt-2 font-semibold">
                        Campos faltantes:
                    </div>
                    <div className="mt-2 flex flex-col gap-2">
                        {getMissingFieldsToPublish().map(field => (
                            <div>
                                {field.label}
                            </div>
                        ))}
                    </div>
                </Alert>
            )}

            <div className="mt-6 flex justify-end gap-2">
                <Button type="secondary" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>
                <Button
                    type="primary"
                    onClick={handlePublish}
                    loading={loading}
                    disabled={!!getMissingFieldsToPublish().length}
                >
                    Publicar
                </Button>

            </div>
        </>
    )

    const renderPublishedInformation = () => (
        <>
            <div className="text-sm">
                Seu artigo já foi publicado. Deseja despublicá-lo?
            </div>
            <div className="mt-4 text-sm">
                O artigo não será apagado, apenas será removido da listagem no Blog Institucional.
            </div>
            <div className="mt-4 text-sm">
                Você poderá publicá-lo novamente, caso deseje.
            </div>
            <div className="mt-6 flex justify-end gap-2">
                <Button type="secondary" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>
                <Button
                    type="primary"
                    color="danger"
                    onClick={handleUnpublish}
                    loading={loading}
                >
                    Despublicar Artigo
                </Button>
            </div>
        </>
    )

    const renderSuccessPublicationInformation = () => (
        <>
            <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2 text-green-600">
                    <CheckOutlined />
                    <span className="font-medium">O artigo foi publicado com sucesso!</span>
                </div>

                <div className="text-sm text-gray-600">
                    Fazemos atualizações periódicas na listagem de artigos no blog, por isso pode levar alguns minutos para o seu artigo aparecer na listagem pública.
                </div>

                <a
                    href={`${appConstants.institucionalUrl}/blog/${blogData.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-blue-600 hover:text-blue-700 underline mt-2"
                >
                    Visualizar artigo publicado
                </a>
            </div>

            <div className="mt-6 flex justify-end">
                <Button type="primary" onClick={() => setOpen(false)}>
                    Fechar
                </Button>
            </div>
        </>
    )

    const getModalTitle = () => {

        if (showSuccess)
            return "Artigo Publicado";

        return isPublished ? "Despublicar Artigo" : "Publicar Artigo";
    }

    return (
        <>
            <Button
                icon={isPublished ? <CloseOutlined /> : <Publish />}
                className="!py-2"
                onClick={() => setOpen(true)}
                color={isPublished ? 'danger' : 'default'}
            >
                <span className="hidden md:inline">
                    {isPublished ? 'Despublicar Artigo' : 'Publicar Artigo'}
                </span>
            </Button>

            <SimpleModal
                title={getModalTitle()}
                open={open}
                maxWidth="sm"
                onClose={() => setOpen(false)}
            >
                {showSuccess
                    ? renderSuccessPublicationInformation()
                    : isPublished
                        ? renderPublishedInformation()
                        : renderNotPublishedInformation()
                }
            </SimpleModal>
        </>
    );
}