
import { Link } from "react-router-dom";
import BlogButton from "../blogButton";

export default function BlogHeaderCta({
    title = 'Um texto para incentivar o cliente a clicar nesse CTA',
    buttonLabel = 'Texto do botão',
    buttonUrl = '',
}) {

    const renderButton = () => (
        <BlogButton>
            {buttonLabel}
        </BlogButton>
    )

    return (
        <div className="w-full max-w-3xl">
            <div className="w-full h-[1px] bg-[#dedede]" />
            <div className="py-5 flex flex-col text-base text-center md:text-start md:flex-row items-center justify-between gap-4">
                <div className="w-full opacity-75">
                    {title}
                </div>
                <div className="shrink-0 md:max-w-[50%] min-w-[220px]">
                    {!buttonUrl ? renderButton() : (
                        <Link to={buttonUrl}>
                            {renderButton()}
                        </Link>
                    )}
                </div>
            </div>
            <div className="w-full h-[1px] bg-[#dedede]" />
        </div>
    )
}