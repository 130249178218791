
import Async from "../../components/Async";

const BannerFacilitaFriday = Async(() => import('../../pages/marketing/banner.facilita.friday.page'));

const routes = {
    wall: {
        id: 'advertising',
        name: 'Mural de propaganda',
        path: '/advertising',
        component: BannerFacilitaFriday
    },
}

const pages = Object.values(routes);


export default {
    routes,
    pages
}