import Quill from 'quill';
const Block = Quill.import('blots/block');

class HeaderBlot extends Block {
    static create(value) {
        const node = super.create(value);
        node.setAttribute('class', `quill-header-${value}`);

        const headerStyles = {
            1: {
                marginTop: '2rem',
                marginBottom: '1.5rem'
            },
            2: {
                marginTop: '1.5rem',
                marginBottom: '1.25rem'
            },
            3: {
                marginTop: '1.25rem',
                marginBottom: '1rem'
            },
            default: {
                marginTop: '1.5rem',
                marginBottom: '1rem'
            }
        };

        const style = headerStyles[value];

        if (!style)
            return node;

        node.style.marginTop = style.marginTop;
        node.style.marginBottom = style.marginBottom;

        return node;
    }

    static formats(node) {
        return this.tagName.indexOf(node.tagName) + 1;
    }
}

HeaderBlot.blotName = 'header';
HeaderBlot.tagName = ['H1', 'H2', 'H3'];

export default HeaderBlot; 