import { useEffect, useState } from "react";

export default function Input({
    id,
    value,
    onChange = () => { },
    placeholder,
    error,
    prefix,
    suffix,
    className = '',
    name = null,
    type,
    onBlur,
}) {

    const [currentValue, setCurrentValue] = useState('');

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const handleChange = (e) => {
        onChange(e.target.value, e);
        setCurrentValue(e.target.value);
    };

    return (
        <div className="relative">
            <div
                className={`
                    w-full relative text-sm h-8 bg-white rounded border hover:border-primary focus-within:border-primary bg-transparent flex items-center overflow-hidden
                    ${!!error ? '!border-red-500' : 'border-[#ededed]'}
                    ${className}
                `}
                style={{
                    transition: 'border-color .3s ease',
                }}
            >
                {prefix && (
                    <div className="absolute left-3 w-5 z-[1]">
                        {prefix}
                    </div>
                )}

                <div className="relative w-full h-full">
                    <input
                        id={id}
                        className={`
                            text-inherit h-full w-full focus:ring-0 border-none bg-transparent px-0 outline-none pr-3
                            ${prefix ? 'pl-10' : 'pl-3'}
                        `}
                        value={currentValue || ''}
                        onChange={handleChange}
                        name={name}
                        type={type}
                        onBlur={onBlur}
                    />

                    {!currentValue && (
                        <div
                            className={`
                                absolute left-0 top-1/2 -translate-y-1/2 max-w-full text-nowrap overflow-hidden overflow-ellipsis text-[#0006] pointer-events-none
                                ${prefix ? 'pl-10' : 'pl-3'}
                            `}
                        >
                            {placeholder}
                        </div>
                    )}
                </div>

                {suffix && (
                    <div className="w-6 h-6 my-1 ml-2">
                        {suffix}
                    </div>
                )}

            </div>
            <div
                className={`absolute top-full h-0 text-xs text-red-500 ${!!error ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-1'}`}
                style={{ transition: 'opacity .3s ease, transform .3s ease' }}
            >
                {error}
            </div>
        </div>
    );
}