import { Clock, Edit, FileText, HelpCircle, Mic, Send } from "react-feather";
import Textarea from "../../shared/Textarea";
import Button from "../../shared/Button";
import GenericAudioRecorder from "../../shared/GenericAudioRecorder";
import documentsService from "../../../domain/services/solicitation/documents.service";
import useRequest from "../../../hooks/useRequest";
import { useEffect, useRef, useState } from "react";
import SnackbarAlert from "../../shared/SnackbarAlert";
import { AttachFile, Close } from "@material-ui/icons";
import Tooltip from "../../shared/Tooltip";
import LoadingSpin from "../../LoadingSpin";
import requestCorrectionService from "../../../domain/services/request/request.correction.service";
import requestStatusService from "../../../domain/services/solicitation/request.status.service";
import stepsSolicitation from "../../../domain/constant/steps.solicitation.constant";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import SkeletonLoading from "../../SkeletonLoading";
import loginService from "../../../domain/services/login.service";

export default function CorrectionForm({
    onCancel,
    requestId,
    request,
    correctionSource,
    showHeader = true,
}) {

    const isFaciliter = loginService.isFaciliter();

    const history = useHistory();
    const documentRef = useRef();

    const [saveAudio, isSavingAudio] = useRequest(documentsService.saveAll);
    const [deleteAudio, isDeletingAudio] = useRequest(documentsService.remove);
    const [getCorrectionFields, isGettingCorrectionFields] = useRequest(requestCorrectionService.findAllCorrectionFields);
    const [changeStep, isChangingStep] = useRequest(requestStatusService.changeStep);
    const [findCorrection, isLoading] = useRequest(requestCorrectionService.findById);
    const [transcribeAudioDocument, isTranscribingAudioDocument] = useRequest(documentsService.transcribeAudioDocument);
    const [checkAvailability, isCheckingAvailability] = useRequest(requestCorrectionService.checkCorrectionAvailability);

    const [saveDocuments, isSavingDocuments] = useRequest(documentsService.saveAll);
    const [checkUpload, isCheckingUpload] = useRequest(documentsService.checkUpload);
    const [deleteDocument] = useRequest(documentsService.remove);
    const [correctionFields, setCorrectionFields] = useState([]);

    const [correctionText, setCorrectionText] = useState({ value: '' });
    const [audio, setAudio] = useState({ value: null });
    const [tempAudio, setTempAudio] = useState({ blob: null, url: null });
    const [documents, setDocuments] = useState([]);
    const [tempDocuments, setTempDocuments] = useState([]);
    const [removingId, setRemovingId] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const isReadOnly = requestCorrectionService.checkIsReadOnly({ isReadOnly: !!correctionSource }, request.status);

    const loadingData = isLoading || isGettingCorrectionFields;

    useEffect(() => {
        const legacyFields = false;

        getCorrectionFields(legacyFields)
            .then(fields => setCorrectionFields(fields))
            .catch(() => SnackbarAlert.warning('Ocorreu um problema ao encontrar os detalhes da correção. Por favor, recarregue a página.'));

        if (!correctionSource)
            return;

        findCorrection(requestId, correctionSource)
            .then(res => parseExistingCorrection(res.data))
            .catch(() => {
                SnackbarAlert.warning('Ocorreu um problema ao encontrar os detalhes da correção. Por favor, tente novamente.');

                if (isFaciliter)
                    return;

                redirectToRequest();
            });
    }, [])

    const redirectToRequest = () => history.push(`/solicitations/${requestId}`);

    const parseExistingCorrection = (correction) => {
        const { fields = [] } = correction;

        const docs = [];

        const actionByType = {
            generalPoints: field => setCorrectionText({ value: field.value || '', id: field.id }),
            audio: field => setAudio({ value: field.document, id: field.id }),
            documents: field => docs.push({ ...field, value: field.document }),
        };

        for (const field of fields) {
            const action = actionByType[field.key];

            if (!action)
                continue;

            action(field);
        }

        setDocuments(docs);
    }

    const handleAudioRecording = async (audioBlob) => {
        audioBlob.name = `correction-${requestId}.wav`;

        const tempUrl = URL.createObjectURL(audioBlob);
        setTempAudio({ blob: audioBlob, url: tempUrl });
    }

    const removeAudio = async () => {
        if (tempAudio.blob) {
            URL.revokeObjectURL(tempAudio.url);
            setTempAudio({ blob: null, url: null });
            return;
        }

        deleteAudio({ requestId, id: audio.value.id })
            .then(() => setAudio(prev => ({ ...prev, value: null })))
            .catch(() => SnackbarAlert.error("Ocorreu um problema ao remover o áudio. Por favor, tente novamente."));
    }

    const handleRemoveAudio = async () => {

        if (!correctionSource) {
            await removeAudio();
            return;
        }

        Swal.fire({
            title: `Remover áudio?`,
            html: `
                Tem certeza que deseja remover o áudio? Após a remoção, não será possível recuperá-lo.
            `,
            icon: 'warning',
            confirmButtonText: 'Remover',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            reverseButtons: true,
        })
            .then(async result => {
                if (!result.isConfirmed)
                    return;

                await removeAudio();
            })
    }

    const handleDocumentsUpload = (files) => {
        const arrayOfFiles = [...Array.from(files)];

        const tempDocs = arrayOfFiles.map(file => ({
            value: {
                id: Math.random().toString(36).substr(2, 9),
                name: file.name,
                url: URL.createObjectURL(file)
            },
            file: file,
            isTemp: true
        }));

        setTempDocuments(prev => [...prev, ...tempDocs]);
        SnackbarAlert.success('Documentos carregados com sucesso');
    }

    const updateDocumentState = (index, value) => {
        setDocuments(prev => ([
            ...prev.slice(0, index),
            {
                ...prev[index],
                ...value,
            },
            ...prev.slice(index + 1, prev.length)
        ]));
    }

    const removeDocument = (doc, index) => {
        if (doc.isTemp) {
            setTempDocuments(prev => ([
                ...prev.slice(0, index),
                ...prev.slice(index + 1, prev.length)
            ]));
            return;
        }

        setRemovingId(doc.value.id);
        deleteDocument({ requestId, id: doc.value.id })
            .then(() => {
                SnackbarAlert.success('Documento removido com sucesso')

                if (doc.id) {
                    updateDocumentState(index, { removed: true });
                    return;
                }

                setDocuments(prev => ([
                    ...prev.slice(0, index),
                    ...prev.slice(index + 1, prev.length)
                ]));
            })
            .catch(() => SnackbarAlert.warning('Ocorreu um problema ao remover o seu documentos. Por favor, tente novamente'))
            .finally(() => setRemovingId(null))
    }

    const handleRemoveDocument = (doc, index) => {
        if (!!removingId) {
            SnackbarAlert.warning('Espere a remoção do outro documento finalizar')
            return;
        }

        if (!correctionSource) {
            removeDocument(doc, index);
            return;
        }

        Swal.fire({
            title: `Remover Documento?`,
            html: `
                Tem certeza que deseja remover o documento? Após a remoção, não será possível recuperá-lo.
            `,
            icon: 'warning',
            confirmButtonText: 'Remover',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            reverseButtons: true,
        })
            .then(async result => {
                if (!result.isConfirmed)
                    return;

                removeDocument(doc, index);
            })
    }

    const sendToCorrectionStep = async () => {
        await changeStep({ stepName: stepsSolicitation.CORRECTION, requestId: request.id })
            .then(() => {
                SnackbarAlert.success("Salvo com sucesso");
                redirectToRequest();
            })
            .catch(err => SnackbarAlert.error('Ocorreu um problema ao alterar o status da solicitação. Por favor, tente novamente.'));
    }

    const showNotAvailableMessage = (correctionExpirationDate) => {
        Swal.fire({
            title: '⚠️ Atenção! ⚠️',
            text: `
                    Infelizmente, o período máximo de pedido de correção expirou ${correctionExpirationDate ? `no dia ${correctionExpirationDate}` : ''}  e não é mais possível pedir uma correção.
                    
                    Estamos aqui para ajudá-lo. Se precisar de qualquer assistência, basta entrar em contato com o suporte e nossa equipe estará pronta para garantir que você tenha uma experiência incrível conosco.

                Agradecemos sua compreensão e estamos ansiosos para continuar a atendê-lo! 😊
                            `,
            icon: false,
            confirmButtonText: 'Tudo bem',
            showCancelButton: false,
        });
    }

    const validateBody = (body) => {
        const values = Object.values(body);

        for (const value of values) {
            const hasEntries = Array.isArray(value) && !!value.length;
            const hasValue = !Array.isArray(value) && value?.value;

            if (!hasEntries || !hasValue)
                return true;
        }

        return false;
    }

    const formatDocToUpload = (files = []) => {
        const docsToUpload = files.map(file => Object.assign(file, { isVisibleCustomer: true }));
        return docsToUpload;
    }

    const isCorrectionAvailable = () =>
        checkAvailability(request.id)
            .then(() => ({ isAvailable: true }))
            .catch(err => ({ isAvailable: false, correctionExpirationDate: err?.response?.data?.correctionExpirationDate }));

    const handleSave = async () => {

        const { isAvailable, correctionExpirationDate } = await isCorrectionAvailable(request.id)

        if (!isAvailable) {
            showNotAvailableMessage(correctionExpirationDate);
            return;
        }

        const body = {
            generalPoints: { value: correctionText.value, id: correctionText.id },
            audio: { value: audio.value?.id, id: audio.id },
            documents: documents
                .map(doc => ({ value: doc.removed ? null : doc.value.id, id: doc.id })),
        };

        const isValid = validateBody(body);

        if (!isValid) {
            SnackbarAlert.warning('Por favor, forneça alguma informação para a correção');
            return;
        }

        setIsSaving(true);

        try {
            if (tempAudio.blob) {
                const docsToUpload = formatDocToUpload([tempAudio.blob]);
                const uploadedAudio = await saveAudio(requestId, docsToUpload, false);
                await checkUpload(requestId, (uploadedAudio || []).map(doc => doc.document.id), false);
                const audioDoc = uploadedAudio[0].document;
                body.audio = { value: audioDoc.id };

                try {
                    await transcribeAudioDocument(requestId, audioDoc.id);
                } catch (error) {
                    console.error('Error transcribing audio:', error);
                }
            }

            if (tempDocuments.length > 0) {
                const docsToUpload = formatDocToUpload(tempDocuments.map(doc => doc.file));
                const uploadedDocs = await saveDocuments(requestId, docsToUpload, false);
                await checkUpload(requestId, (uploadedDocs || []).map(doc => doc.document.id), false);
                const newDocs = uploadedDocs.map(doc => ({ value: doc.document }));
                body.documents = [...body.documents, ...newDocs.map(doc => ({ value: doc.value.id }))];
            }

            const saveInfo = {
                request,
                isCreating: !correctionSource,
                mainRequestId: requestId,
            }

            const saveOrUpdate = requestCorrectionService.returnActionToSaveOrUpdateCorrection(body, saveInfo, correctionFields, { correctionSource });

            await saveOrUpdate();
            await sendToCorrectionStep();
        } catch (error) {
            SnackbarAlert.error('Ocorreu um problema ao atualizar sua correção. Por favor, tente novamente.')
        } finally {
            setIsSaving(false);
        }
    }

    const getLabel = (file) => {

        if (file.removed)
            return 'Documento Removido';

        if (isReadOnly || file.id)
            return 'Clique para visualizar'

        return 'Documento Carregado com Sucesso';
    }

    return (
        <div className="font-['SF_Pro']">
            {showHeader && (
                <div class="mb-6">
                    <h1 class="text-2xl font-semibold flex items-center gap-2 mb-2">
                        <Edit className="text-blue-600 !h-6 !w-6" />
                        Correção Rápida
                    </h1>
                    <p class="text-gray-600">Como podemos melhorar seu documento?</p>
                </div>
            )}

            {!isReadOnly && (
                <div class="bg-blue-50 rounded-lg p-4 mb-8">
                    <div class="flex items-center gap-2 text-blue-600 font-medium mb-1">
                        <Clock className="!w-5 !h-5" />Entrega em 24 horas</div>
                    <p class="text-blue-600">Precisa com urgência? Use nosso chat de suporte</p>
                </div>
            )}

            <div class="mb-8">
                <div class="text-lg font-medium flex items-center gap-2">
                    Quais pontos precisam ser ajustados?
                    <Tooltip title="Use este campo para solicitar ajustes no documento recebido ou para esclarecer suas dúvidas.">
                        <HelpCircle size={16} className="translate-y-[2px]" />
                    </Tooltip>
                </div>
                {!correctionText.value && !!audio?.value?.transcription && (
                    <div className="text-sm text-gray-500 mt-1">O texto a seguir é uma transcrição do áudio gravado pelo cliente</div>
                )}
                <div className="mt-3">
                    <SkeletonLoading size={{ height: 120 }} isDataLoading={loadingData}>
                        <Textarea
                            className="text-base"
                            rows={5}
                            placeholder={(
                                <span className="text-base">
                                    Exemplo: Na página 2, onde está escrito 'valor de R$ 1.000', precisa ser corrigido para 'R$ 1.500'
                                </span>
                            )}
                            onChange={value => setCorrectionText(prev => ({ ...prev, value }))}
                            value={correctionText.value || audio?.value?.transcription}
                            disabled={isReadOnly}
                        />
                    </SkeletonLoading>
                </div>
            </div>

            <div class="mb-8">
                <h2 class="text-lg font-medium mb-3">Prefere explicar por áudio? Grave sua mensagem:</h2>
                <SkeletonLoading size={{ height: 40 }} isDataLoading={loadingData}>
                    {isReadOnly && !audio.value?.url && !tempAudio.url ? (
                        <div className="text-gray-500">
                            Não há áudios gravados para essa correção.
                        </div>
                    ) : (
                        <>
                            <GenericAudioRecorder
                                maxDuration={180}
                                onStop={handleAudioRecording}
                                onDelete={handleRemoveAudio}
                                loading={isDeletingAudio}
                                url={tempAudio.url || audio?.value?.url}
                                allowClear={!isReadOnly}
                            />
                            {(tempAudio.blob || (correctionText.value && audio?.value?.transcription)) && (
                                <div className="mt-3 text-sm text-gray-600 flex items-center gap-2">
                                    <Mic className="!w-4 !h-4" />
                                    {audio?.value?.transcription ? (
                                        <span>
                                            <b>Transcrição:</b> {audio.value.transcription}
                                        </span>
                                    ) : (
                                        <span>
                                            Uma transcrição do áudio estará disponível para visualização após salvar os dados da correção
                                        </span>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </SkeletonLoading>
            </div>

            <div class="mb-8">
                <h2 class="text-lg font-medium mb-3">Tem documentos complementares para adicionar?</h2>

                <SkeletonLoading size={{ height: 40 }} isDataLoading={loadingData}>
                    <div class="flex gap-6">
                        {!isReadOnly && (
                            <div className="shrink-0">
                                <label for="upload-documents" className="hidden" ref={documentRef} />
                                <Button
                                    type="secondary"
                                    icon={<FileText className="!w-5 !h-5" />}
                                    className="!text-primary"
                                    loading={isSavingDocuments}
                                    onClick={() => documentRef.current && documentRef.current.click()}
                                >
                                    <span>
                                        Adicionar documentos
                                    </span>
                                </Button>
                                <input
                                    id="upload-documents"
                                    className='hidden'
                                    type="file"
                                    onChange={e => handleDocumentsUpload(e.target.files)}
                                    multiple
                                />
                            </div>
                        )}

                        <div className="flex flex-col gap-4 w-full max-w-96">
                            {!documents.length && !tempDocuments.length ? (
                                <div className="flex items-center h-9">
                                    <span class="text-gray-500 text-sm">
                                        {isSavingDocuments ? 'Carregando documentos...' : 'Documentos anexados aparecerão aqui'}
                                    </span>
                                </div>
                            ) : [...documents, ...tempDocuments].map((doc, index) => (
                                <div key={doc.value.id}>
                                    <div className="flex gap-4">
                                        <div
                                            className="w-full flex gap-4 hover:cursor-pointer group"
                                            onClick={() => !doc.removed ? (doc.isTemp ? window.open(doc.value.url) : documentsService.downloadFile(doc.value.url, doc.value.name)) : undefined}
                                        >
                                            <div className="rounded-lg border border-[#dedede] bg-white group-hover:bg-gray-100 h-10 w-10 flex justify-center items-center shrink-0 transition-colors">
                                                <AttachFile className="text-primary" />
                                            </div>
                                            <div className="w-full">
                                                <div className="font-semibold text-sm group-hover:text-primary transition-colors">{doc.value.name}</div>
                                                <div className="text-[#0007] text-sm">{getLabel(doc)}</div>
                                            </div>
                                        </div>
                                        {!isReadOnly && (
                                            <Tooltip title="Remover Documento">
                                                <div>
                                                    <Button
                                                        type="text"
                                                        className="!p-1"
                                                        onClick={() => handleRemoveDocument(doc, index)}
                                                    >
                                                        {removingId === doc.value.id ? <LoadingSpin color="#0009" /> : <Close className="!text-base text-[#0009]" />}
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </SkeletonLoading>
            </div>


            {!isReadOnly && (
                <div class="bg-gray-50 rounded-lg p-4 mb-8">
                    <p class="text-gray-600">
                        💡 Dica: Seja específico sobre o que precisa ser corrigido e adicione documentos complementares, se necessário. Isso nos ajuda a entregar exatamente o que você precisa.
                    </p>
                </div>
            )}

            <div class="flex justify-end gap-4">

                {onCancel && (
                    <Button type="text" onClick={correctionSource ? redirectToRequest : onCancel}>
                        {isReadOnly ? 'Voltar' : 'Cancelar'}
                    </Button>
                )}

                {!isReadOnly && (
                    <Button
                        type="primary"
                        icon={<Send className="!h-4 !w-4" />}
                        onClick={handleSave}
                        loading={isSaving || isChangingStep || isSavingAudio || isSavingDocuments || isCheckingAvailability}
                        disabled={loadingData}
                    >
                        {correctionSource ? 'Atualizar' : 'Enviar'} Correção
                    </Button>
                )}
            </div>
        </div>
    )
}