import Quill from 'quill';
const Inline = Quill.import('blots/inline');

class LinkBlot extends Inline {
    static create(value) {
        const node = super.create();
        node.setAttribute('href', value);
        node.setAttribute('style', 'color: #2563eb; text-decoration: none !important');
        return node;
    }

    static formats(node) {
        const currentStyle = node.getAttribute('style');
        if (!currentStyle || !currentStyle.includes('color: #2563eb')) {
            node.setAttribute('style', 'color: #2563eb; text-decoration: none !important');
        }
        return node.getAttribute('href');
    }
}

LinkBlot.blotName = 'link';
LinkBlot.tagName = 'a';

export default LinkBlot; 