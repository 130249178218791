import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box,
    makeStyles
} from '@material-ui/core';
import { WarningOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    dialog: {
        borderRadius: 16,
        padding: theme.spacing(2),
    },
    content: {
        padding: theme.spacing(4),
        textAlign: 'center',
    },
    iconContainer: {
        width: 80,
        height: 80,
        borderRadius: '50%',
        backgroundColor: '#FFF5F5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto 24px',
    },
    icon: {
        fontSize: 40,
        color: '#E53935',
    },
    title: {
        color: '#1A1A1A',
        marginBottom: 32,
        fontWeight: 600,
    },
    message: {
        color: '#4A4A4A',
        marginBottom: 8,
    },
    subMessage: {
        marginTop: 32,
        color: '#666666',
    },
    actions: {
        padding: '16px 24px',
        gap: 12,
    },
    button: {
        borderRadius: 8,
        textTransform: 'none',
        padding: '8px 24px',
        '&.primary': {
            backgroundColor: '#376fd0',
            '&:hover': {
                backgroundColor: '#2B5DB3',
            },
        },
        '&.secondary': {
            borderColor: '#376fd0',
            color: '#376fd0',
            '&:hover': {
                backgroundColor: '#F5F8FF',
            },
        },
    }
}));

const InvalidDocumentModal = ({ open, onClose, onContactSupport }) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                className: classes.dialog
            }}
        >
            <DialogContent className={classes.content}>
                <Box className={classes.iconContainer}>
                    <WarningOutlined className={classes.icon} />
                </Box>

                <Typography variant="h5" className={classes.title}>
                    Documento inválido
                </Typography>

                <Typography variant="body1" className={classes.message}>
                    Identificamos que não houveram alterações suficientes no documento anexado comparado ao documento original. Valide se todos os elementos necessários foram incluídos.
                </Typography>

                <Typography variant="body2" className={classes.subMessage}>
                    Após validar, anexe o documento novamente! Se o documento atual estiver correto, entre em contato com nosso suporte para concluir o serviço.
                </Typography>
            </DialogContent>

            <DialogActions className={classes.actions}>
                <Button
                    onClick={onContactSupport}
                    variant="outlined"
                    className={`${classes.button} secondary`}
                >
                    Falar com o suporte
                </Button>
                <Button
                    onClick={onClose}
                    variant="contained"
                    className={`${classes.button} primary `}
                    style={{ color: '#FFFFFF' }}
                >
                    Anexar outro documento
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvalidDocumentModal;