import api from './api';
import documentProvider from '../../providers/document.provider';

const find = async (id) =>
    api.get({ type: 'user', service: `users/${id}` });

const findAll = async () =>
    api.get({ type: 'user', service: `users/` });

const findRoles = async (source) =>
    api.get({ type: 'user', service: `users/roles/source/${source}` });

const findByRoles = async (data) =>
    api.post({ type: 'user', service: 'users/roles-filter', data });

const save = async (data) =>
    api.post({ type: 'user', service: `users/`, data });

const addWebPushSubscription = async (id, subscription) =>
    api.post({ type: "user", service: `users/${id}/web-push-subscription`, data: { subscription } });

const findSubscriptionEndpoint = async (id, subscriptionEndpoint) =>
    api.post({ type: "user", service: `users/${id}/push-subscription-endpoint`, data: { endpoint: subscriptionEndpoint } });

const editWebPushSubscription = async (userId, subscriptionEndpoint, data) =>
    api.put({ type: "user", service: `users/${userId}/web-push-subscription`, data: { endpoint: subscriptionEndpoint, fields: data } });

const remove = async (id) =>
    api.remove({ type: 'user', service: `users/${id}` });

const saveProfileInfo = async (id, data) => {

    const pictureChanges = await checkToUploadPicture(id, data.picture);
    const profileData = { ...data, ...pictureChanges };

    await editProfileInfo(id, profileData);

    return profileData;
}

const checkToUploadPicture = async (id, profilePicture) => {

    if (!profilePicture || profilePicture.url)
        return { picture: profilePicture };

    const picture = await uploadProfilePicture(id, profilePicture);
    return { picture };
}

const uploadProfilePicture = async (id, file) => {

    const document = documentProvider.getDocumentObject(file);
    const { data } = await api.post({ type: "user", service: `users/${id}/profile/picture`, data: document });

    const { document: uploadedPicture } = await documentProvider.upload(file, data);

    return uploadedPicture;
}

const editProfileInfo = async (id, data) =>
    api.put({ type: 'user', service: `users/${id}/profile`, data });

export default {
    find,
    findAll,
    findRoles,
    findByRoles,
    save,
    remove,
    addWebPushSubscription,
    editWebPushSubscription,
    findSubscriptionEndpoint,
    saveProfileInfo
}