import React, { useEffect, useRef, useState } from "react";
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

import swal from "sweetalert";
import swal2 from "sweetalert2";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Menu,
    MenuItem, Avatar, ListItemText, ListItemIcon, Typography, CircularProgress
} from "@material-ui/core";

import { KeyboardArrowDown } from "@material-ui/icons";

import { stepsSolicitation } from "../../domain/constant/steps.solicitation.constant";
import { requestServices } from "../../domain/constant/request.services.constant";

import requestValidator from "../../domain/validators/request.validator";
import communicationProvider from "../../providers/communication.provider";

import loginService from "../../domain/services/login.service";
import requestStatusService from "../../domain/services/solicitation/request.status.service";
import requestServicesService from "../../domain/services/solicitation/request.services.service";
import requestCorrectionService from "../../domain/services/request/request.correction.service";

import LoadingProgress from "../LoadingProgress";
import CustomerReturnSolicitation from "../solicitations/customer.return.solicitation.component";
import ChecklistRemember from "../solicitations/check-list/check.list.remember.component";
import RequestRatingForm from "../requests/rating/request.rating.form.component";
import AnalysisRatingForm from "../requests/rating/analysis.rating.form.component";
import InternalWorkingTime from "../solicitations/internal.working.time.component";
import LinkSettingsForm from "../solicitations/link.settings.form.component";
import PendingForm from "../requests/pending/pending.form.component";
import ChecklistConclude from "../solicitations/check-list/check.list.conclude.component";
import userConstant from "../../domain/constant/user.constant.js"

import RequestIaRatingDialog from "./rating/request.ia.rating.dialog.component.js"
import { useHistory } from "react-router-dom";

function StatusForm({ request, onRefreshRequest, setAlert }) {

    const history = useHistory();

    const [availableSteps, setAvailableSteps] = useState([]);
    const [step, setStep] = useState({});
    const [openCorrection, setOpenCorrection] = useState(false);

    const [openRating, setOpenRating] = useState(false);
    const [openAnalysisRating, setOpenAnalysisRating] = useState({ show: false });
    const [openLinkSettings, setOpenLinkSettings] = useState(false);
    const [workingTime, setWorkingTime] = useState({ show: false, isReviewer: false, isAnalyst: false });
    const [openRememberDialog, setOpenRememberDialog] = useState(false);
    const [openCheckListConclude, setOpenCheckListConclude] = useState({ show: false, action: 'conclude' });

    const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
    const [newStep, setNewStep] = useState({});
    const [userSource, setUserSource] = useState({})

    const [anchorMenu, setAnchorMenu] = useState(null);
    const open = Boolean(anchorMenu);

    const { promiseInProgress } = usePromiseTracker();

    const stepRequest = requestStatusService.getStepByKeyAndUserSource(request.status);

    const prevStatusRef = useRef();

    useEffect(() => {

        setUserSource(JSON.parse(localStorage.getItem('@facilita-app/user')).source);

        if (request.status != prevStatusRef.current) {
            getAvailableSteps(request.id);
            setStepByStatus(request.status);
        }

        prevStatusRef.current = request.status;

    }, [request.status]);

    const getAvailableSteps = async (requestId) => {

        if (!requestId) return;

        const { data } = await requestStatusService.getAvailableSteps(requestId);

        const steps = (data || []).map(item => {

            const stepValue = requestStatusService.getStepByKey(item.name);
            const disabledAction = (stepValue.disabledAction || {});

            return ({
                ...item,
                label: requestStatusService.getStepLabelBySource(item),
                icon: stepValue.icon,
                backgroundColor: stepValue.background,
                disabledAction: disabledAction[loginService.getUserAuthentication().source]
            });
        })

        setAvailableSteps(steps || []);
    }

    const setStepByStatus = (status) => {
        let item = requestStatusService.getStepByKey(status);
        setStep({ ...item, backgroundColor: item.background, label: item.value });
    }

    const onChangeFields = (field, value) => {
        step[field] = value;
        setStep({ ...step });
    }

    const handleOnfinish = () =>
        handleIaFeedback(newStep, true);

    const handleIaFeedback = (newStep, isFeedbackSave) => {

        if (!isFeedbackSave) {
            return false;
        }

        setOpenFeedbackModal(false)
        return handleSave(newStep)
    };

    const handleStepAction = (newStep) => {
        const needFaciliterFeedback = (newStep.name === stepsSolicitation.CONCLUDED || newStep.name === stepsSolicitation.SAMPLING) && request.generatedAutomaticPetitionAt && !request.isInCorrection && userSource === userConstant.rolesKeys.FACILITER;

        if (!needFaciliterFeedback) {
            return handleSave(newStep)
        }

        setNewStep(newStep)
        setOpenFeedbackModal(true)
        return handleIaFeedback(newStep)
    }

    const handleSave = async (newStep) => {

        handleClose();

        const stepChangeConfirmation = getLeavingStepConfirmation(step.key) || getNewStepConfirmation(newStep.name);

        if (!stepChangeConfirmation)
            return handleChange(newStep);

        return stepChangeConfirmation
            .action()
            .then(isConfirmed => isConfirmed && handleChange(newStep));
    }

    const handleChange = async (newStep, skipValidationsParams) => {

        const actionByStep = getActionByStep(newStep, step);

        if (actionByStep) {
            actionByStep.action();
            return;
        }

        if (step.position > newStep.position) {
            setStep({ ...step, isConfirm: true, new: { ...newStep } });
            return;
        }

        await changeStep({ stepName: newStep.name, requestId: request.id, ...skipValidationsParams });
    }

    const getNewStepConfirmation = (step) => ({
        REVOKED: {
            action: () => (
                swal({
                    title: "Arquivar solicitação",
                    text: `Ao confirmar esta ação, a solicitação será descontinuada e o saldo ficará disponível para uso. Deseja continuar?`,
                    icon: "warning",
                    buttons: {
                        cancel: 'Cancelar',
                        confirm: { text: 'Confirmar', className: 'MuiButton-containedPrimary' }
                    }
                }).then(res => !!res)
            )
        },
    })[step];

    const getLeavingStepConfirmation = (step) => ({
        REVOKED: {
            action: () => (
                swal({
                    title: "Desarquivar solicitação",
                    text: "Ao confirmar esta ação, a solicitação será cobrada novamente e voltará para produção. Deseja continuar?",
                    icon: "warning",
                    buttons: {
                        cancel: 'Cancelar',
                        confirm: { text: 'Confirmar', className: 'MuiButton-containedPrimary' },
                    }
                }).then(res => !!res)
            )
        },
    })[step];

    const redirectToCorrection = () => history.push(`/solicitations/${request.mainId || request.id}/correction`);

    const getActionByStep = (newStep, step) => {
        return {
            LINK: {
                action: () => setOpenLinkSettings(true)
            },
            PENDING: {
                action: () => setStep({ ...step, isPending: true, new: { ...newStep } })
            },
            REVIEW: {
                action: () => setReview()
            },
            SAMPLING: {
                action: () => setConcluded('sampling')
            },
            CONCLUDED: {
                action: () => setConcluded('conclude')
            },
            CORRECTION: {
                action: () => redirectToCorrection(),
            },
            REVOKED: {
                action: () => handleRevoke(request)
            },
        }[newStep.name];
    }

    const handleRevoke = async (request) => {

        try {
            await requestStatusService.revokeRequest(request.mainId || request.id);

            setAlert("Salvo com sucesso", "success")

            onRefreshRequest()
        }
        catch (error) {
            warningAlert(error?.response?.data?.message)
        }

    }

    const newCorrection = (newStep, step) =>
        requestCorrectionService
            .checkCorrectionAvailability(request.id)
            .then(() => {
                setOpenCorrection(true);
                setStep({ ...step, isCorrection: true, new: { ...newStep } })
            })
            .catch(err => communicationProvider.getErrorMessage(err, setAlert));

    const setReview = () => {

        const concludeData = { stepName: stepsSolicitation.REVIEW, requestId: request.id };

        if (!loginService.isFaciliter() || requestValidator.isAnalysisModality(request) || requestValidator.isCalculationModality(request.modalityId))
            return checkToShowAnalysisReview(() => changeStep(concludeData));

        setOpenRememberDialog(true);
    }

    const concludeMethodByAction = {
        sampling: (props) => concludeBySampling(props),
        conclude: (props) => changeStep(props),
    }

    const setConcluded = (actionType) => {

        const conclusionProps = { stepName: stepsSolicitation.CONCLUDED, requestId: request.id }
        const concludeAction = () => concludeMethodByAction[actionType](conclusionProps);

        const hasConclusionSteps = [requestServices.PETITION, requestServices.CALCULATION].includes(request.serviceKey);

        if (!hasConclusionSteps)
            return concludeAction();

        return setOpenCheckListConclude({ show: true, action: concludeAction, conclusionType: actionType });
    }

    const onSaveConcludeChecklist = async () => {

        if (!requestValidator.isPetitionService(request.serviceKey))
            return openCheckListConclude.action();

        if (request.status === stepsSolicitation.REVIEW && openCheckListConclude.conclusionType != 'sampling') {
            setWorkingTime({ show: true, isReviewer: true, isAnalyst: false, action: openCheckListConclude.action });
            return;
        }

        checkToShowAnalysisReview(openCheckListConclude.action)
    }

    const checkIfHasToReviewAnalysis = () =>
        requestValidator.isPetitionService(request.serviceKey)
        && request.analysisWasDevelopedByIa
        && ![stepsSolicitation.REVIEW, stepsSolicitation.WAITING_DELIVER].includes(request.status)
        && !request.additionalInformation?.numberOfCorrections;

    const checkToShowAnalysisReview = async (action) => {

        const hasToReviewAnalysis = checkIfHasToReviewAnalysis();

        if (!hasToReviewAnalysis)
            return action();

        setOpenAnalysisRating({ show: true, action });
    }

    const concludeBySampling = async (item) =>
        trackPromise(
            requestStatusService.concludeBySampling(item)
                .then(() => setStepByStatus(item.stepName))
                .then(() => getAvailableSteps(item.requestId))
                .then(onRefreshRequest)
                .then(() => setAlert("Salvo com sucesso", "success"))
                .then(() => requestServicesService.checkToRedirectService(item.stepName, request))
                .catch((err) => handleChangeError(err, item, concludeBySampling))
        );

    const changeStep = async (item) =>
        trackPromise(
            requestStatusService
                .changeStep(item)
                .then(checkNewAgendaRequests)
                .then(() => requestStatusService.checkFaciliterAccessLimit(item.stepName, request.faciliterReviewers))
                .then(() => setStepByStatus(item.stepName))
                .then(() => getAvailableSteps(item.requestId))
                .then(onRefreshRequest)
                .then(() => setAlert("Salvo com sucesso", "success"))
                .then(() => requestServicesService.checkToRedirectService(item.stepName, request))
                .catch((err) => handleChangeError(err, item, changeStep))
        );

    const handleChangeError = (error, selectedStep, callback) => {

        const skipValidationInfo = error?.response?.data?.skipValidationInfo;

        if (!skipValidationInfo) {
            warningAlert(error?.response?.data?.message);
            return;
        }

        swal2.fire({
            title: skipValidationInfo.title,
            html: `
                <p style="color: #000000DE; font-family: satoshi, sans-serif; font-size: 15; font-weight: 400;">
                    ${skipValidationInfo.message}
                </p>
            `,
            icon: 'warning',
            confirmButtonText: skipValidationInfo.confirmationButton || 'Confirmar',
            cancelButtonText: skipValidationInfo.cancelButtonText || 'Cancelar',
            showCancelButton: true,
        })
            .then(result => result.isConfirmed && callback({ ...selectedStep, [skipValidationInfo.key]: true }))
            .catch(err => setAlert((err?.response?.data?.message), 'error'));
    }

    const warningAlert = (message) => {
        swal2.fire({
            title: 'Atenção',
            text: message || 'Não foi possível atualizar a fase do serviço. Por favor contate o suporte',
            icon: 'warning',
            confirmButtonText: 'Entendi',
        });
    }

    const checkNewAgendaRequests = ({ data }) => {

        const newAgendaRequests = data?.newAgendaRequests || [];

        if (!newAgendaRequests.length)
            return;

        return swal2.fire({
            title: 'Novas vinculações na pauta',
            html: `
                <div style="text-align: left;">
                    <p>
                        O ID movido estava ativo na pauta, novos IDs foram vinculados para substituí-lo.
                    </p>
                    ${newAgendaRequests.map(req => `
                        <p>
                            Membro: ${req.responsibleName} <br>
                            Novo serviço vinculado: ID <b>${req.requestId}</b> - ${req.agendaName}
                        </p>
                    `)}
                </div>
            `,
            icon: 'success',
            confirmButtonText: 'Entendi',
        });
    }

    const finishReview = async () => {

        const action = workingTime.action;

        setWorkingTime({ show: false, isReviewer: false, isAnalyst: false });

        const numberOfCorrections = request.additionalInformation?.numberOfCorrections;

        if (!!numberOfCorrections)
            return action();

        return setOpenRating(true);
    }

    const getOptions = () => {

        let stepOptions = [...availableSteps];

        return stepOptions.map((item, i) => (
            !item.disabledAction &&
            <MenuItem
                key={i}
                onClick={() => { handleStepAction(item) }}
                disableRipple
                style={{ borderBottom: "1px solid #eee", margin: "5px" }}>
                <ListItemIcon style={{ minWidth: "48px" }}>
                    <Avatar style={{ backgroundColor: item.backgroundColor, width: "30px", height: "30px" }}>
                        {item.icon}
                    </Avatar>
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            style={{
                                whiteSpace: "nowrap", width: "18em", overflow: "hidden", textOverflow: "ellipsis",
                                font: "500 14px/24px Google Sans, -apple-system, BlinkMacSystemFont, sans-serif"
                            }}
                        >
                            {item.label}
                        </Typography>
                    }
                />
            </MenuItem>
        ))
    }

    const returnStepToCorrection = async () =>
        changeStep({ stepName: stepsSolicitation.CORRECTION, requestId: request.id })
            .then(() =>
                (request.members || []).some(i => i.isAssureIncome)
                && onRefreshRequest()
            );

    const cancelCorrection = () =>
        setStep({ ...step, isCorrection: false });

    const sendToPending = async () =>
        changeStep({ stepName: stepsSolicitation.PENDING, requestId: request.id });

    const cancelPending = () =>
        setStep({ ...step, isPending: false });

    const handleClose = () =>
        setAnchorMenu(null);

    const confirmDialog = () => {

        const close = () =>
            setStep({ ...step, isConfirm: false });

        const newValue = (step) =>
            ({ stepName: step.new.name, requestId: request.id, description: step.description })

        return <Dialog open={(step.isConfirm || false)} onClose={close}>

            <DialogTitle>Informe o motivo de mover para {(step.new || {}).label}</DialogTitle>

            <DialogContent>
                {promiseInProgress && <LoadingProgress />}

                {!promiseInProgress &&
                    <TextField id="description" label="Motivo" fullWidth
                        multiline={true} autoFocus margin="dense"
                        onChange={(e) => { onChangeFields('description', e.target.value); }} />
                }
            </DialogContent>

            <DialogActions>

                <Button onClick={close} color="primary" disabled={promiseInProgress}>
                    Cancelar
                </Button>

                <Button
                    onClick={() => changeStep(newValue(step))}
                    color="primary" disabled={promiseInProgress}
                >
                    Confirmar
                </Button>

            </DialogActions>
        </Dialog>
    }

    return (
        <React.Fragment>
            <div>

                <Button
                    aria-haspopup="true" fullWidth aria-expanded={open}
                    variant="contained" disableElevation
                    onClick={(event) => setAnchorMenu(event.currentTarget)}
                    endIcon={!!availableSteps.length && <KeyboardArrowDown />}
                    style={{
                        fontWeight: '600', color: 'white',
                        backgroundColor: stepRequest.background
                    }}
                >

                    {stepRequest.value}

                    {promiseInProgress && (
                        <CircularProgress style={{
                            color: "aliceblue", width: 14,
                            height: 14, marginLeft: 8
                        }} />
                    )}

                </Button>

                {!!availableSteps.length &&
                    <Menu
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        anchorEl={anchorMenu} open={open} onClose={handleClose}
                    >
                        {getOptions()}
                    </Menu>
                }

            </div>

            {step.isCorrection &&
                <CustomerReturnSolicitation
                    request={request}
                    mainRequestId={request?.mainId || request?.id}
                    open={openCorrection}
                    setOpen={setOpenCorrection}
                    returnStep={returnStepToCorrection}
                    cancelCorrection={cancelCorrection}
                    isCreating
                    setAlert={setAlert}
                />
            }

            {(step.isPending || false) &&
                <PendingForm status={request.status} requestId={request.id}
                    additionalInformation={request.additionalInformation}
                    onSave={sendToPending} cancelPending={cancelPending} />
            }

            {confirmDialog()}

            {workingTime.show &&
                <InternalWorkingTime
                    isReviewer={workingTime.isReviewer}
                    isAnalyst={workingTime.isAnalyst}
                    userId={loginService.getUserAuthentication().id}
                    requestId={request.id}
                    onSave={finishReview}
                    onCancel={() => setWorkingTime({ show: false, isReviewer: false, isAnalyst: false })}

                />
            }

            {openRating &&
                <RequestRatingForm
                    requestId={request.id}
                    onSave={changeStep}
                    onRefreshRequest={onRefreshRequest}
                    isModalOpen={openRating}
                    closeModal={() => setOpenRating(false)}
                    isInternal={loginService.isInternal()}
                    setAlertStatus={setAlert}
                />
            }

            {openAnalysisRating.show &&
                <AnalysisRatingForm
                    request={request}
                    onSave={openAnalysisRating.action}
                    onRefreshRequest={onRefreshRequest}
                    isModalOpen={openAnalysisRating.show}
                    closeModal={() => setOpenAnalysisRating({ show: false })}
                    setAlertStatus={setAlert}
                    showReviewForAllSituations={openAnalysisRating.show}
                />
            }

            {openLinkSettings &&
                <LinkSettingsForm
                    request={request}
                    onSave={changeStep}
                    onCancel={() => setOpenLinkSettings(false)}
                    onRefreshRequest={onRefreshRequest}
                    setAlert={setAlert}
                />
            }

            {openRememberDialog &&
                <ChecklistRemember
                    request={request}
                    onSave={(data) => checkToShowAnalysisReview(() => changeStep(data))}
                    onRefreshRequest={onRefreshRequest}
                    onCloseDialog={() => setOpenRememberDialog(false)}
                />
            }

            {openCheckListConclude.show &&
                <ChecklistConclude
                    request={request}
                    onSave={onSaveConcludeChecklist}
                    onCloseDialog={() => setOpenCheckListConclude({ show: false })}
                />
            }

            <RequestIaRatingDialog request={request} isOpen={openFeedbackModal} onFinish={handleOnfinish} />

        </React.Fragment>
    );
}

export default StatusForm;


