import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from 'query-string';

import { Box, Button, Grid, Typography } from "@material-ui/core";

import PendingSolveModal from "./pending.solve.modal.component";
import { useCustomerRequestList } from "../../../context/customerRequestListContext";
import { voucherTrackerUserId } from "../../../domain/constant/customer.repurchase.voucher.constant";

const PendingSolveButton = ({ request, setAlert, onFinish, variant = 'link' }) => {

    const {
        setIsPendingResolveModalOpen = () => { },
        showingPendingResolveTour,
    } = useCustomerRequestList() || {};

    let { search } = useLocation();
    const history = useHistory();

    const buttonText = request.isFinancialPending ? 'Adquirir Saldo' : 'Resolver pendência';

    const [isModalOpen, setIsmodalOpen] = useState(false);

    useEffect(() => { checkRedirectionInUrl(search) }, []);

    const checkRedirectionInUrl = (search) => {

        const { solvePending } = queryString.parse(search);

        if (!solvePending)
            return;

        setIsmodalOpen(true);
    }

    const openModal = () => {
        setIsmodalOpen(true);
        setIsPendingResolveModalOpen(true);
    }

    const handleClick = () => {
        if (request.isFinancialPending) {
            history.push(`/marketplace?sellerUserId=${voucherTrackerUserId}`);
            return;
        }

        openModal();
    }

    const getButton = () => ({
        link: (
            <Button
                variant='text'
                color='primary'
                className="w-full"
                onClick={handleClick}
            >
                <span className='text-sm'>{buttonText}</span>
            </Button>
        ),
        card: (
            <Box style={{ borderBottom: '1px solid #C8C8C8', paddingBottom: 10 }}>
                <div
                    style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}
                >
                    <Grid item md xs={6}>
                        <Typography style={{ fontSize: 15 }}>
                            <strong>Resolva a pendência para que possamos avançar com a solicitação!</strong>
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Button variant='contained' onClick={handleClick} color='primary'>
                            {buttonText}
                        </Button>
                    </Grid>
                </div>
            </Box>
        ),
        contained: (
            <Button
                variant="contained" color="primary"
                style={{ fontWeight: 'bold' }}
                onClick={handleClick}
            >
                {buttonText}
            </Button>
        )
    })[variant];

    return (
        <>
            {getButton()}

            <PendingSolveModal
                request={request}
                isOpen={isModalOpen && !showingPendingResolveTour}
                closeModal={() => setIsmodalOpen(false)}
                onFinish={onFinish}
                setAlert={setAlert}
            />
        </>
    )
}

export default PendingSolveButton;