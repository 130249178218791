import api from '../../services/api';

const baseUrl = 'marketing/video'

const findByKey = async (key) =>
    api.get({ type: 'user', service: `${baseUrl}/${key}` });

const findAll = async () =>
    api.get({ type: 'user', service: `${baseUrl}/` });

export default {
    findByKey,
    findAll,
}