import { useEffect, useState } from "react";

export default function Switch({
    onChange = (newState) => {},
    initialState = false,
    value,
    size = 'default'
}) {

    const [currentState, setCurrentState] = useState(initialState);

    const handleChange = () => {
        setCurrentState(prev => !prev);
        onChange(!currentState);
    }

    useEffect(() => {
        setCurrentState(value);
    }, [value]);

    const sizes = {
        small: 14,
        default: 20,
        large: 32,
    };

    const chosenSize = isNaN(size) ? sizes[size] : size;

    const switchWidth = chosenSize * 2 + chosenSize * 0.4;

    return (
        <button
            type="button"
            className={`
                rounded-full w-[${switchWidth}px] p-[2px]
                ${currentState ? 'bg-primary' : 'bg-[#777]'}
            `}
            onClick={handleChange}
        >
            <div
                className={`
                    rounded-full h-[${chosenSize}px] w-[${chosenSize}px] bg-white
                    ${currentState ? 'ml-[100%]' : 'ml-0'}
                    ${currentState ? '-translate-x-full' : 'translate-x-0'}
                `}
                style={{
                    transition: 'margin .3s ease, transform .3s ease',
                }}
            />
        </button>
    )
}