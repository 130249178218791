import { useState } from "react";
import { usePromiseTracker } from "react-promise-tracker";

import { Button } from "@material-ui/core";

import CustomerReturnSolicitation from "../solicitations/customer.return.solicitation.component";

import stepsSolicitation from "../../domain/constant/steps.solicitation.constant";

import requestStatusService from "../../domain/services/solicitation/request.status.service";
import { useTour } from "../../context/tourContext";
import { Edit3 } from "react-feather";
import Tooltip from "../shared/Tooltip";
import { useHistory } from "react-router-dom";

function RequestCustomerCorrectionButton({ request, isList = false, onSendToCorrection, setAlert, tutorial, setAllowNext, onFinish, className }) {

    const history = useHistory();

    const [isCorrectionFormOpen, setIsCorrectionFormOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const redirectToCorrection = () => history.push(`/solicitations/${request.id}/correction`);

    const sendToCorrectionStep = async () => {

        setIsLoading(true);

        await requestStatusService
            .changeStep({ stepName: stepsSolicitation.CORRECTION, requestId: request.id })
            .then(() => setAlert("Salvo com sucesso", "success"))
            .then(onSendToCorrection)
            .catch(err => setAlert(err.response.data.message, "error"))
            .finally(() => setIsLoading(false));
    }

    const { registerRef } = useTour();
    const newCorrection = registerRef('requestCorrectionTour', 'newCorrection');

    const isSelected = tutorial?.requestId == request.id || tutorial?.requestId == request.mainId

    const getButton = (isList) => ({
        true: (
            <Button
                variant='text'
                color='primary'
                className={`w-full ${className}`}
                onClick={redirectToCorrection}
                {...(isSelected) && {
                    ref: newCorrection
                }}
            >
                <span className='text-sm'>Enviar para correção</span>
            </Button>
        ),
        false: (
            <button
                ref={isSelected ? newCorrection : null}
                className="w-full bg-indigo-50 hover:bg-indigo-100 text-indigo-700 font-medium px-6 py-3 rounded-lg flex items-center justify-center gap-2 transition-colors border border-indigo-100 shadow-sm"
                onClick={redirectToCorrection}
            >
                <Edit3 />
                Solicitar Correção
            </button>
        )
    })[isList];

    return (
        <>
            <Tooltip title="Precisa de ajustes no serviço? Solicite aqui e receba em 24h. Para entregas urgentes, use o chat de suporte.">
                {getButton(isList)}
            </Tooltip>

            {isCorrectionFormOpen &&
                <CustomerReturnSolicitation
                    request={request}
                    mainRequestId={request?.mainId || request?.id}
                    open={isCorrectionFormOpen}
                    setOpen={setIsCorrectionFormOpen}
                    returnStep={sendToCorrectionStep}
                    setAlert={setAlert}
                    onFinish={onFinish}
                    setAllowNext={setAllowNext}
                    isCreating
                />
            }
        </>
    );
}

export default RequestCustomerCorrectionButton;
