
import Async from "../../components/Async";

import { More, Send, ViewCarousel } from "@material-ui/icons";
import { Book } from "@material-ui/icons";

const WhatsappNotificationsPage = Async(() => import('../../pages/marketing/notifications/whatsappNotificationsPage'));
const Campaigns = Async(() => import('../../pages/marketing/campaigns'));
const Automations = Async(() => import('../../pages/marketing/automations/automations'));
const BlogPage = Async(() => import("../../pages/marketing/blog/blogPage"));
const Banner = Async(() => import('../../pages/marketing/banner'));
const VoucherView = Async(() => import("../../pages/marketing/voucher.table"));

const routes = {
    notifications: {
        id: 'Marketing',
        path: '/marketing',
        icon: <Send />,
        children: [
            {
                id: 'blog',
                name: 'Blog',
                path: '/blog',
                component: BlogPage,
                icon: <Book />,
                roles: ['admin'],
            },
            {
                id: 'banners',
                name: 'Banners',
                icon: <ViewCarousel />,
                path: '/banners',
                component: Banner,
                roles: ['admin', 'email'],
            },
            {
                id: 'campaigns',
                path: '/marketing/campaigns',
                name: 'Campanhas',
                component: Campaigns,
                roles: ['admin', 'email'],
            },
            {
                id: 'automations',
                path: '/marketing/automations',
                name: 'Automações',
                component: Automations,
                roles: ['admin', 'email'],
            },
            {
                id: 'whatsapp',
                path: '/marketing/notifications/whatsapp',
                name: 'WhatsApp',
                component: WhatsappNotificationsPage,
                roles: ['admin'],
                isNew: true,
            },
            {
                id: 'vouchers',
                name: 'Vouchers',
                path: '/marketing/vouchers',
                component: VoucherView,
                icon: <More />,
                roles: ['admin', 'financial', 'marketing'],
            },
        ],
        component: null,
        roles: ['admin', 'financial', 'marketing', 'email'],
    },
}

const pages = Object.values(routes);

export default {
    routes,
    pages,
}