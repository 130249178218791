import { useState, useRef, useEffect } from "react";
import Button from "./Button";
import { Mic } from "react-feather";
import { Delete, Stop } from "@material-ui/icons";
import Tooltip from "./Tooltip";
import AudioPlayer from "../AudioPlayer";
import LoadingSpin from "../LoadingSpin";

export default function GenericAudioRecorder({
    maxDuration = 10,
    customStartButton = null,
    onStop = async (audioBlob) => { },
    onDelete = async () => { },
    loading = false,
    url = null,
    allowClear = true,
}) {

    const [recording, setRecording] = useState(false);
    const [audioURL, setAudioURL] = useState(null);
    const [timeLeft, setTimeLeft] = useState(maxDuration);
    const mediaRecorderRef = useRef(null);
    const timerRef = useRef(null);

    useEffect(() => {
        setAudioURL(url);
    }, [url]);

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(stream);
            const chunks = [];

            mediaRecorder.ondataavailable = (event) => chunks.push(event.data);

            mediaRecorder.onstop = async () => {
                const blob = new Blob(chunks, { type: "audio/wav" });
                setAudioURL(URL.createObjectURL(blob));
                clearInterval(timerRef.current);

                await onStop(blob);

                setRecording(false);
            };

            mediaRecorder.start();
            mediaRecorderRef.current = mediaRecorder;
            setRecording(true);
            setTimeLeft(maxDuration);

            timerRef.current = setInterval(() => {
                setTimeLeft((prev) => {
                    if (prev <= 1) {
                        stopRecording();
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        } catch (error) {
            console.error("Error accessing microphone", error);
        }
    };

    const stopRecording = () => {
        if (!loading && mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current = null;
        }
    };

    const deleteRecording = async () => {
        if (loading)
            return;

        setTimeLeft(maxDuration);

        await onDelete();

        setAudioURL(url);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    return (
        <div>
            {!recording && !audioURL && (
                customStartButton ? (
                    <div onClick={startRecording}>{customStartButton}</div>
                ) : (
                    <Button
                        type="text"
                        className="!bg-gray-50 !text-gray-600 hover:!bg-gray-100 hover:!text-gray-800"
                        icon={<Mic className="!w-5 !h-5" />}
                        onClick={startRecording}
                    >
                        Gravar Áudio
                    </Button>
                )
            )}

            {recording && (
                <div>
                    <div className="flex items-center gap-2">
                        <div className="text-primary">
                            Gravando...
                        </div>

                        <Button
                            type="secondary"
                            icon={<Stop />}
                            onClick={stopRecording}
                            className="!py-1 !px-2"
                            loading={loading}
                        >
                            Parar
                        </Button>
                    </div>

                    <div className="mt-1 text-gray-700">
                        Tempo de áudio restante: <span className="font-semibold">{formatTime(timeLeft)}</span>
                    </div>
                </div>
            )}

            {!recording && audioURL && (
                <div className="flex items-center">
                    <AudioPlayer
                        src={audioURL}
                        type={null}
                    />

                    {allowClear && (
                        <Tooltip title="Apagar e gravar novamente">
                            <div>
                                <Button
                                    type="text"
                                    onClick={deleteRecording}
                                    className="!rounded-full !text-gray-500 !px-2"
                                >
                                    {loading ? <LoadingSpin /> : <Delete />}
                                </Button>
                            </div>
                        </Tooltip>
                    )}
                </div>
            )}
        </div>
    );
}
