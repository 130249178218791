import { Card, CardContent, Grid, Typography } from '@material-ui/core';

import requestFieldsConfig from './fields/request.fields.config';

export default function RequestDetailsHeader(props) {

    const { request } = props;

    const fieldProps = {
        titleColor: 'gray', titleSize: 12,
        cleanTitle: true, spacing: 8, fontSize: 14
    }

    if (!request.id)
        return null;

    return (
        <div className="flex flex-col gap-6">
            {requestFieldsConfig.getRequestHeaderSteps(request).map((item, index) => (
                <item.Component
                    key={index} {...props}
                    fieldProps={fieldProps}
                />
            ))}
        </div>
    )
}