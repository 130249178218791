import {
    IconButton, Grid
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { AttachFileOutlined, SaveAlt, VisibilityOutlined, VolumeUpRounded } from "@material-ui/icons";

import documentsService from "../../../domain/services/solicitation/documents.service";
import modalities from "../../../domain/constant/modalities.constant";
import { useState } from "react";
import FirstRequestDownloadModals from "../request-details/firstRequestDownloadModals";
import AnswerAdditionalQuestionsModal from "../request-details/asnwerAdditionalQuestionsModal";
import CustomerSingleUpsellModal from "../request-details/CustomerSingleUpsellModal";
import loginService from "../../../domain/services/login.service";
import customerOfficeService from "../../../domain/services/client/customer.office.service";
import Button from "../../shared/Button";
import Tooltip from "../../shared/Tooltip";

const RequestServiceDocumentList = ({
    documents = [],
    openPreview,
    serviceName = '',
    isNotion,
    request,
    isPrimary = true,
}) => {

    const [openFirstDownloadModals, setOpenFirstDownloadModals] = useState(false);
    const [openQuestionsConfirmModal, setOpenQuestionsConfirmModal] = useState(false);
    const [isSingleUpsellModalOpen, setIsSingleUpsellModalOpen] = useState(false);

    const getItemDocument = (item) => (
        <div className="text-sm md:text-base">

            {(item.isProcessMainDocument || !!isNotion || serviceName) && (
                <div className="font-medium">
                    {item.isProcessMainDocument ? "Processo" : !!isNotion ? modalities.notion.label : serviceName}
                </div>
            )}

            <div className="text-gray-600 font-normal">
                {item.name}
            </div>

            {item.forCorrection && (
                <span className="bg-orange-400 text-white px-[6px] py-[2px] rounded-md text-xs">
                    Correção
                </span>
            )}
        </div>
    );

    const getDocumentIcon = (doc) => {

        const isAudio = (doc.fileType || '').includes('audio');

        const Icon = isAudio ? VolumeUpRounded :doc.icon || AttachFileOutlined;

        return (
            <Icon className={`${isPrimary ? 'text-blue-600 !h-6 !w-6' : 'text-gray-500 !h-5 !w-5'}`} />
        )
    }

    const openQuestionsModals = () => {
        const user = loginService.getUserAuthentication();
        const customer = { ...user.customer, name: user.name };

        const incompleteRegister = user.customer.isRegisterIncomplete;
        const officeQuestions = customerOfficeService.getQuestionsToShow(customer.profileQuestions);

        if (!incompleteRegister && !officeQuestions.length) return;

        setOpenQuestionsConfirmModal(true);
    }

    const handleDownload = async (doc) => {
        await documentsService.downloadFile(doc.url, doc.name);

        if (!request.isFirstCustomerRequest) return;

        openQuestionsModals();
    }

    const getDocumentsActions = (doc) => {

        if (doc.action)
            return doc.action();

        return (
            <div className={`flex ${isPrimary ? 'gap-4' : 'gap-0'}`}>
                <Tooltip title="Visualizar documento">
                    <IconButton color="primary" onClick={() => openPreview(doc)}>
                        <VisibilityOutlined className="!text-xl" />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Clique para baixar seu documento automaticamente">
                    <div>
                        <Button
                            icon={<SaveAlt className="!text-base" />}
                            type={isPrimary ? 'primary' : 'text'}
                            className={`!rounded-lg !text-sm md:!text-base h-10 !font-normal ${isPrimary ? '' : '!text-primary'}`}
                            onClick={() => handleDownload(doc)}
                        >
                            Baixar {isPrimary && 'Documento'}
                        </Button>
                    </div>
                </Tooltip>
            </div>
        )
    }

    const getContent = () => {

        if (!documents.length)
            return (
                <Grid style={{ width: '100%', marginTop: 5 }}>
                    <Alert severity='error'>
                        <AlertTitle>
                            <strong>Não foi possível encontrar os documentos</strong>
                        </AlertTitle>
                        Por favor tente novamente mais tarde ou entre
                        em contato com nosso suporte.
                    </Alert>
                </Grid>
            );

        return (
            <>
                {openFirstDownloadModals && (
                    <FirstRequestDownloadModals onClose={() => {
                        setOpenFirstDownloadModals(false);
                        setIsSingleUpsellModalOpen(true);
                    }} />
                )}

                <CustomerSingleUpsellModal open={isSingleUpsellModalOpen} />

                <AnswerAdditionalQuestionsModal
                    open={openQuestionsConfirmModal}
                    onCancel={() => {
                        setOpenQuestionsConfirmModal(false);
                        setOpenFirstDownloadModals(true);
                    }}
                    onOk={() => {
                        setOpenQuestionsConfirmModal(false);
                        setOpenFirstDownloadModals(true)
                    }}
                />

                <div className="flex flex-col gap-4">
                    {documents.map((doc, i) => (
                        <div
                            key={doc.id}
                            className={`flex flex-col md:flex-row gap-4 items-center justify-between rounded-lg border ${isPrimary ? 'bg-blue-50 p-4 border-blue-100' : 'bg-gray-50 p-3 border-gray-100'}`}
                        >

                            <div className="flex items-center gap-4">

                                {getDocumentIcon(doc)}

                                {getItemDocument(doc)}

                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {getDocumentsActions(doc)}
                            </div>

                        </div>
                    ))}
                </div>
            </>
        )
    }

    return getContent();
}

export default RequestServiceDocumentList;