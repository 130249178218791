import { useEffect, useState } from "react";
import moment from "moment";

import { Grid, Dialog, DialogTitle, DialogContent, Typography, IconButton, TextField, DialogActions, Button, Card } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import SkeletonLoading from "../../SkeletonLoading";
import VideoPlayer from "../../VideoPlayer";

import videoService from "../../../domain/services/marketing/video.service";

import communicationProvider from "../../../providers/communication.provider";
import datetimeProvider from "../../../providers/datetime.provider";
import { createGlobalStyle } from "styled-components";
import CountdownTimer from "../../shared/CountdownTimer";
import { videos } from "../../../domain/constant/marketing/video.constant"

const GlobalStyle = createGlobalStyle`
  #videoModal {
    h5 {
        font-family: satoshi, sans-serif !important;
    }

    textarea {
      font-family: satoshi, sans-serif !important;
      line-height: 1.5 !important;
      font-size: 15px !important;
    }
  }
`;

const VideoModal = ({ videoKey, onClose, setAlert, required, showCancelButtons, openByUser, showTimer = false }) => {

    const [initTime] = useState(moment());
    const [timer, setTimer] = useState(false);

    const [video, setVideo] = useState({});

    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => { findVideo(videoKey) }, []);

    useEffect(() => {
        let isSubscribed = true;

        let timeout = null;
        if (isSubscribed)
            timeout = setTimeout(() => setTimer(getIsWatched()), 1000);

        return () => {
            isSubscribed = false;
            clearTimeout(timeout);
        };
    });
    const getIsWatched = () =>
        datetimeProvider.getTimeDifference('seconds', initTime, moment());

    const isViewed = () => timer > video.minimumWatchDuration;

    const findVideo = (videoKey) =>
        videoService
            .find(videoKey)
            .then(setVideo)
            .catch(err => {
                communicationProvider.getErrorMessage(err, setAlert, 'Não foi possível carregar vídeo');
                closeModal(false);
            });

    const handleClose = () =>{
        let videoTimePlayed = document.getElementsByTagName('video')[0].currentTime

        if([videos.faciliterWelcome, videos.faciliterHearingWelcome].includes(video.key) && videoTimePlayed == 0)
            return setIsOpen(false);
   
        videoService
            .checkVideoViewed(isViewed(), video)
            .then(() => videoService.updateLocalStorage(video.key))
            .then(() => closeModal(true))
            .catch(() => communicationProvider.getErrorMessage({}, setAlert, 'Assista o vídeo para fechar'))
    }

    const closeModal = (isToMarkViewed) => {
        setIsOpen(false);
        onClose && onClose(isToMarkViewed);
    }

    const getCloseButton = () => {

        if (required && !isViewed())
            return null;

        return (
            <Grid style={{ marginLeft: 20 }}>
                <IconButton
                    aria-label="close" size='small'
                    onClick={() => closeModal(false)}
                >
                    <Close />
                </IconButton>
            </Grid>
        )
    }

    return (
        <>

            <GlobalStyle />

            <Dialog
                id="videoModal"
                maxWidth='md' fullWidth disableEscapeKeyDown={required} open={isOpen}
                onClose={(e, reason) => (reason != 'backdropClick') && closeModal(false)}
            >
                <DialogTitle style={{ paddingBottom: 20, paddingTop: 20, borderBottom: "1px solid rgb(212 212 212 / 53%)" }} >
                    <Grid container justifyContent="space-between">

                        <Grid xs>
                            <SkeletonLoading isDataLoading={!video.title} size={{ height: 32 }}>
                                <Typography variant="h5" style={{ fontSize: 25, fontFamily: "satoshi, sans-serif !important" }}>
                                    {video.title}
                                </Typography>
                            </SkeletonLoading>
                        </Grid>

                        {getCloseButton()}

                    </Grid>
                </DialogTitle>

                <DialogContent>

                    <Grid style={{ padding: '10px 15%', marginBottom: 15 }}>
                        <SkeletonLoading
                            isDataLoading={!video.videoProviderId}
                            size={{ height: '45vh' }} variant="retangular"
                        >
                            <Card style={{ borderRadius: '4px', boxShadow: "rgba(50, 50, 93, 0.1) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px" }}>
                                <VideoPlayer videoId={video.videoProviderId} />
                            </Card>
                        </SkeletonLoading>
                    </Grid>

                    <Grid style={{ padding: '0 2%' }}>
                        <SkeletonLoading isDataLoading={!video.description} size={{ height: 25 }}>
                            {video.description && (
                                <TextField
                                    name='description' multiline={true}
                                    fullWidth value={video.description}
                                    InputProps={{ readOnly: true, disableUnderline: true }}
                                    style={{
                                        fontFamily: "satoshi, sans-serif !important",
                                        lineHeight: "1.5 !important",
                                        fontSize: 15
                                    }}
                                />
                            )}
                        </SkeletonLoading>
                    </Grid>

                </DialogContent>

                <DialogActions style={{ borderTop: "1px solid rgb(212 212 212 / 53%)" }}>
                    {showTimer && !!video.minimumWatchDuration && (
                        <div>
                            <div className="text-lg">
                                <CountdownTimer duration={video.minimumWatchDuration} />
                            </div>
                        </div>
                    )}
                    {(showCancelButtons && !openByUser) ?
                        <>
                            <Button
                                variant="text"
                                autoFocus color='primary'
                                style={{ margin: 10 }}
                                onClick={() => closeModal(false)}
                            >
                                Continuar
                            </Button>

                            <Button
                                variant="text"
                                color='default'
                                style={{ margin: 10 }}
                                onClick={handleClose}
                            >
                                Não mostrar novamente
                            </Button>
                        </> :
                        <Button
                            variant="text"
                            autoFocus color='primary'
                            style={{ margin: 10 }}
                            onClick={handleClose}
                            disabled={showTimer && !isViewed()}
                        >
                            Fechar
                        </Button>
                    }

                </DialogActions>

            </Dialog>
        </>
    )
}

export default VideoModal;
