import { useTour } from "../../context/tourContext";
import { StarRounded } from "@material-ui/icons";
import Button from "../shared/Button";

const RequestFormHighlightButton = ({ label, text, onClick }) => {

    const { registerRef } = useTour();

    const ratingRef = registerRef('concludedRequestTour', 'rating')

    return (
        <div className="relative overflow-hidden rounded-lg bg-white">
            <div className="absolute bg-primary opacity-10 left-0 top-0 right-0 bottom-0" />
            <div className="relative flex justify-between items-center gap-3 p-4">
                <div className="text-base md:text-xl font-medium">
                    {label}
                </div>
                <div ref={ratingRef}>
                    <Button
                        icon={<StarRounded className="!text-lg" />}
                        type="primary"
                        className="h-12 !px-6 !rounded-lg !text-sm md:!text-base"
                        onClick={onClick}
                    >
                        {text}
                    </Button>
                </div>
            </div>
        </div>
    );
}
export default RequestFormHighlightButton;