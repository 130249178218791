import { useEffect } from 'react';

const VideoPlayer = ({ videoId }) => {

    useEffect(() => initializeVideoPlayer(), [videoId]);

    const initializeVideoPlayer = () => {

        var player = document.getElementById(`videoPlayer${videoId}`);

        var playerScript = document.createElement("script");

        playerScript.src = `https://scripts.converteai.net/a8f5fdf5-417c-4300-986f-e03c0159ee4d/players/${videoId}/player.js`;
        playerScript.async = !0;

        player.appendChild(playerScript);
    }

    return (
        <div key={videoId} id={`videoPlayer${videoId}`}>
            <div id={`vid_${videoId}`} style={{ position: "relative", width: "100%", padding: "56.25% 0 0" }}>
                <img
                    id={`thumb_${videoId}`} src={`https://images.converteai.net/a8f5fdf5-417c-4300-986f-e03c0159ee4d/players/${videoId}/thumbnail.jpg`}
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "cover", display: "block" }}
                />
                <div id={`backdrop_${videoId}`} style={{ position: "absolute", top: 0, width: "100%", height: "100%", WebkitBackdropFilter: "blur(5px)", backdropFilter: "blur(5px)" }}></div>
            </div>
        </div>
    );
}

export default VideoPlayer;
