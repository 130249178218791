import { Grid, IconButton } from "@material-ui/core"
import { Close } from "@material-ui/icons";

import RequestCustomerRatingForm from "./request.customer.rating.form.component";
import SimpleModal from "../../shared/SimpleModal";

const RequestCustomerRatingDialog = ({ request = {}, initialRating, setAlert, isOpen, onClose, onFinish, additionalInformation}) => {

    return (
        <SimpleModal
            open={isOpen}
            onClose={onClose}
            disableBackdropClick
            maxWidth="sm"
            bodyStyle={{ padding: 40 }}
        >
            <div
                style={{
                    display: 'flex', alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <span className="text-xl font-semibold">
                    Sua opinião vale ouro: avalie o serviço do ID #{request.mainId || request.id} e garanta os melhores Faciliters
                </span>

                <IconButton className="!mb-auto" aria-label="close" onClick={onClose} size='small'>
                    <Close />
                </IconButton>
            </div>

            <Grid container justifyContent="center" style={{ marginBottom: 20 }}>
                <RequestCustomerRatingForm
                    initialRating={initialRating}
                    requestId={request.id}
                    requestMainId={request.mainId}
                    setAlert={setAlert}
                    onFinish={onFinish}
                    additionalInformation={additionalInformation}
                />
            </Grid>


        </SimpleModal>
    )
}

export default RequestCustomerRatingDialog;