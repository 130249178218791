import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@material-ui/core';

export default function VideoInputModal({ open, onClose, onSubmit }) {
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        let url = formData.get('videoUrl');

        if (url && !url.match(/^https?:\/\//))
            url = 'https://' + url;

        onSubmit(url);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogTitle>Inserir Vídeo</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        name="videoUrl"
                        label="URL do Vídeo"
                        type="url"
                        fullWidth
                        variant="outlined"
                        placeholder="https://www.youtube.com/watch?v=..."
                        helperText={
                            <span style={{ marginTop: '16px', display: 'block' }}>
                                Cole aqui a URL completa do vídeo começando com https://<br />
                                Ex: https://www.youtube.com/watch?v=...<br />
                            </span>
                        }
                        required
                        InputProps={{
                            inputProps: {
                                pattern: "https?://.*"
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancelar
                    </Button>
                    <Button type="submit" color="primary" variant="contained">
                        Inserir
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
} 