import React, { useState, useEffect, useRef } from 'react';

export default function Textarea({
    placeholder,
    onChange = () => {},
    value,
    className,
    alternativeStyle = false,
    style,
    error,
    rows = 1,
    disabled = false,
}) {
    const [currentValue, setCurrentValue] = useState('');
    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [currentValue]);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const handleChange = (newValue) => {
        setCurrentValue(newValue);
        onChange(newValue);
    }

    const defaultClasses = 'rounded border'
    const alternativeClasses = 'border-0 border-b-2';

    return (

        <div className="w-full relative text-sm">
            <textarea
                ref={textareaRef}
                value={currentValue || ''}
                onChange={(e) => handleChange(e.target.value)}
                rows={rows}
                className={`
                    w-full py-[6px] px-3 text-sm resize-none transition-[border-color_.3s_ease] focus:ring-0 overflow-hidden bg-white border-[#ededed]
                    ${!disabled ? 'hover:border-primary focus:border-primary' : 'text-gray-500'}
                    ${alternativeStyle ? alternativeClasses : defaultClasses}
                    ${className}
                `}
                style={{ transition: 'border-color .3s ease', ...style }}
                disabled={disabled}
            />

            {!currentValue && <div className="absolute left-3 top-[6px] text-[#0006] pointer-events-none">{placeholder}</div>}

            <div
                className={`h-0 text-sm text-red-500 ${!!error ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-1'}`}
                style={{ transition: 'opacity .3s ease, transform .3s ease' }}
            >
                {error}
            </div>
        </div>
    );
}
