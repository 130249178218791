import React, { useEffect, useState } from "react";
import Col from "../../../components/shared/grid/Col";
import Row from "../../../components/shared/grid/Row";
import PageHeader from "../../../components/shared/layout/pageHeader";
import { useHistory, useParams } from "react-router-dom";
import requestServicesService from "../../../domain/services/solicitation/request.services.service";
import requestService from "../../../domain/services/request/request.service";
import SnackbarAlert from "../../../components/shared/SnackbarAlert";
import useRequest from "../../../hooks/useRequest";
import requestCorrectionService from "../../../domain/services/request/request.correction.service";
import swal from "sweetalert";
import CorrectionOptions from "../../../components/requests/correction/correctionOptions";
import CorrectionForm from "../../../components/solicitations/correction/correctionForm";
import Card from "../../../components/shared/Card";
import LoadingSpin from "../../../components/LoadingSpin";
import CorrectionNewServiceForm from "../../../components/solicitations/correction/correctionNewServiceForm";

export default function CustomerRequestCorrection() {

    const history = useHistory();
    const { id, correctionSource } = useParams();

    const [checkVersion, isCheckingVersion] = useRequest(requestServicesService.checkVersion);
    const [getRequestDetails, isGettingDetails] = useRequest(requestService.getRequestDetails);
    const [getAvailableServices, isGettingServices] = useRequest(requestServicesService.getAvailableServicesToCreateAfterConclusion);
    const [checkAvailability, isCheckingAvailability] = useRequest(requestCorrectionService.checkCorrectionAvailability);

    const [request, setRequest] = useState({});
    const [services, setServices] = useState([]);
    const [correctionType, setCorrectionType] = useState(correctionSource ? 'correctionService' : null);

    const mainRequestId = request.mainId || request.id;
    const initialDataLoading = isCheckingVersion || isGettingDetails || isGettingServices;

    useEffect(() => {
        if (!id)
            history.push('/solicitations');

        getRedirectId()
            .then(getRequestDetails)
            .then(requestDetails => {
                setRequest(requestDetails);
            })
            .catch(err => SnackbarAlert.warning('Ocorreu um problema ao encontrar os detalhes da correção.'));

    }, []);

    useEffect(() => {
        if (!request.id)
            return;

        getServices();
    }, [request])

    const getRedirectId = async () =>
        checkVersion(id)
            .then(res => res.data?.requestId);

    const redirectToRequest = () => history.push(`/solicitations/${mainRequestId}`);

    const handleSelectServiceCorrection = async () => {
        checkAvailability(request.id)
            .then(() => setCorrectionType('correctionService'))
            .catch(err => {
                swal({
                    title: '⚠️ Atenção! ⚠️',
                    text: `
                                Infelizmente, o período máximo de pedido de correção expirou ${err?.response?.data?.correctionExpirationDate ? `no dia ${err?.response?.data?.correctionExpirationDate}` : ''}  e não é mais possível pedir uma correção.
                                
                               Estamos aqui para ajudá-lo. Se precisar de qualquer assistência, basta entrar em contato com o suporte e nossa equipe estará pronta para garantir que você tenha uma experiência incrível conosco.
        
                            Agradecemos sua compreensão e estamos ansiosos para continuar a atendê-lo! 😊
                            `,
                    icon: false,
                    buttons: {
                        confirm: 'Tudo bem'
                    }
                })
            });
    }

    const handleChangeCorrectionType = (newType) => {

        if (newType === 'correctionService')
            return handleSelectServiceCorrection();

        setCorrectionType(newType);
    }

    const getServices = async () =>
        getAvailableServices(mainRequestId)
            .then((res) => {

                if ((res.data || []).length == 0)
                    handleSelectServiceCorrection();

                setServices(res.data)
            })
            .catch(handleSelectServiceCorrection)



    const navs = [
        {
            label: <span className="text-base">Solicitações</span>,
            href: '/solicitations',
        },
        {
            label: <span className="text-base">Detalhes</span>,
            href: `/solicitations/${mainRequestId}`
        },
        {
            label: <span className="text-base">Correção</span>,
        },
    ];

    return (
        <div className="flex justify-center font-['SF_Pro']">
            <div className="w-full max-w-4xl">

                <PageHeader
                    className="text-base"
                    navs={navs}
                    extra={(
                        <div className="flex gap-3">
                            <div className="relative text-primary px-3 py-1 rounded overflow-hidden">
                                <div className="absolute bg-primary opacity-20 left-0 top-0 right-0 bottom-0" />
                                <div className="relative text-base">
                                    ID: {mainRequestId}
                                </div>
                            </div>
                        </div>
                    )}
                />

                <Row gutter={[24, 24]} className="text-base">
                    <Col span={24}>

                        {initialDataLoading && (
                            <Card className="max-w-2xl mx-auto">
                                <div className="flex justify-center items-center h-32">
                                    <LoadingSpin size="large" />
                                </div>
                            </Card>
                        )}

                        {!correctionType && !initialDataLoading && (
                            <CorrectionOptions
                                onSelect={handleChangeCorrectionType}
                                onCancel={redirectToRequest}
                                loading={isCheckingAvailability}
                            />
                        )}

                        {!initialDataLoading && correctionType === 'correctionService' && (
                            <div class="max-w-2xl mx-auto">
                                <Card>
                                    <CorrectionForm
                                        requestId={mainRequestId}
                                        request={request}
                                        onCancel={() => setCorrectionType(null)}
                                        correctionSource={correctionSource}
                                    />
                                </Card>
                            </div>
                        )}

                        {!initialDataLoading && correctionType === 'newService' && (
                            <CorrectionNewServiceForm
                                request={request}
                                services={services}
                                onCancel={() => setCorrectionType(null)}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        </div >
    )
}