import api from './api';
import moment from 'moment';

const find = async (id) =>
    api.get({ type: 'user', service: `employess/${id}` });

const findAll = async () =>
    api.get({ type: 'user', service: `employess/` });

const findBySector = async (sector) =>
    await api.get({ type: 'user', service: `employess/${sector}` })

const findSpecialties = async () =>
    api.get({ type: 'user', service: 'employess/specialties' });

const findSectors = async () =>
    api.get({ type: 'user', service: 'employess/sectors' });

const saveAction = async (employee) => {
    const value = { ...mapFields(employee), name: employee.name };

    (value.id) ?
        await edit(value.id, { ...value, userId: employee.userId }) :
        await save({
            ...value, source: 'internal', email: employee.email,
            roles: checkValidRoles(employee.roles || [])
        });
}

const save = async (data) =>
    api.post({ type: 'user', service: `employess/`, data });

const edit = async (id, data) =>
    api.put({ type: 'user', service: `employess/${id}`, data });

const editRoles = async (data) =>
    api.put({ type: 'user', service: `users/${data.id}`, data });

const remove = async (id, reason) =>
    api.put({ type: 'user', service: `employess/${id}/remove`, data: reason });

const checkValidRoles = (roles) => {
    if ((roles || []).some(item => item.name == "operator" || item.name == "admin" || item.name == "external-seller"))
        return roles;

    return [...roles, { id: 4, name: "operator" }];
}

const newEmployee = () => ({
    name: "", email: "", rg: "", cpf: "", birthdate: "", cellphone: "", additionalData: {}
})

const mapFields = (employee) => ({
    id: employee.id,
    cpf: employee.cpf,
    rg: employee.rg,
    cellphone: employee.cellphone,
    sectorId: employee.sectorId,
    occupation: employee.occupation,
    observation: employee.observation,
    birthdate: employee.birthdate ? moment(employee.birthdate).format("YYYY-MM-DD") : null,
    additionalData: employee.additionalData || null,
    address: employee.address && JSON.stringify(employee.address),
    bankData: employee.bankData && JSON.stringify(employee.bankData),
    company: employee.company && JSON.stringify(employee.company),
    remunerationInfo: employee.remunerationInfo && JSON.stringify(employee.remunerationInfo),
    agendaData: employee.agendaData || null
})

export default {
    find,
    findAll,
    findBySector,
    findSectors,
    findSpecialties,
    saveAction,
    editRoles,
    remove,
    newEmployee,
}