import React, { useEffect, useState } from "react";
import requestValidator from "../../../domain/validators/request.validator";
import RequestCalculationServices from "../../requests/calculation/request.calculation.services.component";
import Button from "../../shared/Button";
import Card from "../../shared/Card";
import CustomSelect from "../../shared/CustomSelect";
import requestFieldsConfig from "../../requests/fields/request.fields.config";
import { requestServices } from "../../../domain/constant/request.services.constant";
import requestRegistryService from "../../../domain/services/solicitation/request.registry.service";
import SnackbarAlert from "../../shared/SnackbarAlert";
import useRequest from "../../../hooks/useRequest";
import requestServicesService from "../../../domain/services/solicitation/request.services.service";
import { useHistory } from "react-router-dom";
import { FileText, HelpCircle } from "react-feather";

export default function CorrectionNewServiceForm({
    request,
    services = [],
    onCancel,
}) {

    const mainRequestId = request.mainId || request.id;

    const history = useHistory();

    const [createNewService, isCreatingServices] = useRequest(requestServicesService.create);

    const [newService, setNewService] = useState({});
    const [additionalFields, setAdditionalFields] = useState([]);
    const [additionalFieldsValues, setAdditionalFieldsValues] = useState({});

    useEffect(() => {
        if (newService.key != requestServices.ISSUEPAYMENTFEE)
            return;

        requestRegistryService.getFieldsToCreateIssuePaymentServiceAfterConclusion(request.id)
            .then((res) => setAdditionalFields(res.fields))
    }, [newService]);

    const redirectToRequest = () => history.push(`/solicitations/${mainRequestId}`);

    const handleChangeService = (field, fieldValue) =>
        setNewService((values) => ({ ...values, [field]: fieldValue }));

    const createService = async () => {

        if (!newService.key || newService.key === 'calculation' && !newService.calculationServiceId) {
            SnackbarAlert.warning('Preencha todos os campos para criar o serviço!')
            return;
        }

        if (newService.key === requestServices.ISSUEPAYMENTFEE && Object.keys(additionalFieldsValues).length === 0) {
            SnackbarAlert.warning('Preencha todos os campos para criar o serviço!')
            return;
        }

        additionalFields.map(async (field) => {
            await requestRegistryService.updateField(request.id, field.id, { value: additionalFieldsValues[field.key] })
        })

        const serviceToCreate = services.find(s => s.key === newService.key);
        const calculationServiceId = serviceToCreate.key === 'calculation' ? newService.calculationServiceId : null;

        createNewService(mainRequestId, { serviceId: serviceToCreate.id, calculationServiceId })
            .then(() => {
                SnackbarAlert.success('Serviço criado com sucesso');
                redirectToRequest();
            })
            .catch(error => SnackbarAlert.error('Ocorreu um problema ao criar o seu serviço'))
    }

    const renderFieldTitle = (text, withMargin = true) => (
        <label class={`block text-sm font-medium text-gray-700 ${withMargin ? 'mb-2' : ''}`}>{text}</label>
    );

    const renderFields = () => {

        const parsedFields = additionalFields.map(field => ({
            ...field,
            label: renderFieldTitle(field.label, false)
        }));

        return parsedFields.map((field, index) => (
            <React.Fragment key={index}>
                {requestFieldsConfig.getComponentField({
                    field,
                    request,
                    setValue: (...params) => { setAdditionalFieldsValues((values) => ({ ...values, [field.key]: params[2] })) },
                    isEdit: true,
                    fieldProps: {},
                    fieldsWithError: [],
                    setAlert: () => { }
                })}
            </React.Fragment>
        ));
    };

    return (
        <Card className="max-w-2xl mx-auto">
            <div className="text-2xl font-semibold">
                Adicionar Novo Serviço
            </div>

            <div className="mt-6">
                {renderFieldTitle('1. Selecione o tipo de serviço')}
                <CustomSelect
                    placeholder={<span className="text-base">Selecione um serviço</span>}
                    value={newService?.key}
                    onChange={value => handleChangeService("key", value)}
                    className="h-14"
                    allowClear
                    options={services.map(service => ({
                        label: <span className="text-base pl-1">{service.name}</span>,
                        labelOptionRender: (
                            <div class="w-full flex items-center py-2 transition-colors">
                                <FileText className="shrink-0 text-primary !h-6 !w-6 mr-3" />
                                <div class="">
                                    <div class="font-medium">{service.name}</div>
                                    <div class="mt-1 text-sm text-gray-500">{requestServices.structure[service.key].shortDescription}</div>
                                </div>
                            </div>
                        ),
                        value: service.key,
                    }))}
                />
            </div>

            {requestValidator.isIssuePaymentFeesService(newService.key) && additionalFields.length > 0 && (
                <div className="mt-4 -ml-[15px]">
                    {renderFields()}
                </div>
            )}

            {requestValidator.isCalculationService(newService.key) &&
                <div className="mt-6">
                    {renderFieldTitle('2. Selecione o tipo de cálculo')}
                    <RequestCalculationServices
                        request={request}
                        requestId={request?.id}
                        areaId={request.areaId}
                        calculationServiceId={newService.calculationServiceId || ''}
                        isEdit={false}
                        setFieldValue={(field, value) => handleChangeService('calculationServiceId', value)}
                        value={newService?.calculationServiceId}
                        useCustomSelect
                    />

                </div>
            }

            <div class="mt-6 bg-gray-50 rounded-lg p-4 flex items-start space-x-3">
                <HelpCircle className="text-primary !h-5 !w-5" />
                <div class="text-sm text-gray-600">
                    <span class="font-medium">Precisa de ajuda?</span>
                    <p class="mt-1">Clique na caixa de seleção acima e escolha um dos serviços disponíveis. Se você escolher a opção "Cálculo", aparecerá uma nova caixa para você selecionar o tipo específico de cálculo que precisa.</p>
                </div>
            </div>

            <div className="mt-6 flex gap-3 justify-end">
                <Button
                    type="secondary"
                    onClick={onCancel}
                    disabled={isCreatingServices}
                >
                    Voltar
                </Button>
                <Button
                    type="primary"
                    onClick={createService}
                    loading={isCreatingServices}
                >
                    Enviar
                </Button>
            </div>

        </Card>
    )
}