


import BlogEditorPage from "../../pages/marketing/blog/blogEditorPage";



const routes = {
    blogEditorSpecific: {
        id: 'blogEditorSpecific',
        name: 'Editor de Blog',
        path: '/blog/editor/:id',
        component: BlogEditorPage,
        roles: ['admin'],
        layoutProps: {
            hideSideBar: true,
        },
    },
}

const pages = Object.values(routes);

export default {
    routes,
    pages
}