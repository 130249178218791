import React, { useEffect, useState } from 'react';

import { Chip, CircularProgress, Grid } from '@material-ui/core';

import RadioButtonField from './radio.button.field';

import requestFieldsService from '../../../domain/services/request/request.fields.service';
import FormField from '../../FormField';
import { ClearOutlined } from '@material-ui/icons';

export default function MultiSelectField({
    id, name, label, required, value = [], request = {}, confirmationLabel, needConfirmation,
    extraInfo, getOptions, setFieldValue, hasCustomFieldOptions, fieldProps, onError,
    fieldServiceName,
}) {

    const [options, setOptions] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);

    const [confirmationButtonLabel, setConfirmationButtonLabel] = useState(null);
    const [declineButtonLabel, setDeclineButtonLabel] = useState(null);

    useEffect(() => {

        checkToShowPreliminariesButtonLabels();

        const alreadyConfirmed = isConfirmed || !!value.length || !needConfirmation;
        if (!alreadyConfirmed)
            return;

        if (name === keyPreliminaries) {
            checkPreliminaries();
            return;
        }

        setIsConfirmed(true);
    }, [value, needConfirmation, name]);

    const checkPreliminaries = () => {
        const isNotInclude = value[0]?.name !== FIELD_CONFIG.PRELIMINARIES.DO_NOT_INCLUDE.name;
        setIsConfirmed(isNotInclude);
    }

    const checkToShowPreliminariesButtonLabels = () => {
        if (name !== keyPreliminaries)
            return;

        preliminariesButtonLabel();
    }

    const preliminariesButtonLabel = () => {
        setConfirmationButtonLabel('Preciso de preliminares na minha petição');
        setDeclineButtonLabel('Não preciso de preliminares na minha petição');
    }

    const findOptions = async () => {

        setIsLoading(true);

        await getOptions(id, name, request.areaId)
            .then(res => setOptions([...res.data]))
            .finally(() => setIsLoading(false));
    }

    const filterOptions = (options, { inputValue }) => {

        let filtered = !inputValue ? options : options.filter(item => item.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0);


        if (hasCustomFieldOptions && inputValue && !options.some(option => (option.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0)))
            return [{ name: `Adicionar: "${inputValue}"`, inputValue }];

        return [...filtered];
    }

    const checkIfIsNewOption = (item) => {

        const newOption = item[Math.max(0, item.length - 1)];

        if (newOption?.inputValue)
            return { ...newOption, name: newOption.inputValue };

        return null;
    }

    const removeUnselectedOption = (selectedOptions) => {
        const selectedOption = selectedOptions[selectedOptions.length - 1];
        if (!selectedOption) return [];

        const prevSelectedOptions = selectedOptions.slice(0, -1);
        const existingIndex = prevSelectedOptions.findIndex(option => option.value === selectedOption.value);

        if (existingIndex < 0) {
            return selectedOptions;
        }

        prevSelectedOptions.splice(existingIndex, 1);
        return prevSelectedOptions;
    }

    const onChange = (e, value, selectedsOptions) => {

        const newOption = checkIfIsNewOption(selectedsOptions);

        if (newOption) {
            addFieldValue(id, selectedsOptions, { name: newOption.name });
        }

        const newSelectedOptions = removeUnselectedOption(selectedsOptions);

        setFieldValue(name, newSelectedOptions);
        return;
    }

    const addFieldValue = async (fieldId, selectedsOptions, newOption) => {

        const data = await requestFieldsService.createFieldOption(fieldId, newOption);

        const newSelectedsOptions = JSON.parse(JSON.stringify(selectedsOptions));

        newSelectedsOptions.pop();

        setFieldValue(name, [...newSelectedsOptions, { ...data }]);
    }

    const changeConfirmation = (n, value) => {
        setIsConfirmed(value);

        if (name === keyPreliminaries) {
            preliminariesChangeConfirmation(value);
            return;
        }

        if (!!value)
            return;

        setFieldValue(name, []);
    }

    const preliminariesChangeConfirmation = (value) => {

        if (!value) {
            setFieldValue(name, []);
            setFieldValue(name, [FIELD_CONFIG.PRELIMINARIES.DO_NOT_INCLUDE]);
            return;
        }

        setFieldValue(name, []);
        setFieldValue(name, [FIELD_CONFIG.PRELIMINARIES.CREATE_FOR_ME]);
    }

    const hasConfirmation = () => {

        if (!needConfirmation)
            return null;

        return (
            <Grid item xs={12}>
                <RadioButtonField
                    label={confirmationLabel}
                    name={`has${name}`}
                    value={isConfirmed}
                    setFieldValue={changeConfirmation}
                    confirmationButtonLabel={confirmationButtonLabel}
                    declineButtonLabel={declineButtonLabel}
                    fieldProps={fieldProps}
                    onError={onError}
                />
            </Grid>
        )

    }

    const keyPreliminaries = 'preliminaries';

    const FIELD_CONFIG = {
        PRELIMINARIES: {
            CREATE_FOR_ME: {
                id: 45857,
                name: 'SIM',
                value: "45857",
                fieldId: 190
            },
            DO_NOT_INCLUDE: {
                id: 45819,
                name: 'NÃO INCLUIR',
                value: "45819",
                fieldId: 190
            }
        }
    };

    return (
        <>

            {hasConfirmation()}

            {isConfirmed && (
                <Grid item xs={12}>
                    <FormField
                        {...fieldProps}
                        type='autocomplete'
                        placeholder={`Selecione ${fieldServiceName?.toLowerCase() || ''}`}
                        name={name}
                        field={label}
                        isLoading={isLoading}
                        currentValue={value}
                        options={options
                            .filter(item => item.id !== FIELD_CONFIG.PRELIMINARIES.CREATE_FOR_ME.id || item.id !== FIELD_CONFIG.PRELIMINARIES.DO_NOT_INCLUDE.id)
                            .map(item => ({ ...item, value: item.id }))}
                        required={required}
                        hasCustomFieldOptions={hasCustomFieldOptions}
                        onOpen={findOptions}
                        onChange={onChange}
                        filterOptions={filterOptions}
                        onError={onError}
                        disableClearable
                        multiple
                        helperText={extraInfo}
                        disableCloseOnSelect
                        renderTags={(value, getTagProps) =>
                            value
                                .filter(option => option.value !== FIELD_CONFIG.PRELIMINARIES.CREATE_FOR_ME.value)
                                .map((option, index) => (
                                    <Chip
                                        key={option.id}
                                        variant="default"
                                        label={option.name}
                                        style={{ background: '#e9e9e9' }}
                                        deleteIcon={(
                                            <div className='h-full flex items-center'>
                                                <ClearOutlined fontSize='small' />
                                            </div>
                                        )}
                                        {...getTagProps({ index })}
                                    />
                                ))
                        }
                        getCustomRenderProps={(params) => ({
                            InputProps: {
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }
                        })}
                        titleSize={15}
                        titleWeight={600}
                        spacing={10}
                    />
                </Grid>
            )}

        </>
    );
}
